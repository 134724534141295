import { useEffect, useRef } from 'react';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { SFMap } from '../../services';

type Props = {
  paths: Coordinate[];
  name?: string;
  fillColor: string;
  strokeColor: string;
};

function Polygon({ paths, name, fillColor, strokeColor }: Props) {
  const vectorRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>> | null>(
    null,
  );

  useEffect(() => {
    const geoJSONObject = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [paths],
          },
        },
      ],
    };
    const source = new VectorSource({
      features: new GeoJSON().readFeatures(geoJSONObject),
    });
    const layer = new VectorLayer({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      source,
    });
    vectorRef.current = layer;
    SFMap.getInternalMap().addOverlayLayer(layer);

    return () => {
      SFMap.removeOverlayLayer(layer);
    };
  }, [paths]);

  useEffect(() => {
    const style = new Style();
    style.setStroke(new Stroke({ color: strokeColor }));
    style.setFill(new Fill({ color: fillColor }));
    if (vectorRef.current) {
      if (name) {
        style.setText(
          new Text({
            text: name,
            font: '600 13px Roboto',
            fill: new Fill({
              color: '#000',
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 3,
            }),
            backgroundFill: new Fill({
              color: '#fff',
            }),
            padding: [8, 8, 8, 8],
            overflow: true,
            placement: 'point',
            textAlign: 'center',
            textBaseline: 'middle',
          }),
        );
      }
      vectorRef.current.setStyle(style);
    }
  }, [fillColor, name, strokeColor, paths]);

  return null;
}

Polygon.displayName = 'Polygon';

export default Polygon;
