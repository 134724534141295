import { ApplicationAction, ApplicationArea } from '../../shared/types';

export interface Permission {
  area: ApplicationArea;
  action: ApplicationAction;
}

export interface RoleFormValues {
  name: string;
  description: string;
  area: Record<ApplicationArea, ApplicationAction>;
}

export const enum SearchOption {
  NAME = 'name',
}
