import { MouseEvent } from 'react';
import { FieldErrors } from 'react-hook-form';
import type { SxProps } from '@mui/system';

export const buildBase64SVGString = (svgString: string) =>
  `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;

export const stopPropagation = (e: MouseEvent<HTMLElement>) => {
  e.stopPropagation();
};

export const noop = () => {};

/**
 * @deprecated This function shouldn't be used
 */
export function getFieldErrorMessage(errors: FieldErrors, name: string) {
  if (name.includes('.')) {
    const nestedNames = name.split('.');
    return nestedNames.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (accum: any, curr: string) => accum?.[curr],
      errors,
    )?.message;
  }
  return errors[name]?.message;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessageFromException = (e: any) => {
  const message = e?.details?.message;
  if (Array.isArray(message)) {
    return message.join(',');
  }
  if (message) {
    return message;
  }
  return 'Something Went Wrong';
};

export function isValidLatLng(lat: number, lng: number): boolean {
  if (Number.isNaN(lat) || Number.isNaN(lng)) {
    return false;
  }
  if (lat < -90 || lat > 90) {
    return false;
  }
  if (lng < -180 || lng > 180) {
    return false;
  }
  return true;
}

export function formatLatLng(lat: number, lng: number, fractionDigits = 6) {
  if (!lat || !lng) {
    return 'N/A';
  }
  return `${lat.toFixed(fractionDigits)}, ${lng.toFixed(fractionDigits)}`;
}

type PureSx<T extends object> = Exclude<SxProps<T>, ReadonlyArray<unknown>>;
type SxAsArray<T extends object> = Array<PureSx<T>>;

export const mergeSX = <T extends object>(
  ...styles: (SxProps<T> | false | undefined)[]
): SxProps<T> => {
  const capacitor: SxAsArray<T> = [];

  styles.forEach((sx) => {
    if (sx) {
      if (Array.isArray(sx)) {
        (sx as SxAsArray<T>).forEach((s) => capacitor.push(s));
      } else {
        capacitor.push(sx as PureSx<T>);
      }
    }
  });

  return capacitor;
};
