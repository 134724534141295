import { TextFieldProps, TextField } from '@mui/material';
import { style } from './style';

export type SFControlledInputProps = Partial<{
  isDirty: boolean;
  isTouched: boolean;
  invalid: boolean;
  errorMessage: string;
}>;

export type SFTextFieldProps = TextFieldProps & SFControlledInputProps;

/**
 * @deprecated This component shouldn't be used
 */
function SFTextField({
  invalid,
  errorMessage,
  isTouched,
  isDirty,
  sx,
  FormHelperTextProps,
  ...props
}: SFTextFieldProps) {
  return (
    <TextField
      error={invalid}
      helperText={errorMessage}
      {...props}
      sx={
        Array.isArray(sx)
          ? [style.input, ...sx]
          : [style.input, errorMessage ? style.inputError : null, sx]
      }
      FormHelperTextProps={{
        sx: style.helperText,
        ...FormHelperTextProps,
      }}
    />
  );
}

export default SFTextField;
