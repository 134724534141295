export const ROUTES = {
  auth: {
    login: {
      path: '/login',
    },
    forgotPassword: {
      path: '/forgot-password',
    },
    resetPassword: {
      path: '/reset-password',
    },
  },
  geofence: {
    fullPath: '/geofence/*',
    path: '/geofence',
    create: {
      fullPath: '/geofence/create',
      path: '/create',
    },
    edit: {
      getPath: (id: string) => `/geofence/edit/${id}`,
      path: '/edit/:id',
      fullPath: '/geofence/edit/:id',
    },
  },
  places: {
    fullPath: '/places/*',
    path: '/places',
    locations: {
      path: '/locations',
      fullPath: '/places/locations',
    },
    groups: {
      path: '/groups',
      fullPath: '/places/groups',
    },
  },
  protocols: {
    fullPath: '/protocols/*',
    path: '/protocols',
    rules: {
      path: '/rules',
      fullPath: '/protocols/rules',
    },
    alerts: {
      path: '/alerts',
      fullPath: '/protocols/alerts',
    },
  },
  personnel: {
    path: '/personnel',
    fullPath: '/personnel/*',
    vehicles: {
      path: '/vehicles',
      fullPath: '/personnel/vehicles',
    },
    teams: {
      path: '/teams',
      fullPath: '/personnel/teams',
    },
    devices: {
      path: '/devices',
      fullPath: '/personnel/devices',
    },
  },
  drivers: {
    path: '/drivers',
    fullPath: '/drivers/*',
  },
  admin: {
    path: '/admin',
    fullPath: '/admin/*',
    users: {
      path: '/user-management',
      fullPath: '/admin/user-management',
    },
    roles: {
      path: '/role-management',
      fullPath: '/admin/role-management',
    },
  },
  monitoring: {
    path: '/monitoring',
    fullPath: '/monitoring/*',
    searchParams: {
      transportID: 'transportID',
      startDate: 'startDate',
      endDate: 'endDate',
      tripID: 'tripID',
    },
  },
};
