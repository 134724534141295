import { Theme } from '@mui/material';

export const style = {
  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: 'repeat(3, auto)',
    height: 'fit-content',
    width: 'auto',
    overflowX: 'auto',
    alignItems: 'start',
    justifyContent: 'start',
    minWidth: 'auto',
    padding: '8px 14px 4px 14px',
    borderRadius: '4px',
    border: '1px solid #CCCCCC',
    gap: 2,
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    justifyContent: 'center',
  },
  movementStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  },
  circle: (inMovement: boolean) => (theme: Theme) => ({
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: inMovement
      ? theme.palette.success.main
      : theme.palette.action.disabled,
  }),
  title: {
    color: 'text.secondary',
  },
  titleValue: {
    color: '#000000',
    fontSize: 12,
    fontWeight: 500,
  },
};
