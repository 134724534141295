import { LogicalOperator, MatchingMethod } from '../../global';

interface QueryFilter {
  field: string;
  value?: null | string | number;
  values?: null | string[];
  matchingMethod?: MatchingMethod;
}

interface LogicalQuery {
  logicalOperator: LogicalOperator;
  filters: QueryFilter[];
}

class QueryBuilder {
  private query: LogicalQuery[] = [];

  private limit?: number;

  private offset?: number;

  private sanitizeFilter(filter: QueryFilter): QueryFilter {
    if (filter.values && filter.values.length === 0) {
      filter.values = null;
    }
    return filter;
  }

  private isEmptyFilter(filter: QueryFilter): boolean {
    const noValue = filter.value == null || filter.value === '';
    const noValues = !filter.values || filter.values.length === 0;
    return noValue && noValues;
  }

  public addLimit(value: number): this {
    this.limit = value;
    return this;
  }

  public addOffset(value: number): this {
    this.offset = value;
    return this;
  }

  /**
   * Creates or returns the single OR block if it already exists in 'query'.
   */
  private getOrCreateOrBlock(): LogicalQuery {
    // Try to find an existing OR block
    let orBlock = this.query.find(
      (block) => block.logicalOperator === LogicalOperator.OR,
    );

    // If none found, create a new one and push to 'query'
    if (!orBlock) {
      orBlock = {
        logicalOperator: LogicalOperator.OR,
        filters: [],
      };
      this.query.push(orBlock);
    }

    return orBlock;
  }

  /**
   * AND creates a new block in 'query'.
   */
  public and(filters: QueryFilter | QueryFilter[], skipIfEmpty = true): this {
    const filtersArray = Array.isArray(filters) ? filters : [filters];

    let prepared = filtersArray.map((f) => this.sanitizeFilter(f));
    if (skipIfEmpty) {
      prepared = prepared.filter((f) => !this.isEmptyFilter(f));
    }

    if (prepared.length > 0) {
      this.query.push({
        logicalOperator: LogicalOperator.AND,
        filters: prepared,
      });
    }

    return this;
  }

  /**
   * OR appends filters to a *single* OR block.
   * If that OR block doesn't exist, we create it here.
   */
  public or(filters: QueryFilter | QueryFilter[], skipIfEmpty = true): this {
    const filtersArray = Array.isArray(filters) ? filters : [filters];

    let prepared = filtersArray.map((f) => this.sanitizeFilter(f));
    if (skipIfEmpty) {
      prepared = prepared.filter((f) => !this.isEmptyFilter(f));
    }

    if (prepared.length > 0) {
      // Get (or create) the single OR block
      const orBlock = this.getOrCreateOrBlock();
      // Append new filters to that block
      orBlock.filters.push(...prepared);
    }
    return this;
  }
}

export default QueryBuilder;
