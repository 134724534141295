export const style = {
  label: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  field: {
    display: 'flex',
    gap: 2,
    flexDirection: 'column',
    marginTop: 4,
  },
  teamName: {
    fontWeight: 400,
  },
  option: {
    borderBottom: '1px solid #CCCCCC',
    display: 'flex',
    '&:last-child': {
      borderBottom: 'unset',
    },
    paddingTop: 2,
    paddingBottom: 2,
  },
};
