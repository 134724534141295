import { formatDuration, intervalToDuration } from 'date-fns';

const getDuration = (seconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });
  delete duration.seconds;
  return formatDuration(duration);
};

export default getDuration;
