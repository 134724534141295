import { ApplicationAction, ApplicationArea, LocationIconType } from '../types';
import { Icon } from '../../global';

export const NAV_DRAWER_COLLAPSED_WIDTH = 56;
export const NAV_DRAWER_WIDTH = 224;

export const ROLE_DEFAULT_AREAS = {
  [ApplicationArea.MONITORING]: ApplicationAction.NONE,
  [ApplicationArea.PERSONNEL]: ApplicationAction.NONE,
  [ApplicationArea.PLACES]: ApplicationAction.NONE,
  [ApplicationArea.GEOFENCES]: ApplicationAction.NONE,
  [ApplicationArea.PROTOCOLS]: ApplicationAction.NONE,
  [ApplicationArea.REMINDERS]: ApplicationAction.NONE,
  [ApplicationArea.REPORTS]: ApplicationAction.NONE,
};
export const Icons: Icon[] = [
  {
    id: '1',
    type: LocationIconType.CIRCLE,
  },
  {
    id: '2',
    type: LocationIconType.SQUARE,
  },
  {
    id: '3',
    type: LocationIconType.TRIANGLE,
  },
  {
    id: '4',
    type: LocationIconType.RHOMBUS,
  },
  {
    id: '5',
    type: LocationIconType.CROSS,
  },
  {
    id: '6',
    type: LocationIconType.STAR,
  },
];

export const MAPBOX_API_KEY =
  'pk.eyJ1IjoidHllbmdpYmFyeWFuIiwiYSI6ImNtNG9taHpkajBtNmMyanIybWVydWJuMDQifQ.mBuGDqlE0Zk9q3pRhVGS1A';

export const DEFAULT_TEAM_NAME = 'Individuals';

export const LIST_DEFAULT_ITEM_LIMIT = 10;
