import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../layout/store';
import { MonitoringTeamDTO } from '../types';

export const getMonitoringSelector = (store: RootState) =>
  store.monitoringSlice;

export const getMonitoringTeamsSelector = createSelector(
  getMonitoringSelector,
  (monitoring) => Object.values(monitoring.map.teams),
);

export const getMonitoringTeamSelector = (id: number) =>
  createSelector(
    getMonitoringTeamsSelector,
    (teams): MonitoringTeamDTO =>
      teams.find((team) => team.id === id) as MonitoringTeamDTO,
  );

export const getMonitoringSidebarFilterSelector = createSelector(
  getMonitoringSelector,
  (state) => state.sidebarFilters,
);

export const getMonitoringSearchResultsSelector = createSelector(
  getMonitoringSelector,
  (monitoring) => monitoring.sidebarFilters.searchResults,
);

export const getMonitoringIsSearchingSelector = createSelector(
  getMonitoringSearchResultsSelector,
  (searchResults) => searchResults.searching,
);

export const getMonitoringTransportByIdSelector = (id: number) =>
  createSelector(getMonitoringSelector, (state) => state.map.transports[id]);

export const getMonitoringDriversByIDsSelector = (ids: number[]) =>
  createSelector(getMonitoringSelector, (state) =>
    ids.map((id) => state.map.transports[id]),
  );

export const getMonitoringVehiclesSelector = createSelector(
  getMonitoringSelector,
  (monitoring) => Object.values(monitoring.map.transports),
);

export const getMonitoringVehiclesWithPositioningSelector = createSelector(
  getMonitoringVehiclesSelector,
  (vehicles) =>
    vehicles.filter((vehicle) => vehicle.latitude && vehicle.longitude),
);

export const getMonitoringTrackInfoSelector = createSelector(
  getMonitoringSelector,
  (monitoring) => monitoring.trackInfo,
);

export const getMonitoringFilterSelector = createSelector(
  getMonitoringSelector,
  (monitoring) => monitoring.filter,
);

export const getMonitoringFilteredTeamsSelector = createSelector(
  getMonitoringSidebarFilterSelector,
  (sidebarFilter) => sidebarFilter.teamIds,
);

export const getMonitoringSelectedTransportSelector = (
  selectedTransportId: string | null,
) =>
  createSelector(getMonitoringSelector, (monitoring) =>
    selectedTransportId ? monitoring.map.transports[selectedTransportId] : null,
  );

export const getMonitoringCourierNameByTransportId = (
  transportId: number | undefined,
) =>
  createSelector(getMonitoringSelector, (monitoring) =>
    transportId
      ? Object.values(monitoring.map.transports).find(
          (transport) => transport.transportId === transportId,
        )?.courierName
      : null,
  );

export const getMonitoringTripsSelector = createSelector(
  getMonitoringSelector,
  (monitoring) => monitoring.trips,
);

export const getMonitoringAllTripsSelector = createSelector(
  getMonitoringTripsSelector,
  (trips) => trips.all,
);

export const getMonitoringSelectedTripSelector = (
  selectedTripId: string | null,
) =>
  createSelector(getMonitoringTripsSelector, (trips) => {
    return selectedTripId === 'all'
      ? trips.overallTrip
      : trips.all.find((trip) => trip.id === Number(selectedTripId));
  });

export const getMonitoringTripDetailsSelector = (tripId: string) =>
  createSelector(getMonitoringTripsSelector, (trips) =>
    tripId === 'all'
      ? trips.overallTrip
      : trips.all.find((trip) => String(trip.id) === tripId),
  );

export const getMonitoringTripStateSelector = createSelector(
  getMonitoringTripsSelector,
  (trips) => trips.tripState,
);

export const getMonitoringTripSpeedSelector = createSelector(
  getMonitoringTripsSelector,
  (trips) => trips.tripSpeed,
);

export const getMonitoringTripDraggedProgressSelector = createSelector(
  getMonitoringTripsSelector,
  (trips) => trips.tripDraggedProgress,
);

export const getMonitoringTripPreviewProgressSelector = createSelector(
  getMonitoringTripsSelector,
  (trips) => trips.previewProgress,
);

export const getMonitoringTripProgressSelector = createSelector(
  getMonitoringTripsSelector,
  (trips) => trips.tripProgress,
);

export const isMonitoringTripsEmptyData = createSelector(
  getMonitoringTripsSelector,
  (trips) => !trips.loading && trips.all.length === 0,
);

export const isMonitoringTripsLoading = (tab: string) =>
  createSelector(getMonitoringSelector, (monitoring) =>
    tab === 'general' ? monitoring.general.isLoading : monitoring.trips.loading,
  );

export const getMonitoringGeneralInfoSelector = createSelector(
  getMonitoringSelector,
  (monitoring) => monitoring.general,
);

export const getMonitoringAdjacentTripIdsSelector = (
  selectedTripId: string | null,
) =>
  createSelector(getMonitoringTripsSelector, (trips) => {
    if (selectedTripId === 'all') {
      return {
        previousTripID: null,
        nextTripID: trips.all[0]?.id || null,
      };
    }

    const currentTripIndex = trips.all.findIndex(
      (trip) => trip.id === Number(selectedTripId),
    );
    const previousTrip = trips.all[currentTripIndex - 1];
    const nextTrip = trips.all[currentTripIndex + 1];
    return {
      previousTripID: previousTrip?.id || null,
      nextTripID: nextTrip?.id || null,
    };
  });

export const getMonitoringModeSelector = createSelector(
  getMonitoringSelector,
  (monitoring) => monitoring.mode,
);
