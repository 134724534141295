import { createSlice } from '@reduxjs/toolkit';
import { LayoutSlice } from '../types';
import {
  getNavDrawerCollapsedState,
  NAVIGATION_COLLAPSE_STATE,
} from '../utils';

const initialState: LayoutSlice = {
  navDrawerCollapsed: getNavDrawerCollapsedState(),
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openNavDrawer: (state) => {
      state.navDrawerCollapsed = false;
      localStorage.setItem(NAVIGATION_COLLAPSE_STATE, 'false');
    },
    closeNavDrawer: (state) => {
      state.navDrawerCollapsed = true;
      localStorage.setItem(NAVIGATION_COLLAPSE_STATE, 'true');
    },
    toggleNavDrawer: (state) => {
      localStorage.setItem(
        NAVIGATION_COLLAPSE_STATE,
        String(!state.navDrawerCollapsed),
      );
      state.navDrawerCollapsed = !state.navDrawerCollapsed;
    },
  },
});

export const { openNavDrawer, closeNavDrawer, toggleNavDrawer } =
  layoutSlice.actions;
