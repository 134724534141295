import { VehicleLightDTO } from '../../shared/types';

export enum DriverRole {
  SUPPLIER = 'SUPPLIER',
  MANAGER = 'MANAGER',
  PRESELLER = 'PRESELLER',
}

export type DriverFormDTO = {
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  role: DriverRole;
  transport: null | VehicleLightDTO;
  notes: string;
  profileImageId: string;
};

export type DriverTeamChangeDTO = {
  courierIds: number[];
  action: string;
  teamId?: number;
  roles?: string;
};

export const enum SearchOption {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
}
