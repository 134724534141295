import { SvgIconProps } from '@mui/material';
import { TableFilterDTO } from '../../global';
import { ColorLabel } from '../../global/components/SFColorPalette';
import { DriverDTO } from '../../services/driver.service';
import { DeviceLightDTO } from '../../services/device.service';
import { TeamLightDTO } from '../../services/team.service';
import { GroupMember } from '../components/GroupListView';

export type VehicleDTO = {
  id: number;
  name: string;
  courier: null | DriverDTO;
  vehicleType: VehicleType;
  licensePlate: string;
  currentKilometer: number;
  vin: string;
  avgConsumption: number;
  imageId: string;
  makeAndModel: null;
  manufacturingYear: null;
  fuelType: null;
  lastMaintenanceDate: null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  device: DeviceLightDTO | null;
  team: TeamLightDTO | null;
};

export enum WorkingSchedule {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}

export enum VehicleType {
  CAR = 'CAR',
  MOTO = 'MOTO',
  BIKE = 'BIKE',
  WALK = 'WALK',
  TRUCK = 'TRUCK',
}

export type VehicleListFilterDTO = TableFilterDTO & {
  type?: VehicleType;
};
export interface Group {
  name: string;
  id: number;
  members: Array<GroupMember>;
}

export interface GroupListItemProps {
  group: Group;
}

export type VehicleLightDTO = {
  courier: null | {
    firstName: string;
    lastName: string;
  };
  team: null | {
    name: string;
  };
  device: null | {
    name: string;
  };
  currentKilometer: number;
  licensePlate: string;
  id: number;
  name: string;
  vehicleType: VehicleType;
  imageId: string | null;
};

type Geo = {
  name: string;
  address: string;
  country: string;
  province: string;
  locality: string;
  street: string;
  house: string;
  lat: number;
  lng: number;
};
export enum SearchOption {
  NAME = 'name',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TERM = 'term',
}
export enum IconVariant {
  ABSTRACT = 'ABSTRACT',
  NATURAL = 'NATURAL',
  TECHNICAL = 'TECHNICAL',
  MAP_ICON = 'MAPICON',
}
export type LocationIconProps = SvgIconProps & {
  variant: IconVariant;
  round?: boolean;
};

export type GeoResponse = Geo[];

export enum DriverRole {
  SUPPLIER = 'SUPPLIER',
  MANAGER = 'MANAGER',
  PRESELLER = 'PRESELLER',
}

export type GeofenceListResponseDTO = {
  items: Array<{
    id: string;
    name: string;
    description: string;
    polygon: Array<[Array<number>]>;
    createdAt: string;
    updatedAt: string;
    label: ColorLabel;
  }>;
  limit?: number;
  moreItems?: boolean;
  offset?: number;
  totalItems?: number;
};

export enum ApplicationArea {
  REPORTS = 'REPORTS',
  REMINDERS = 'REMINDERS',
  PROTOCOLS = 'RULES_ALERTS',
  GEOFENCES = 'GEOFENCES',
  PLACES = 'LOCATION_GROUPS',
  PERSONNEL = 'VEHICLES_DRIVERS_TEAMS',
  MONITORING = 'MONITORING',
  USERS = 'USERS',
}

export enum ApplicationAction {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  NONE = '',
}
export interface LocationIconPalette {
  markerFill: string;
  markerBackground: string;
  position: string;
  backgroundColor: string;
}

export interface Permission {
  area: ApplicationArea;
  action: ApplicationAction;
}

export interface RoleFormValues {
  name: string;
  description: string;
  area: Record<ApplicationArea, ApplicationAction>;
}

export type GeoAddress = {
  label: string;
  lat: number;
  lng: number;
};

export enum TransportFuelType {
  GASOLINE = 'GASOLINE',
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRICAL',
  HYBRID = 'HYBRID',
}

export interface MessageDTO {
  latitude: number;
  longitude: number;
  speed: number;
  createdAt: string;
  engineRpm: number | null;
  bearing: number;
}

export enum LocationIconType {
  STAR = 'STAR',
  SQUARE = 'SQUARE',
  TRIANGLE = 'TRIANGLE',
  CIRCLE = 'CIRCLE',
  CROSS = 'CROSS',
  RHOMBUS = 'RHOMBUS',
}
