import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import hyTranslation from '../../../assets/localizations/hy.json';
import englishTranslation from '../../../assets/localizations/en.json';
import esTranslation from '../../../assets/localizations/es.json';
import frTranslation from '../../../assets/localizations/fr.json';
import ruTranslation from '../../../assets/localizations/ru.json';
import itTranslation from '../../../assets/localizations/it.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: englishTranslation,
    },
    hy: {
      translation: hyTranslation,
    },
    es: {
      translation: esTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
    it: {
      translation: itTranslation,
    },
  },
});

export default i18n;
