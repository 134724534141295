import { Theme } from '@mui/material';

export const style = {
  iconWrapper: (theme: Theme) => ({
    borderRadius: 1,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
};
