const LOCALSTORAGE_ACCESS_TOKEN = 'access_token';
const LOCALSTORAGE_REFRESH_TOKEN = 'refresh_token';
const HAS_NUMBER_REGEX = /\d/;
const HAS_LOWER_UPPER_CASE_REGEX = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
const HAS_SPECIAL_CHARACTER_REGEX = /[!@#$%^&*(),.?":{}|<>]/;

/*
 * Password matching expression.
 * Match all alphanumeric character and predefined wild characters.
 * Password must consists of at least 8 characters and not more than 15 characters.
 */
const PASSWORD_VALIDATION_REGEX = /^([a-zA-Z0-9@*#!]{8,15})$/;

export {
  PASSWORD_VALIDATION_REGEX,
  LOCALSTORAGE_REFRESH_TOKEN,
  LOCALSTORAGE_ACCESS_TOKEN,
  HAS_NUMBER_REGEX,
  HAS_LOWER_UPPER_CASE_REGEX,
  HAS_SPECIAL_CHARACTER_REGEX,
};
