export const style = {
  appBar: {
    boxShadow: '0px 1px 0px #CCCCCC',
    height: 60,
    backgroundColor: 'common.white',
    cursor: 'unset',
    color: 'unset',
    position: 'sticky',
    top: 0,
  },
  leftMenu: {
    display: 'flex',
    alignItems: 'center',
    flex: { xs: 1, sm: 0 },
  },
  menuBar: {
    borderRadius: 1,
    p: 2,
  },
  logo: {
    marginLeft: { xs: 0, sm: 3 },
    position: { xs: 'absolute', sm: 'static' },
    left: { xs: '50%', sm: 'initial' },
    transform: { xs: 'translate(-50%)', sm: 'initial' },
  },
  menuIcon: {
    // color: '#323232',
  },
};
