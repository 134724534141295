import { grey } from '@mui/material/colors';

export const style = {
  wrapper: {
    display: { xs: 'flex', sm: 'none' },
    justifyContent: 'center',
    py: 4,
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 1,
  },
  puller: {
    width: 140,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    flexShrink: 0,
  },
};
