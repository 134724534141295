import { GroupFormDTO } from '../../groups/types';
import { LocationIconType } from '../../shared/types';

export type LocationFilterDTO = {
  search?: string;
  limit?: number;
  offset?: number;
  statusIds?: string[];
  group?: string | null | number;
};

export enum LocationSearchOption {
  NAME = 'name',
  CODE = 'code',
  ADDRESS = 'address',
  GROUP = 'group',
  STATUS = 'status',
}

export type LocationFormDTO = {
  id: number;
  name: string;
  address: {
    lat: number;
    lng: number;
    label: string;
  };
  coordinates: string;
  code: string;
  group: null | GroupFormDTO;
  status: number;
  icon: LocationIconType;
  notes: string;
  contact: string;
  imageId?: string;
};

export type LocationsBulkDTO = {
  locationIds: number[];
  groupId?: number;
  notes?: string;
  icon?: string;
  status?: number;
  contact?: string;
};
