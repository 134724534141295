export const style = {
  image: {
    borderRadius: '50%',
    aspectRatio: '1/1',
    objectFit: 'cover',
    boxShadow: 2,
  },
  pointer: {
    cursor: 'pointer',
  },
};
