import ApiService from '../global/services/api.service';

class MediaService {
  static ENDPOINT = '/media';

  static uploadMedia(directory: string, file: File) {
    const formData = new FormData();
    formData.append('avatar', file);
    formData.append('directory', directory);
    return ApiService.post<{ mediaId: string }>(
      MediaService.ENDPOINT,
      formData,
    );
  }

  static isMediaSizeValid(file: File) {
    return file.size <= 1024 * 1024;
  }

  static getUrlOfImage(id: string) {
    return `${ApiService.BASE_URL}/media/${id}`;
  }
}

export default MediaService;
