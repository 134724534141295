import { ApiRequest, ApiResponse } from './api.service';

export interface MiddlewareContext {
  request: ApiRequest;
  response?: ApiResponse;
  error?: Error;
}

export type NextFunction = () => Promise<void>;
export type MiddlewarePhase = 'request' | 'response';

export interface IMiddleware {
  onRequest?(context: MiddlewareContext, next: NextFunction): Promise<void>;
  onResponse?(context: MiddlewareContext, next: NextFunction): Promise<void>;
}

export interface IMiddlewareManager {
  use(middleware: IMiddleware, phase: MiddlewarePhase): void;
  executeRequestMiddlewares(context: MiddlewareContext): Promise<void>;
  executeResponseMiddlewares(context: MiddlewareContext): Promise<void>;
}

export class MiddlewareManager implements IMiddlewareManager {
  private middlewares: IMiddleware[] = [];

  use(middleware: IMiddleware): void {
    if (
      this.middlewares.some(
        (m) => m.constructor.name === middleware.constructor.name,
      )
    ) {
      return;
    }
    this.middlewares.push(middleware);
  }

  async executeRequestMiddlewares(context: MiddlewareContext): Promise<void> {
    const executeChain = async (index: number): Promise<void> => {
      if (index >= this.middlewares.length) {
        return Promise.resolve();
      }
      const middleware = this.middlewares[index];
      if (typeof middleware.onRequest === 'function') {
        await middleware.onRequest(context, () => executeChain(index + 1));
        return Promise.resolve();
      }
      await executeChain(index + 1);
      return Promise.resolve();
    };

    await executeChain(0);
  }

  async executeResponseMiddlewares(context: MiddlewareContext): Promise<void> {
    const executeChain = async (index: number): Promise<void> => {
      if (index >= this.middlewares.length) {
        return Promise.resolve();
      }
      const middleware = this.middlewares[index];
      if (typeof middleware.onResponse === 'function') {
        await middleware.onResponse(context, () => executeChain(index + 1));
      }
      await executeChain(index + 1);
      return Promise.resolve();
    };

    await executeChain(0);
  }
}
