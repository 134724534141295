import { Control } from 'ol/control';
import { SFMapType } from './map.service';
import { MapControlType, SFControlType } from '../types';
import './control.css';

class SFControl {
  instance: SFMapType;

  right = 8;

  left = 8;

  subscribers: (() => void)[] = [];

  constructor(instance: SFMapType) {
    this.instance = instance;
    this.right = 8;
    this.left = 8;
    this.subscribers = [];
  }

  attachControls(controls: SFControlType[]) {
    controls.forEach(
      ({ control, controlType, width, customCSS, position, hidden }) => {
        this.instance.map.addControl(control);
        this.onControlRender(
          controlType,
          {
            right: this.right,
            left: this.left,
            position,
            width,
          },
          customCSS,
        );
        if (!hidden) {
          this[position] = this[position] + width + 8;
        }
      },
    );

    const cleanup = () => {
      controls.forEach(({ control, width, hidden, position }) => {
        this.instance.map.removeControl(control);
        if (!hidden) {
          this[position] = this[position] - width - 8;
        }
      });
    };

    return { cleanup };
  }

  attachControl(control: Control) {
    this.instance.map.addControl(control);

    const cleanup = () => {
      this.instance.map.removeControl(control);
    };

    return { cleanup };
  }

  // I know that this is not the best solution
  // but we don't have enough time to come up with
  // another solution, if you would like to refactor this
  // you will get bounty from Tigran
  subscribeToChange(fn: () => void) {
    this.subscribers.push(fn);

    const cleanup = () => {
      this.subscribers = this.subscribers.filter(
        (subscriber) => subscriber !== fn,
      );
    };

    return { cleanup };
  }

  private onControlRender(
    control: MapControlType,
    calculatedCSS: {
      right: number;
      left: number;
      position: 'left' | 'right';
      width: number;
    },
    customCSS: Record<string, string>,
  ) {
    const intervalID = setInterval(() => {
      const element = document.getElementById(control);
      if (element) {
        const css: Record<string, string> = {
          display: 'block',
          width: `${calculatedCSS.width}px`,
          ...customCSS,
        };
        if (calculatedCSS.position === 'right') {
          css.right = `${calculatedCSS.right}px`;
        } else {
          css.left = `${calculatedCSS.left}px`;
        }
        Object.keys(css).forEach((key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          element.style[key] = css[key];
        });
        clearInterval(intervalID);
        this.subscribers.forEach((subscriber) => subscriber());
      }
    }, 100);
  }
}

export default SFControl;
