import ApiService from '../global/services/api.service';
import {
  RuleConditionOperator,
  RuleDTO,
  RuleFilterDTO,
  RuleFormDTO,
  RuleNotificationTechnicalType,
  RuleRequestDTO,
  RuleSearchOption,
  RuleType,
} from '../rules/types';
import QueryBuilder from '../shared/utils/query-builder';
import { ListResponseDTO, MatchingMethod } from '../global';

class RuleService {
  static ENDPOINT = '/rules';

  static getRules(filter: RuleFilterDTO) {
    const ruleFilter = filter.rule;

    const body = new QueryBuilder()
      .addLimit(filter?.limit || 20)
      .addOffset(filter?.offset || 0);
    body
      .and({
        field: RuleSearchOption.ENABLED,
        value: filter.status,
        matchingMethod: MatchingMethod.EXACT,
      })
      .and({
        value: ruleFilter.length === 1 ? ruleFilter[0] : null,
        values: ruleFilter.length > 1 ? ruleFilter : null,
        field: RuleSearchOption.TYPE,
        matchingMethod:
          ruleFilter.length === 1
            ? MatchingMethod.EXACT
            : MatchingMethod.CONTAINS,
      })
      .and({
        field: RuleSearchOption.COURIER,
        value: filter.driverID ? String(filter.driverID) : null,
        matchingMethod: MatchingMethod.EXACT,
      })
      .and({
        field: RuleSearchOption.VEHICLE,
        value: filter.vehicleID ? String(filter.vehicleID) : null,
        matchingMethod: MatchingMethod.EXACT,
      });
    return ApiService.post<ListResponseDTO<RuleDTO>>(
      `${RuleService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static createRule(values: RuleFormDTO) {
    const body: RuleRequestDTO = {
      type: values.type,
      firingDuration: Number(values.firingDuration),
      resolvingDuration: Number(values.resolvingDuration),
      firingUnit: values.firingUnit,
      resolvingUnit: values.resolvingUnit,
      transports: values.access.transportIds,
      teams: values.access.teamIds,
      conditions:
        values.type === RuleType.STOP_TIME
          ? null
          : values.conditions?.map((c) => ({
              operator: c.operator || RuleConditionOperator.GREATER_THAN,
              value: Number(c.value),
            })),
      notificationSettings: values.notifications.map((nt) => ({
        notificationType: nt as RuleNotificationTechnicalType,
        sendImmediately: Boolean(values.notificationTypes[nt]?.sendImmediately),
      })),
      recipients: values.recipients.map((r) => r.id),
      geofence: values.geofence
        ? {
            name: values.geofence.name,
            label: values.geofence.label,
            id: values.geofence.id,
            exclude: values.geofence.exclude || true,
          }
        : null,
    };
    return ApiService.post(`${RuleService.ENDPOINT}`, body);
  }

  static editRule(ruleId: number, values: RuleFormDTO) {
    const body: Partial<RuleRequestDTO> = {
      transports: values.access.transportIds,
      teams: values.access.teamIds,
      recipients: values.recipients.map((r) => r.id),
      notificationSettings: values.notifications.map((nt) => ({
        notificationType: nt as RuleNotificationTechnicalType,
        sendImmediately: Boolean(values.notificationTypes[nt]?.sendImmediately),
      })),
      enabled: values.enabled,
    };

    return ApiService.patch(`${RuleService.ENDPOINT}/${ruleId}`, body);
  }

  static deleteRule(ruleId: number) {
    return ApiService.delete(`${RuleService.ENDPOINT}/${ruleId}`);
  }

  static getRulesById(id: number) {
    return ApiService.get<RuleDTO>(`${RuleService.ENDPOINT}/${id}`);
  }
}
export default RuleService;
