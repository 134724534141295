import ApiService from '../global/services/api.service';
import { GeoResponse, StrictGeoResponse } from '../layout/types';

class GeoService {
  static ENDPOINT = '/geo';

  static reverseSearch = (search: number[]) =>
    ApiService.get<StrictGeoResponse>(
      `${GeoService.ENDPOINT}/reverse-search/`,
      { lat: String(search[1]), lng: String(search[0]) },
      { cache: true },
    );

  static search = (term: string) =>
    ApiService.get<GeoResponse>(`${GeoService.ENDPOINT}/search/`, { term });
}

export default GeoService;
