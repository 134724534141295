import ApiService from '../global/services/api.service';
import { ListDTO, TableFilterDTO } from '../global';
import { UserFormRequestDTO, SearchOption, UserFormDTO } from '../users/types';
import { MatchingMethod } from '../monitoring/types';
import QueryBuilder from '../shared/utils/query-builder';
import { RoleService } from './index';
import { RoleLightDTO } from './role.service';

export interface UserLightDTO {
  email: string;
  phone: string;
  isAccountDisabled: boolean;
  id: number;
  firstName: string;
  lastName: string;
  role: Partial<RoleLightDTO>;
  profileImageId: string | null;
}

interface TeamTransportEntity {
  id: number;
  name: string;
}

export interface UserDTO extends UserLightDTO {
  access: {
    level: string;
    teams: TeamTransportEntity[];
    transports: TeamTransportEntity[];
  };
}

class UserService {
  static ENDPOINT = '/users';

  static getUsers(filter: TableFilterDTO) {
    const body = new QueryBuilder()
      .addOffset(filter.offset)
      .addLimit(filter.limit)
      .or({
        field: SearchOption.FIRST_NAME,
        value: filter.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: SearchOption.LAST_NAME,
        value: filter.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: SearchOption.EMAIL,
        value: filter.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: SearchOption.PHONE,
        value: filter.search,
        matchingMethod: MatchingMethod.CONTAINS,
      });

    return ApiService.post<ListDTO<UserLightDTO>>(
      `${UserService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static async inviteUser(formValues: UserFormDTO) {
    const body = await UserService.getUserRequestPayload(formValues);

    if (!body) {
      return null;
    }

    return ApiService.post(`/account/invite`, body);
  }

  static getUserById(id: number) {
    return ApiService.get<UserDTO>(`${UserService.ENDPOINT}/${id}`);
  }

  static deleteUser(id: number) {
    return ApiService.delete(`${UserService.ENDPOINT}/${id}`);
  }

  static async updateUser(id: number, formValues: UserFormDTO) {
    const body = await UserService.getUserRequestPayload(formValues);

    if (!body) {
      return null;
    }

    return ApiService.patch(`${UserService.ENDPOINT}/${id}`, body);
  }

  static async getUserRequestPayload(values: UserFormDTO) {
    const { area, name, description, ...formValues } = values;
    const body: UserFormRequestDTO = {
      ...formValues,
      access: {
        level: formValues.access.level,
        teamIds: formValues.access.teamIds.map((teamId) => Number(teamId)),
        transportIds: formValues.access.transportIds || [],
      },
    };
    const roleID = formValues.roleId;
    if (roleID === 'custom') {
      const { data, ok } = await RoleService.createRole({
        name,
        description,
        area,
      });
      if (!ok) {
        return null;
      }
      body.roleId = data.id;
    } else {
      body.roleId = Number(roleID);
    }
    return body;
  }
}

export default UserService;
