import { Collection, Feature } from 'ol';
import { Circle, Point } from 'ol/geom';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { Modify } from 'ol/interaction';
import { Coordinate } from 'ol/coordinate';
import { SFMapType } from './map.service';

class SFCircle {
  instance: SFMapType;

  circleFeature: Feature<Circle> | null;

  markerFeature: Feature<Point> | null;

  circleLayer: Vector<VectorSource<Feature<Circle>>> | null;

  markerLayer: Vector<VectorSource<Feature<Point>>> | null;

  onCenterChanged: (center: number[]) => void;

  constructor(instance: SFMapType) {
    this.instance = instance;
    this.circleFeature = null;
    this.markerFeature = null;
    this.circleLayer = null;
    this.markerLayer = null;
    this.onCenterChanged = () => {};
  }

  createCircle(
    center: number[],
    radius: number,
    onCenterChanged: (center: number[]) => void,
  ) {
    const { markerLayer } = this.createMarker(center);
    const circleGeometry = new Circle(center, radius);
    this.circleFeature = new Feature(circleGeometry);
    this.onCenterChanged = onCenterChanged;
    onCenterChanged(center);
    const circleStyle = new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)',
      }),
    });
    this.circleLayer = new Vector({
      source: new VectorSource({
        features: [this.circleFeature],
      }),
      style: circleStyle,
    });
    this.instance.addOverlayLayer(this.circleLayer);

    return {
      cleanup: this.cleanupCircle.bind(this),
      markerLayer,
      instance: this,
    };
  }

  private createMarker(center: number[]) {
    const markerGeometry = new Point(center);
    this.markerFeature = new Feature(markerGeometry);
    this.markerLayer = new Vector({
      source: new VectorSource({
        features: [this.markerFeature],
      }),
    });
    const modify = new Modify({
      features: new Collection([this.markerFeature]),
      style: [],
    });

    this.instance.map.addInteraction(modify);
    this.instance.map.on('pointermove', this.handleMarkerDragged.bind(this));

    this.instance.addOverlayLayer(this.markerLayer);

    return { markerLayer: this.markerLayer };
  }

  handleMarkerDragged() {
    if (this.markerFeature && this.circleFeature) {
      const markerGeometry = this.markerFeature.getGeometry();
      const circleGeometry = this.circleFeature.getGeometry();
      if (markerGeometry && circleGeometry) {
        const newCenter = markerGeometry.getCoordinates();
        circleGeometry.setCenter(newCenter);
        this.onCenterChanged(newCenter);
      }
    }
  }

  setCenter(center: Coordinate) {
    if (this.circleFeature && this.markerFeature) {
      const markerGeometry = this.markerFeature.getGeometry();
      const circleGeometry = this.circleFeature.getGeometry();

      if (markerGeometry) {
        markerGeometry.setCoordinates(center);
      }

      if (circleGeometry) {
        circleGeometry.setCenter(center);
      }
      this.onCenterChanged(center);
    }
  }

  cleanupCircle() {
    if (this.circleLayer && this.markerLayer) {
      this.instance.removeOverlayLayer(this.circleLayer);
      this.instance.removeOverlayLayer(this.markerLayer);
      this.instance.map.un('pointermove', this.handleMarkerDragged);
    }
  }
}

export default SFCircle;
