export const style = {
  overlay: {
    width: 336,
    pointerEvents: 'all',
  },
  cardContent: {
    ':last-child': {
      paddingBottom: 1,
    },
    gap: 4,
    display: 'flex',
    flexDirection: 'column',
    width: 325,
    paddingBottom: 1,
    paddingTop: 2,
    paddingLeft: 4,
    paddingRight: 4,
  },
  card: { borderRadius: 2 },
};
