import { Card, CardContent, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { style } from '../MonitoringVehicles/style';
import MonitoringDeviceDetails from '../MonitoringDeviceDetails';
import { useAppDispatch, useAppSelector } from '../../../layout/store';
import { getMonitoringTransportByIdSelector } from '../../selectors';
import { getMonitoringTransportById } from '../../services/monitoring.service';

interface MonitoringVehicleRealTimeInfoProps {
  id: number;
}

function MonitoringVehicleRealTimeInfo({
  id,
}: MonitoringVehicleRealTimeInfoProps) {
  const transport = useAppSelector(getMonitoringTransportByIdSelector(id));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMonitoringTransportById(id));
  }, [dispatch, id]);

  return (
    <Card sx={style.card}>
      <CardContent sx={style.cardContent}>
        {!transport && (
          <Skeleton
            variant='rounded'
            animation='wave'
            height={226}
            width={304}
          />
        )}
        {transport && <MonitoringDeviceDetails vehicleInfo={transport} />}
      </CardContent>
    </Card>
  );
}

MonitoringVehicleRealTimeInfo.displayName = 'MonitoringVehicleRealTimeInfo';

export default MonitoringVehicleRealTimeInfo;
