/**
 * Superfleet.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/10/2023
 * All Rights Reserved.
 */
import { Control, Controller, ControllerProps } from 'react-hook-form';
import { ComponentType } from 'react';
import {
  AutocompleteProps,
  MenuItemProps,
  SelectProps,
  SliderProps,
  TextFieldProps,
} from '@mui/material';
import { SFTextField } from '../index';
import { getFieldErrorMessage } from '../../utils';
import { Icon } from '../../types';

type Props = Omit<Omit<ControllerProps, 'render'>, 'control'> & {
  Component?: ComponentType<any>;
  render?: ControllerProps['render'];
  options?: any[];
  menuItemProps?: MenuItemProps;
  control: Control<any>;
  sitekey?: string;
  dirtyText?: string;
  hideErrorMessage?: boolean;
  minDate?: Date;
  disableClearable?: boolean;
  extractValue?: boolean;
  step?: number | string;
  icons?: Icon[];
  handleChange?: (...args: any[]) => any;
  directory?: string;
  canReset?: boolean;
} & Partial<TextFieldProps> &
  Partial<SelectProps> &
  Partial<SliderProps> &
  Partial<AutocompleteProps<any, any, any, any>>;

/**
 * @deprecated This component shouldn't be used
 */
function SFFieldController({
  Component = SFTextField,
  name,
  control,
  render,
  children = null,
  handleChange,
  ...componentProps
}: Props) {
  return (
    <Controller
      render={
        render ||
        (({
          field: { ref, onChange, ...field },
          fieldState,
          formState: state,
          ...controlProps
        }) => (
          <Component
            {...field}
            {...controlProps}
            {...componentProps}
            inputRef={ref}
            errorMessage={getFieldErrorMessage(state.errors, name)}
            onChange={(...args: any[]) => {
              onChange(...args);
              if (typeof handleChange === 'function') {
                handleChange(...args);
              }
            }}
          >
            {children}
          </Component>
        ))
      }
      name={name}
      control={control}
    />
  );
}

SFFieldController.displayName = 'SFFieldController';

export default SFFieldController;
