import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../global/services/api.service';
import { ListResponseDTO, MatchingMethod } from '../../global';
import {
  MonitoringDriverDTO,
  MonitoringGeneralInfo,
  MonitoringRequestFilter,
  MonitoringSearchOption,
  MonitoringTeam,
  MonitoringTransportSearchParams,
  MonitoringVehicleDetailedDTO,
  MonitoringVehicleDTO,
} from '../types';
import { MessageDTO } from '../../shared/types';
import { AppDispatch, RootState } from '../../layout/store';
import QueryBuilder from '../../shared/utils/query-builder';
import { LIST_DEFAULT_ITEM_LIMIT } from '../../shared/utils/constants';

export const getMonitoringTeams = createAsyncThunk(
  'monitoring/getMonitoringTeams',
  async () => {
    const { data } =
      await ApiService.get<ListResponseDTO<MonitoringTeam>>(
        '/monitoring/teams',
      );
    return (data?.items || []).map((item: MonitoringTeam) => ({
      id: item.id,
      name: item.name,
      total: item.transportsCount,
      drivers: [],
    }));
  },
);

export const getMonitoringDrivers = createAsyncThunk(
  'monitoring/getMonitoringDrivers',
  async (id: number) => {
    const { data } = await ApiService.post<
      ListResponseDTO<MonitoringDriverDTO>
    >('/monitoring/transports/', {
      query: [
        {
          filters: [
            {
              field: 'team:id',
              value: String(id),
              matchingMethod: MatchingMethod.EXACT,
              values: null,
            },
          ],
        },
      ],
    });
    return { transports: data?.items || [], teamId: id };
  },
);

export const getMonitoringTransportsByTerm = createAsyncThunk(
  'monitoring/getMonitoringDriversByTerm',
  async (data: MonitoringTransportSearchParams) => {
    const { search, team } = data;
    if (!data) {
      return { drivers: [], total: 0 };
    }
    const body = new QueryBuilder()
      .addOffset(0)
      .addLimit(100)
      .or({
        field: MonitoringSearchOption.LICENSE_PLATE,
        value: search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: MonitoringSearchOption.DRIVER_NAME,
        value: search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .and({
        field: MonitoringSearchOption.TEAM_ID,
        values: team,
        matchingMethod: MatchingMethod.CONTAINS,
      });

    const { data: transportsData } = await ApiService.post<
      ListResponseDTO<MonitoringDriverDTO>
    >('/monitoring/transports/', body);
    return {
      drivers: transportsData?.items,
      total: transportsData?.totalItems,
    };
  },
);

export const getMonitoringVehicles = createAsyncThunk(
  'monitoring/getMonitoringTransports',
  async () => {
    const { data } = await ApiService.post<
      ListResponseDTO<MonitoringVehicleDTO>
    >('/monitoring/transports/locations', {
      query: [],
      offset: 0,
      limit: LIST_DEFAULT_ITEM_LIMIT,
    });
    return { transports: data?.items, total: data?.totalItems };
  },
);

export const getMonitoringTransportById = createAsyncThunk(
  'monitoring/getMonitoringTransportById',
  async (vehicleID: number) => {
    const { data, ok } =
      await MonitoringService.getMonitoringVehicleById(vehicleID);

    if (ok) {
      const { transportStatus, ...rest } = data;

      return { ...rest, status: transportStatus, id: vehicleID };
    }
    return { id: vehicleID };
  },
);

export interface MonitoringTripDTO {
  id: number;
  deviceId: number;
  transportId: number;
  startTime: string;
  endTime: string;
  startLatitude: number;
  startLongitude: number;
  endLongitude: number;
  endLatitude: number;
  startAddress: string;
  endAddress: string;
  distance: number;
  messages: Array<MessageDTO & { timestamp: number }>;
}

export const getMonitoringTrips = createAsyncThunk(
  'monitoring/getMonitoringTrips',
  async (filters: MonitoringRequestFilter) => {
    const source = filters.source || 'monitoring';
    let body = new QueryBuilder().addLimit(filters.limit || 100);

    if (source === 'alerts') {
      body = body
        .and([
          {
            matchingMethod: MatchingMethod.EXACT,
            field: 'transport:id',
            value: filters.transportId,
          },
          {
            matchingMethod: MatchingMethod.LESS_THAN,
            field: 'startTime',
            value: filters.startDate,
          },
        ])
        .or([
          {
            matchingMethod: MatchingMethod.GREATER_THAN,
            field: 'endTime',
            value: filters.startDate,
          },
          {
            matchingMethod: MatchingMethod.NULL,
            field: 'endTime',
            value: NaN,
          },
        ]);
    }

    if (source === 'monitoring') {
      body = body
        .and([
          {
            matchingMethod: MatchingMethod.EXACT,
            field: 'transport:id',
            value: filters.transportId,
          },
          {
            matchingMethod: MatchingMethod.GREATER_THAN,
            field: 'startTime',
            value: filters.startDate,
          },
        ])
        .or([
          {
            matchingMethod: MatchingMethod.LESS_THAN,
            field: 'endTime',
            value: filters.endDate,
          },
          {
            field: 'endTime',
            value: null,
          },
        ]);
    }

    const { data } = await ApiService.post<ListResponseDTO<MonitoringTripDTO>>(
      `/trips/search?includeMessages=true`,
      body,
    );

    return (
      data.items
        .map((trip) => {
          // Keep only messages with speed and add 'shouldContinuePainting'
          const messages = trip.messages
            .filter(({ speed }) => speed)
            .map((msg) => ({
              ...msg,
              timestamp: new Date(msg.createdAt).getTime(),
            }));

          // Pre-parse the start time to avoid repeated `Date` parsing later
          return {
            ...trip,
            messages,
            timestamp: new Date(trip.startTime).getTime(),
          };
        })
        // Filter out trips with zero valid messages
        .filter(({ messages }) => messages.length > 0)
        // Sort newest to oldest by parsed start time
        .sort((a, b) => a.timestamp - b.timestamp)
    );
  },
);

export const getMonitoringGeneralInfo = createAsyncThunk<
  null | MonitoringGeneralInfo,
  MonitoringRequestFilter,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'monitoring/getMonitoringGeneralInfo',
  async (filter: MonitoringRequestFilter) => {
    const response = await ApiService.get<MonitoringGeneralInfo>(
      `/monitoring/transport/general/${filter.transportId}?startTime=${filter.startDate}&endTime=${filter.endDate}`,
      {},
      { cache: true },
    );
    if (response.ok) {
      return response.data;
    }
    return null;
  },
);

class MonitoringService {
  static ENDPOINT = '/monitoring';

  static getMonitoringVehicleById(id: number) {
    return ApiService.get<MonitoringVehicleDetailedDTO>(
      `${MonitoringService.ENDPOINT}/transports/${id}`,
    );
  }
}

export default MonitoringService;
