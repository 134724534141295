import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  SxProps,
} from '@mui/material';
import {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import { useController } from 'react-hook-form';
import { style } from './style';
import { EMPTY_OBJECT, noop } from '../../utils';

type Props = {
  name: string;
  label?: ReactNode;
  children: ReactElement;
  refName?: string;
  fieldSX?: SxProps;
  onChange?: (...args: any[]) => boolean | void;
  transformer?: (...args: never[]) => never[];
  attachError?: boolean;
  formControlProps?: FormControlProps;
};

function Field({
  name,
  label,
  fieldSX,
  children,
  refName = 'ref',
  onChange = noop,
  transformer = (...args: never[]) => args,
  attachError = true,
  formControlProps = EMPTY_OBJECT,
}: Props) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
  });

  const id = useMemo(() => crypto.randomUUID(), []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fieldOnChange = (...args: any[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const transformedArgs = transformer(...args);
    const shouldNotChange = onChange(...transformedArgs);
    if (!shouldNotChange) {
      field.onChange(...transformedArgs);
    }
  };

  const childrenWithProps = cloneElement(children, {
    onChange: fieldOnChange,
    onBlur: field.onBlur,
    value: field.value,
    name: field.name,
    [refName]: field.ref,
    id,
    error: attachError ? Boolean(error) : undefined,
  });

  const sxForField = Array.isArray(fieldSX)
    ? [...fieldSX, style.field]
    : [style.field, fieldSX];

  const isLabelReactNode = isValidElement(label);

  return (
    <FormControl sx={sxForField} {...formControlProps}>
      {label &&
        (isLabelReactNode ? (
          label
        ) : (
          <Box sx={style.label} component='label' htmlFor={id}>
            {label}
          </Box>
        ))}
      {childrenWithProps}
      {error?.message && (
        <FormHelperText role='alert' error>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
}

Field.displayName = 'Field';

export default Field;
