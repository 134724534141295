import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { style } from './style';
import MonitoringDateRangePicker from '../MonitoringDateRangePicker';
import SFPopover from '../../../global/components/SFPopover';
import useMonitoringDateFilter from '../../hooks/useMonitoringDateFilter';

function MonitoringDateRangeControl() {
  const { startDate, endDate } = useMonitoringDateFilter();

  return (
    <SFPopover
      triggerProps={{ sx: style.trigger }}
      renderTrigger={
        <Box display='flex' alignItems='center' whiteSpace='nowrap'>
          <Typography sx={style.dateRangeTag}>Custom</Typography>
          &nbsp;&nbsp;
          <Typography sx={style.dateTime}>
            {format(startDate, 'MMM dd, yyyy')}
            {' - '}
            {format(endDate, 'MMM dd, yyyy')}
          </Typography>
        </Box>
      }
      renderContent={(handleClose) => (
        <MonitoringDateRangePicker onClose={handleClose} />
      )}
    />
  );
}

MonitoringDateRangeControl.displayName = 'MonitoringDateRangeControl';

export default MonitoringDateRangeControl;
