import { SnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';
import { NotificationTracker } from '../index';

function SnackbarContainer({ children }: PropsWithChildren) {
  return (
    <SnackbarProvider maxSnack={3}>
      <NotificationTracker />
      {children}
    </SnackbarProvider>
  );
}

export default SnackbarContainer;
