import { DriverDTO } from '../../services/driver.service';
import { LocationLightDTO } from '../../services/location.service';

export type GroupListFilterDTO = {
  search?: string;
  limit?: number;
  offset?: number;
  group?: number | string | string[];
};
export enum GroupSearchOption {
  NAME = 'name',
}

export type GroupFormDTO = {
  id: number;
  name: string;
  courier: null | DriverDTO;
  courierSchedule: WorkingSchedule[];
  manager: null | DriverDTO;
  managerSchedule: WorkingSchedule[];
  label?: string;
  description?: string;
  locations: LocationLightDTO[];
};

export enum WorkingSchedule {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}

export enum EntityAssociationType {
  ASSIGN = 'ASSIGN',
  UNASSIGN = 'UNASSIGN',
}

export type GroupAssignmentDTO = {
  groupId: string | number;
  locationId: string;
  association: EntityAssociationType;
};
