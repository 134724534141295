import { useEffect, useState } from 'react';

export const useIsInBrowser = () => {
  const [isInBrowser, setIsInBrowser] = useState(false);

  useEffect(() => {
    const checkIfBrowser = () => {
      const isStandalone =
        window.matchMedia('(display-mode: standalone)').matches ||
        document.referrer.includes('android-app://');
      setIsInBrowser(!isStandalone);
    };

    checkIfBrowser();

    const mediaQuery = window.matchMedia('(display-mode: standalone)');
    mediaQuery.addEventListener('change', checkIfBrowser);

    return () => {
      mediaQuery.removeEventListener('change', checkIfBrowser);
    };
  }, []);

  return isInBrowser;
};

export default useIsInBrowser;
