import { ReactNode } from 'react';
import { Popover } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useAnchor from '../../hooks/use-anchor';
import { style } from './style';
import SFControlButton, { SFControlButtonProps } from '../SFControlButton';

type SFPopoverProps = {
  renderTrigger: ReactNode;
  renderContent: (handleClose: () => void) => ReactNode;
  triggerProps?: Omit<SFControlButtonProps, 'onClick' | 'endIcon'>;
};

function SFPopover(props: SFPopoverProps) {
  const { renderContent, renderTrigger, triggerProps } = props;

  const { open, anchorEl, handleClick: handleOpen, handleClose } = useAnchor();

  return (
    <>
      <SFControlButton
        onMouseDown={handleOpen}
        endIcon={<ExpandMore color='action' sx={style.endIcon(open)} />}
        {...triggerProps}
      >
        {renderTrigger}
      </SFControlButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        slotProps={{ paper: { sx: style.paper } }}
      >
        {renderContent(handleClose)}
      </Popover>
    </>
  );
}

export default SFPopover;
