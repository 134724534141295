import ApiService from '../global/services/api.service';
import { MatchingMethod, TableFilterDTO } from '../global';
import {
  DeviceFormDTO,
  DeviceModel,
  DeviceSearchOption,
} from '../devices/types';
import QueryBuilder from '../shared/utils/query-builder';
import { VehicleDTO } from '../shared/types';

export interface DeviceLightDTO {
  name: string;
  imei: string;
  model: DeviceModel;
  phone: string;
  enabled: boolean;
  id: number;
  transport: null | {
    name: string;
  };
}

export interface DeviceDTO extends DeviceLightDTO {
  transport: VehicleDTO | null;
}

class DeviceService {
  static ENDPOINT = '/devices';

  static getDevices(filter: TableFilterDTO) {
    const body = new QueryBuilder()
      .addOffset(filter.offset)
      .addLimit(filter.limit)
      .or({
        field: DeviceSearchOption.NAME,
        matchingMethod: MatchingMethod.CONTAINS,
        value: filter.search,
      })
      .or({
        field: DeviceSearchOption.MODEL,
        matchingMethod: MatchingMethod.CONTAINS,
        value: filter.search,
      })
      .or({
        field: DeviceSearchOption.IMEI,
        matchingMethod: MatchingMethod.CONTAINS,
        value: filter.search,
      })
      .or({
        field: DeviceSearchOption.PHONE,
        matchingMethod: MatchingMethod.CONTAINS,
        value: filter.search,
      });

    return ApiService.post<{ items: DeviceLightDTO[]; totalItems: number }>(
      `${DeviceService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static getDeviceById(id: number) {
    return ApiService.get<DeviceDTO>(`${DeviceService.ENDPOINT}/${id}`);
  }

  static updateDevice(device: DeviceFormDTO, id: number) {
    const body = DeviceService.getDeviceRequestPayload(device);
    return ApiService.patch(`${DeviceService.ENDPOINT}/${id}`, body);
  }

  static deleteDevice(id: number) {
    return ApiService.delete(`${DeviceService.ENDPOINT}/${id}`);
  }

  static createDevice(values: DeviceFormDTO) {
    const body = DeviceService.getDeviceRequestPayload(values);
    return ApiService.post(DeviceService.ENDPOINT, body);
  }

  static getDeviceRequestPayload(values: DeviceFormDTO) {
    return {
      name: values.name,
      imei: values.imei,
      model: values.model,
      transportId: values.transports?.id || null,
      phone: values.phone,
    };
  }
}
export default DeviceService;
