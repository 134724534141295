import { MONITORING_DRAWER_WIDTH } from '../../utils/constants';

export const style = {
  paper: {
    width: { xs: '100%', sm: MONITORING_DRAWER_WIDTH },
    flexDirection: 'column',
    padding: 4,
    boxShadow: '8px 0px 8px 0px rgba(56, 67, 88, 0.25)',
    position: 'absolute',
    height: { xs: '100%', sm: 'calc(100vh - 60px)' },
    overflowY: 'hidden',
    zIndex: 9,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  startIcon: {
    transform: 'rotate(180deg)',
  },
  hideButton: { fontSize: 12 },
};

export default style;
