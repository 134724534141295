/**
 * Superfleet.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 2/24/2024
 * All Rights Reserved.
 */
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { SFControlledInputProps } from '../SFTextField';
import { style } from './style';
import { noop } from '../../utils';

enum Schedule {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}

type Props = SFControlledInputProps & {
  onBlur?: () => void;
  onChange?: (value: string[]) => void;
  value?: string[];
  size?: 'S' | 'L';
};

const SFSchedule = forwardRef(
  ({ onBlur = noop, onChange = noop, value = [], size = 'L' }: Props, ref) => {
    const { t } = useTranslation('translation', {
      keyPrefix: 'global.workingSchedule',
    });

    function handleDayClick(day: Schedule) {
      if (value.includes(day)) {
        onChange(value.filter((d) => d !== day));
      } else {
        onChange([...value, day]);
      }
    }

    return (
      <Box onBlur={onBlur} ref={ref} sx={style.schedule}>
        <Box
          sx={[
            style.day(size),
            value.includes(Schedule.MONDAY) && style.selected,
          ]}
          onClick={() => handleDayClick(Schedule.MONDAY)}
        >
          <Typography variant='body1' sx={style.dayLabel}>
            {t('m')}
          </Typography>
        </Box>
        <Box
          sx={[
            style.day(size),
            value.includes(Schedule.TUESDAY) && style.selected,
          ]}
          onClick={() => handleDayClick(Schedule.TUESDAY)}
        >
          <Typography variant='body1' sx={style.dayLabel}>
            {t('t')}
          </Typography>
        </Box>
        <Box
          sx={[
            style.day(size),
            value.includes(Schedule.WEDNESDAY) && style.selected,
          ]}
          onClick={() => handleDayClick(Schedule.WEDNESDAY)}
        >
          <Typography variant='body1' sx={style.dayLabel}>
            {t('w')}
          </Typography>
        </Box>
        <Box
          sx={[
            style.day(size),
            value.includes(Schedule.THURSDAY) && style.selected,
          ]}
          onClick={() => handleDayClick(Schedule.THURSDAY)}
        >
          <Typography variant='body1' sx={style.dayLabel}>
            {t('t')}
          </Typography>
        </Box>
        <Box
          sx={[
            style.day(size),
            value.includes(Schedule.FRIDAY) && style.selected,
          ]}
          onClick={() => handleDayClick(Schedule.FRIDAY)}
        >
          {' '}
          <Typography variant='body1' sx={style.dayLabel}>
            {t('f')}
          </Typography>
        </Box>
        <Box
          sx={[
            style.day(size),
            value.includes(Schedule.SATURDAY) && style.selected,
          ]}
          onClick={() => handleDayClick(Schedule.SATURDAY)}
        >
          <Typography variant='body1' sx={style.dayLabel}>
            {t('s')}
          </Typography>
        </Box>
        <Box
          sx={[
            style.day(size),
            value.includes(Schedule.SUNDAY) && style.selected,
          ]}
          onClick={() => handleDayClick(Schedule.SUNDAY)}
        >
          <Typography variant='body1' sx={style.dayLabel}>
            {t('s')}
          </Typography>
        </Box>
      </Box>
    );
  },
);

SFSchedule.displayName = 'SFSchedule';

export default SFSchedule;
