/**
 * Superfleet.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 2/25/2024
 * All Rights Reserved.
 */
import { Box } from '@mui/material';
import { forwardRef } from 'react';
import { style } from './style';
import { SFControlledInputProps } from '../SFTextField';
import { noop } from '../../utils';

export enum ColorLabel {
  // Backend as color send red color , I have change DARK_PURPLE to RED temporary until it  will be our config.
  RED = 'RED',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  LIGHT_BLUE = 'LIGHT_BLUE',
  YELLOW = 'YELLOW',
  PURPLE = 'PURPLE',
}

const COLORS = [
  {
    id: 0,
    label: ColorLabel.BLUE,
  },
  {
    id: 1,
    label: ColorLabel.LIGHT_BLUE,
  },
  {
    id: 2,
    label: ColorLabel.GREEN,
  },
  {
    id: 3,
    label: ColorLabel.YELLOW,
  },
  {
    id: 4,
    label: ColorLabel.RED,
  },
  {
    id: 5,
    label: ColorLabel.PURPLE,
  },
];

export const SFPaletteColorMapping: Record<ColorLabel, string> = {
  [ColorLabel.BLUE]: '#2860B4',
  [ColorLabel.LIGHT_BLUE]: '#4CA7C8',
  [ColorLabel.GREEN]: '#72B777',
  [ColorLabel.YELLOW]: '#EEC65E',
  [ColorLabel.PURPLE]: '#7F5DD9',
  [ColorLabel.RED]: '#813295',
};

type Props = SFControlledInputProps & {
  onBlur?: () => void;
  onChange?: (value: string) => void;
  value?: string;
};

const SFColorPalette = forwardRef(
  ({ onBlur = noop, onChange = noop, value = '' }: Props, ref) => {
    function handleColorChange(label: ColorLabel) {
      onChange(label);
    }

    return (
      <Box sx={style.colors} ref={ref} onBlur={onBlur}>
        {COLORS.map((color) => (
          <Box
            key={color.id}
            onClick={() => handleColorChange(color.label)}
            sx={[
              style.coloredCircle(SFPaletteColorMapping[color.label]),
              value === color.label && style.selectedCircle,
            ]}
          />
        ))}
      </Box>
    );
  },
);

SFColorPalette.displayName = 'SFColorPalette';

export default SFColorPalette;
