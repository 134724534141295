import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import AuthLayout from '../AuthLayout';
import GetTheApp from '../GetTheApp';
import LoginForm from '../LoginForm';
import { getAuthSliceSelector } from '../../selectors/user-selector';
import { ROUTES } from '../../../global';

function Login() {
  const { user, isLoading } = useSelector(getAuthSliceSelector);

  if (isLoading) {
    return null;
  }

  if (user) {
    return <Navigate to={ROUTES.monitoring.path} />;
  }

  return (
    <AuthLayout>
      <LoginForm />
      <GetTheApp />
    </AuthLayout>
  );
}

export default Login;
