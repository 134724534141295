export const style = {
  input: {
    height: '100%',
    borderRadius: 1,
    '&.MuiTextField-root': {
      '& > div': {
        backgroundColor: '#fff',
      },
      '& fieldset': {
        border: '1px solid #dfe7f0',
        transition: 'border 0.2s',
      },
      '&:hover fieldset': {
        borderColor: '#3A77EB',
      },
      '& .Mui-disabled': {
        backgroundColor: '#e9ecef',
      },
      '& .MuiInputBase-input': {
        borderRadius: 1,
      },
      '& .Mui-disabled+fieldset': {
        borderColor: '#dfe7f0',
      },
    },
  },
  inputError: {
    '& > .Mui-focused': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: 'red',
      },
    },
    '&.MuiTextField-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
    },
  },
  helperText: {
    color: 'red',
  },
};
