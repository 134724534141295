import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import UserAgentInfo from '../UserAgentInfo';
import styles from './styles';
import { LANGUAGES, LANGUAGE_MAPPER } from '../../utils';
import { Language } from '../../types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getUserSelector } from '../../../shared/selectors';
import useAnchor from '../../../global/hooks/use-anchor';
import AvatarImage from '../../../shared/components/AvatarImage';
import {
  LOCALSTORAGE_ACCESS_TOKEN,
  LOCALSTORAGE_REFRESH_TOKEN,
} from '../../../auth/utils';
import { resetUser } from '../../../auth/slices/auth-slice';
import { ROUTES } from '../../../global';

function AppBarInfo() {
  const { i18n, t } = useTranslation();
  const theme = useTheme();

  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { open, anchorEl, handleClick, handleClose } = useAnchor();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onChange = (e: SelectChangeEvent<string>) => {
    i18n.changeLanguage(e.target.value);
  };

  const user = useAppSelector(getUserSelector);

  const fullName = user ? `${user.firstName} ${user.lastName}` : '';

  const logout = () => {
    handleClose();
    localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, '');
    localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, '');
    navigate(ROUTES.auth.login.path);
    dispatch(resetUser());
  };

  return (
    <>
      <UserAgentInfo />
      <Box sx={styles.appBarRight}>
        <Select
          value={LANGUAGE_MAPPER[i18n.language as Language]}
          renderValue={(value) => (
            <Box
              component='img'
              src={LANGUAGES.find((lang) => lang.value === value)?.src}
              alt='Language flag'
            />
          )}
          size='small'
          onChange={onChange}
          MenuProps={{ MenuListProps: { sx: styles.selectMenu } }}
          IconComponent={() => null}
          sx={styles.languageSelect}
          variant='standard'
        >
          {LANGUAGES.map((lang) => (
            <MenuItem value={lang.value} key={lang.value} sx={styles.menuItem}>
              <Box component='img' src={lang.src} alt={lang.label} />
              {lang.label}
            </MenuItem>
          ))}
        </Select>
        <Typography
          color='gray'
          sx={styles.divider}
          display={{ xs: 'none', sm: 'block' }}
        >
          |
        </Typography>
        <Box sx={styles.userName}>
          <Typography>{fullName}</Typography>
          <AvatarImage
            altName='Profile Image'
            imageId={user?.profileImageId || null}
            onClick={handleClick}
          />
        </Box>
        {!isSM && (
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
          >
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize='small' />
              </ListItemIcon>
              {t('auth.logout')}
            </MenuItem>
          </Menu>
        )}
        {isSM && (
          <Button
            startIcon={<Logout fontSize='small' />}
            color='error'
            variant='contained'
            onClick={logout}
            sx={styles.logout}
          >
            {t('auth.logout')}
          </Button>
        )}
      </Box>
    </>
  );
}

export default AppBarInfo;
