import { Box, BoxProps } from '@mui/material';
import { style } from './style';

type PullerProps = Omit<BoxProps, 'sx'>;

function Puller(props: PullerProps) {
  return (
    <Box sx={style.wrapper} {...props}>
      <Box sx={style.puller} />
    </Box>
  );
}

export default Puller;
