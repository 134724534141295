import { Control } from 'ol/control';
import { MapControlType } from '../types';

class FullScreenControl extends Control {
  constructor() {
    const div = document.createElement('div');
    div.id = MapControlType.FULL_SCREEN_CONTROL;

    super({
      element: div,
      target: undefined,
    });

    div.addEventListener('click', this.toggleMapFullScreen.bind(this), false);
  }

  toggleMapFullScreen() {
    this.dispatchEvent('enterfullscreen');
    const map = this.getMap();
    if (map) {
      if (!document.fullscreenElement) {
        const target = map.getTargetElement();
        target.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    }
  }
}

class CustomControl extends Control {
  constructor(id: string) {
    const div = document.createElement('div');
    div.id = id;

    super({
      element: div,
      target: undefined,
    });
  }
}

export { FullScreenControl, CustomControl };
