import { MenuItem, Select, SelectProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

type SFControlledSelectProps = SelectProps &
  Partial<{
    isDirty: boolean;
    isTouched: boolean;
    invalid: boolean;
    errorMessage: string;
  }> & {
    canReset?: boolean;
  };
/**
 * @deprecated This component shouldn't be used
 */
function SFSelect({
  children,
  errorMessage,
  invalid,
  isTouched,
  isDirty,
  canReset,
  ...props
}: PropsWithChildren<SFControlledSelectProps>) {
  const { t } = useTranslation('translation', { keyPrefix: 'shared' });

  return (
    <Select {...props}>
      {canReset ? (
        <MenuItem>
          <em>{t('none')}</em>
        </MenuItem>
      ) : null}
      {children}
    </Select>
  );
}

export default SFSelect;
