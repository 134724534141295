import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Button,
  Box,
} from '@mui/material';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import { ErrorOption, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { ROUTES, Field } from '../../../global';
import styles from './styles';
import { LoginFormState } from '../../types';
import useAuthService from '../../hooks/use-auth-service';
import { getLoginValidationSchema } from '../../utils/validation';
import { ResponseStatus } from '../../../global/types';
import IdentifyUser from '../../../layout/components/IdentifyUser';

function LoginForm() {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const [loggedIn, setLoggedIn] = useState(false);
  const formMethods = useForm<LoginFormState>({
    resolver: yupResolver(getLoginValidationSchema()),
    defaultValues: { username: '', password: '', rememberMe: false },
  });

  const { handleSubmit, formState, setError } = formMethods;

  const { login } = useAuthService();

  const navigate = useNavigate();

  const onSubmit = async (values: LoginFormState) => {
    try {
      const response = await login(values);

      if (response.status === ResponseStatus.APPROVED) {
        setLoggedIn(true);
      } else if (response.errorMessage) {
        setError('form', response.errorMessage as ErrorOption);
      }
    } catch {}
  };

  const { form } = formState.errors;

  function navigateToRegister() {
    navigate(ROUTES.auth.forgotPassword.path);
  }
  return (
    <>
      <Typography variant='subtitle1' sx={styles.welcomeBack}>
        {t('welcome')}
      </Typography>
      <>
        <Typography sx={styles.loginTo} variant='h1' whiteSpace='nowrap'>
          {t('loginTo')}
        </Typography>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={styles.form} spacing={3}>
              <Grid item>
                <Box sx={styles.gridItem}>
                  <Typography
                    color='#4A5568'
                    variant='subtitle1'
                    fontWeight={400}
                    component='label'
                  >
                    {t('email')}
                  </Typography>
                  <Field name='username'>
                    <TextField
                      size='medium'
                      fullWidth
                      inputProps={{ 'aria-label': 'Email' }}
                    />
                  </Field>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={styles.gridItem}>
                  <Typography
                    color='#4A5568'
                    variant='subtitle1'
                    fontWeight={400}
                    component='label'
                  >
                    {t('password')}
                  </Typography>
                  <Field name='password'>
                    <TextField
                      size='medium'
                      type='password'
                      fullWidth
                      inputProps={{ 'aria-label': 'Password' }}
                    />
                  </Field>
                </Box>
              </Grid>
              <Box sx={styles.footer}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Field name='rememberMe' attachError={false}>
                        <Checkbox
                          sx={styles.checkbox}
                          size='small'
                          icon={
                            <CircleOutlinedIcon
                              sx={styles.icon}
                              color='disabled'
                            />
                          }
                          checkedIcon={
                            <RadioButtonCheckedOutlinedIcon
                              color='primary'
                              sx={styles.icon}
                            />
                          }
                        />
                      </Field>
                    }
                    label={t('remember')}
                    sx={styles.rememberMe}
                  />
                </Grid>
                <Button sx={styles.forgot} onClick={navigateToRegister}>
                  {t('forgot')}
                </Button>
              </Box>
              {form && <Typography>{form?.message}</Typography>}
              <Grid sx={styles.loginButton} item>
                <Button
                  fullWidth
                  variant='contained'
                  size='large'
                  disabled={formState.isSubmitting}
                  type='submit'
                >
                  {t('login')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </>
      {loggedIn && <IdentifyUser />}
    </>
  );
}

export default LoginForm;
