import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { style } from './style';
import { getMonitoringTeams } from '../../services/monitoring.service';
import { useAppDispatch } from '../../../layout/store';
import { SFMap } from '../../../map/services';
import {
  getMonitoringControls,
  getMonitoringSidebarCollapseState,
} from '../../utils';
import { MONITORING_SIDEBAR_COLLAPSE_STATE } from '../../utils/constants';
import MonitoringTeamsDrawer from '../MonitoringTeamsDrawer';
import MonitoringDeviceHistoryDrawer from '../MonitoringDeviceHistoryDrawer';
import MonitoringControls from '../MonitoringControls';
import { ROUTES } from '../../../global';
import useMonitoringTripsPreload from '../../hooks/useMonitoringTripsPreload';
import {
  monitoringOpenHistoryMode,
  monitoringTriggerLiveMode,
} from '../../slices/monitoring-slice';

function Monitoring() {
  const dispatch = useAppDispatch();
  const [sidebarHidden, setSidebarHidden] = useState(
    getMonitoringSidebarCollapseState(),
  );

  useMonitoringTripsPreload();

  const [searchParams] = useSearchParams();

  const selectedTransportID = searchParams.get(
    ROUTES.monitoring.searchParams.transportID,
  );

  const hideSidebar = () => {
    localStorage.setItem(MONITORING_SIDEBAR_COLLAPSE_STATE, String(true));
    setSidebarHidden(true);
  };

  const showSidebar = () => {
    localStorage.setItem(MONITORING_SIDEBAR_COLLAPSE_STATE, String(false));
    setSidebarHidden(false);
  };

  useEffect(() => {
    dispatch(getMonitoringTeams());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTransportID) {
      dispatch(monitoringOpenHistoryMode());
    } else {
      dispatch(monitoringTriggerLiveMode());
    }
  }, [dispatch, selectedTransportID]);

  const hasSelectedDevice = !!selectedTransportID;

  useEffect(() => {
    const { cleanup } = SFMap.attachControls(
      getMonitoringControls(sidebarHidden, hasSelectedDevice),
    );

    return cleanup;
  }, [sidebarHidden, hasSelectedDevice]);

  return (
    <Box sx={style.root(sidebarHidden)}>
      <MonitoringControls
        showSidebar={showSidebar}
        sidebarHidden={sidebarHidden}
      />

      <MonitoringTeamsDrawer
        open={!sidebarHidden}
        hideSidebar={hideSidebar}
        showSidebar={showSidebar}
      />

      <MonitoringDeviceHistoryDrawer
        hasSelectedDevice={hasSelectedDevice}
        sidebarHidden={sidebarHidden}
      />
    </Box>
  );
}

Monitoring.displayName = 'Monitoring';

export default Monitoring;
