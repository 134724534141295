import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { PopOverItem } from '../../types';
import SidebarContentPopover from '../SidebarContentPopover';
import { GeoService } from '../../../services';
import { StrictGeoResponse } from '../../../layout/types';
import { getLastDataReceivedOption } from '../../utils';

type Props = {
  longitude?: number;
  latitude?: number;
  lastData?: number | null;
};
export default function LocationIcon({ latitude, longitude, lastData }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });

  const [currentLocation, setCurrentLocation] = useState<{
    data: StrictGeoResponse | undefined;
    isLoading: boolean;
  }>({ data: undefined, isLoading: true });

  const getCurrentLocation = useDebouncedCallback(async () => {
    setCurrentLocation({ data: undefined, isLoading: true });
    if (!latitude || !longitude) {
      return;
    }
    const { data, ok } = await GeoService.reverseSearch([longitude, latitude]);
    if (ok) {
      setCurrentLocation({ data, isLoading: true });
    }
  }, 50);

  useEffect(() => {
    if (latitude && longitude) {
      getCurrentLocation();
    }
  }, [getCurrentLocation, latitude, longitude]);
  const popOverItem: PopOverItem[] = [
    {
      label: t('currentLocation'),
      value: currentLocation.data?.address || 'No Data',
    },
    getLastDataReceivedOption(lastData),
  ];

  return <SidebarContentPopover items={popOverItem} />;
}
