import { Theme } from '@mui/material';

export const style = {
  cardHeader: {
    display: 'flex',
    gap: 4,
    alignItems: 'end',
  },
  deviceStatus: (status: number | undefined) => ({
    width: 20,
    height: 20,
    borderRadius: '50%',
    alignItems: 'center',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    left: 46,
    top: 34,
    backgroundColor: status ? 'success.main' : 'error.main',
  }),
  copy: { display: 'flex', cursor: 'pointer' },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    flex: 1,
  },
  dot: (theme: Theme) => ({
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.text.secondary,
  }),
  transportName: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  smallIcon: {
    width: 18,
    height: 18,
  },
  listItemSecondaryText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 150,
    marginLeft: 'auto',
    color: '#000000',
    fontWeight: 500,
  },
  listItem: (theme: Theme) => ({
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    height: 24,
    padding: theme.spacing(1.5, 2),
    '&:nth-of-type(odd)': {
      background: 'rgb(231, 231, 231)',
    },
    '&:nth-of-type(even)': {
      background: '#fff',
    },
  }),
  listItemPrimaryText: (theme: Theme) => ({
    color: theme.palette.text.secondary,
  }),
  addressInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: 1,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    marginLeft: 'auto',
  },
  statusDot: (isActive: boolean) => ({
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: isActive ? 'success.main' : 'action.disabled',
  }),
  showMore: {
    width: 'fit-content',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
