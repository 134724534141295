import { Content } from '../../../layout/components';
import { useAppSelector } from '../../../layout/store';
import { getUserSelector } from '../../selectors/user-selector';
import Reauthenticate from '../Reauthenticate';

function AuthContainer() {
  const user = useAppSelector(getUserSelector);

  return (
    <>
      {user ? <Content /> : null}
      <Reauthenticate />
    </>
  );
}

export default AuthContainer;
