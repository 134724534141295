import { Autocomplete, AutocompleteProps, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import SFTextField from '../SFTextField';

export type SFAutocompleteProps = Partial<
  AutocompleteProps<any, any, any, any>
> & {
  inputProps?: TextFieldProps;
  inputRef?: (instance: any) => void;
  isDirty?: boolean;
  isTouched?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  onChange: (...event: any[]) => void;
  options: readonly any[];
};
/**
 * @deprecated This component shouldn't be used
 */
const SFAutocomplete: FC<SFAutocompleteProps> = function ({
  inputProps = {},
  inputRef,
  errorMessage,
  isTouched,
  isDirty,
  invalid,
  ...props
}) {
  return (
    <Autocomplete
      forcePopupIcon
      {...props}
      onChange={(ev, v) => props.onChange(v)}
      renderInput={(
        renderInputProps: Omit<
          AutocompleteRenderInputParams,
          'InputLabelProps'
        >,
      ) => (
        <SFTextField
          {...renderInputProps}
          {...inputProps}
          InputProps={{
            ...(renderInputProps.InputProps || {}),
            ...(inputProps.InputProps || {}),
          }}
          inputRef={inputRef}
          errorMessage={errorMessage}
          isTouched={isTouched}
          isDirty={isDirty}
          invalid={invalid}
        />
      )}
    />
  );
};

export default SFAutocomplete;
