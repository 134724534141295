import {
  IMiddleware,
  MiddlewareContext,
  NextFunction,
} from '../middleware-manager';

export class ContentTypeMiddleware implements IMiddleware {
  async onRequest(
    context: MiddlewareContext,
    next: NextFunction,
  ): Promise<void> {
    if (!(context.request.body instanceof FormData)) {
      context.request.headers.set('Content-Type', 'application/json');
    }
    return next();
  }
}
