import { LoginFormState } from '../types';
import { ResponseStatus } from '../../global/types';
import {
  LOCALSTORAGE_ACCESS_TOKEN,
  LOCALSTORAGE_REFRESH_TOKEN,
} from '../utils';
import ApiService from '../../global/services/api.service';

const useAuthService = () => {
  const register = async (values: LoginFormState) => {
    // const { data } = await API.post('account/register', values);
    // return data;
  };

  const login = async (values: LoginFormState) => {
    const { data, ok } = await ApiService.post<{
      accessToken: string;
      refreshToken: string;
      error?: { message: string };
    }>('/auth/login', {
      username: values.username,
      password: values.password,
    });
    if (!ok) {
      return {
        errorMessage: 'generic message',
        status: ResponseStatus.REJECTED,
      };
    }

    localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, data.accessToken);
    localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, data.refreshToken);

    return { data, status: ResponseStatus.APPROVED };
  };

  const getRefreshToken = async () => {
    const refreshToken = localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN);
    if (!refreshToken) {
      return null;
    }

    try {
      const { data } = await ApiService.post<{
        accessToken: string;
        refreshToken: string;
      }>('/auth/refresh-token', {
        refreshToken,
      });

      localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, data.accessToken);
      localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, data.refreshToken);

      return data;
    } catch (e) {
      return null;
    }
  };

  return {
    login,
    getRefreshToken,
    register,
  };
};

export default useAuthService;
