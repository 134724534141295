import { AppBar, Box, Grid, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import superfleetIcon from '../../../../assets/team-fleet.jpg';
import AppBarInfo from '../AppBarInfo/AppBarInfo';
import { style } from './style';

type Props = {
  onMenuClick: () => void;
};

function Header({ onMenuClick }: Props) {
  return (
    <AppBar sx={style.appBar}>
      <Grid
        pl={2}
        pr={4}
        alignItems='center'
        height='100%'
        container
        justifyContent='space-between'
      >
        <Box sx={style.leftMenu}>
          <IconButton
            sx={style.menuBar}
            onMouseDown={onMenuClick}
            disableFocusRipple
          >
            <Menu sx={style.menuIcon} />
          </IconButton>
          <Box
            component='img'
            src={superfleetIcon}
            alt='Superfleet icon'
            sx={style.logo}
            height={40}
          />
        </Box>
        <Box display={{ xs: 'none', sm: 'contents' }}>
          <AppBarInfo />
        </Box>
      </Grid>
    </AppBar>
  );
}

Header.displayName = 'Header';

export default Header;
