import {
  ColorLabel,
  SFPaletteColorMapping,
} from '../../global/components/SFColorPalette';
import { LocationIconPalette, IconVariant } from '../types';

function buildIconPalette(
  fill: string | undefined,
  variant: IconVariant,
): LocationIconPalette {
  const colorScheme: LocationIconPalette = {
    markerFill: '#323232',
    markerBackground: 'white',
    position: 'static',
    backgroundColor: 'white',
  };
  if (variant == IconVariant.ABSTRACT) {
    colorScheme.markerFill = SFPaletteColorMapping[fill as ColorLabel];
    colorScheme.markerBackground = 'white';
  }
  if (variant == IconVariant.MAP_ICON) {
    colorScheme.markerFill = SFPaletteColorMapping[fill as ColorLabel];
  }

  if (variant == IconVariant.TECHNICAL) {
    colorScheme.position = 'absolute';
    colorScheme.backgroundColor = SFPaletteColorMapping[fill as ColorLabel];
    colorScheme.markerFill = 'white';
    colorScheme.markerBackground = SFPaletteColorMapping[fill as ColorLabel];
  }
  if (!fill) {
    colorScheme.markerFill = '#323232';
    colorScheme.markerBackground = 'white';
    colorScheme.backgroundColor = 'white';
  }
  return colorScheme;
}
export { buildIconPalette };
