import { ThemeProvider } from '@mui/material';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import theme from './theme';
import { AuthContainer, Login } from '../../../auth/components';
import store from '../../store';
import { SnackbarContainer } from '../../../global/components';
import '../../../global/utils/localization';
import ResetPassword from '../../../auth/components/ResetPassword';
import ForgotPassword from '../../../auth/components/ForgotPassword';
import { ROUTES } from '../../../global';
import IdentifyUser from '../IdentifyUser';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <SnackbarContainer>
          <ReduxProvider store={store}>
            <IdentifyUser />
            <Routes>
              <Route
                path='/'
                element={<Navigate to={ROUTES.monitoring.path} />}
              />
              <Route path={ROUTES.auth.login.path} element={<Login />} />
              <Route
                path={ROUTES.auth.resetPassword.path}
                element={<ResetPassword />}
              />
              <Route
                path={ROUTES.auth.forgotPassword.path}
                element={<ForgotPassword />}
              />
              <Route path='*' element={<AuthContainer />} />
            </Routes>
          </ReduxProvider>
        </SnackbarContainer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
