import {
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { style } from './style';
import { ROUTES } from '../../../global';
import { MonitoringMovementStatus, MonitoringTransportDTO } from '../../types';
import AvatarImage from '../../../shared/components/AvatarImage';
import StyledBadge from '../../../shared/components/StyledBadge';
import { toggleSearchParam } from '../../../shared/utils';
import TransportMetricsDisplay from './TransportMetricsDisplay';

type Props = MonitoringTransportDTO & {
  tracked: boolean;
  selectedTransportID: string | null;
  onClose?: () => void;
};

function MonitoringSingleTransport({
  courierId,
  courierName,
  transportName,
  licensePlate,
  vehicleSpeed,
  gsmSignal,
  tracked,
  transportId,
  selectedTransportID,
  deviceId,
  latitude,
  longitude,
  onClose,
  profileImageId,
  status,
  satellites,
  lastData,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const [, setSearchParams] = useSearchParams();

  const inMovement = status === MonitoringMovementStatus.MOVEMENT;

  const selectTransport = () => {
    onClose?.();
    setSearchParams((params) => {
      params = toggleSearchParam(
        params,
        ROUTES.monitoring.searchParams.transportID,
        transportId,
      );
      params.delete(ROUTES.monitoring.searchParams.tripID);
      return params;
    });
  };

  const transportInHistoryMode = selectedTransportID === String(transportId);

  return (
    <>
      <ListItem
        key={courierId}
        sx={[
          style.listItem,
          transportInHistoryMode && style.listItemSelected,
          !deviceId && style.disabled,
        ]}
        onClick={selectTransport}
      >
        <ListItemAvatar>
          {inMovement ? (
            <StyledBadge
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            >
              <AvatarImage
                imageId={profileImageId as null | string}
                altName='Transport Image'
              />
            </StyledBadge>
          ) : (
            <AvatarImage
              imageId={profileImageId as null | string}
              altName='Transport Image'
            />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box sx={style.listItemSecondaryText} component='span'>
              <Typography
                sx={{ display: 'inline' }}
                component='span'
                variant='body2'
                color='text.primary'
              >
                {transportName || 'N/A'}
              </Typography>
              {licensePlate ? <Box sx={style.dot} component='span' /> : null}
              {licensePlate || ''}
            </Box>
          }
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
          secondary={courierName || t('notAssigned')}
          sx={style.listItemText}
        />
        <TransportMetricsDisplay
          deviceId={deviceId}
          transportId={transportId}
          tracked={tracked}
          inMovement={inMovement}
          vehicleSpeed={vehicleSpeed}
          gsmSignal={gsmSignal}
          latitude={latitude}
          longitude={longitude}
          status={status}
          satellites={satellites}
          lastData={lastData}
        />
      </ListItem>
      <Divider component='li' />
    </>
  );
}

MonitoringSingleTransport.displayName = 'MonitoringSingleDriver';

export default MonitoringSingleTransport;
