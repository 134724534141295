import {
  GroupFormDTO,
  GroupListFilterDTO,
  GroupSearchOption,
} from '../groups/types';
import ApiService from '../global/services/api.service';
import QueryBuilder from '../shared/utils/query-builder';
import { ListDTO, MatchingMethod } from '../global';
import { WorkingSchedule } from '../shared/types';
import { DriverRole } from './driver.service';
import { LocationLightDTO } from './location.service';

export interface GroupLightDTO {
  description: string;
  id: number;
  label: string;
  locations: LocationLightDTO[];
  name: string;
  couriers: Array<{
    courierName: string;
    courierId: number;
    roles: DriverRole;
  }>;
}

export interface GroupDTO extends GroupLightDTO {
  courierSchedule: WorkingSchedule[];
  managerSchedule: WorkingSchedule[];
}

class GroupService {
  static ENDPOINT = '/groups';

  static getGroups({ search, limit, offset }: GroupListFilterDTO) {
    const body = new QueryBuilder()
      .addLimit(limit || 20)
      .addOffset(offset || 0)
      .or({
        field: GroupSearchOption.NAME,
        value: search,
        matchingMethod: MatchingMethod.CONTAINS,
      });
    return ApiService.post<ListDTO<GroupLightDTO>>(
      `${GroupService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static createGroup(group: GroupFormDTO) {
    const data = GroupService.getGroupRequestPayload(group);
    return ApiService.post(GroupService.ENDPOINT, data);
  }

  static updateGroup(group: GroupFormDTO, id: string | number) {
    const data = GroupService.getGroupRequestPayload(group);
    return ApiService.patch(`${GroupService.ENDPOINT}/${id}`, data);
  }

  static getGroup(id: number | string) {
    return ApiService.get<GroupDTO>(`${GroupService.ENDPOINT}/${id}`);
  }

  static deleteGroup(id: number | string) {
    return ApiService.delete(`${GroupService.ENDPOINT}/${id}`);
  }

  static getGroupRequestPayload(group: GroupFormDTO) {
    return {
      name: group.name,
      courierId: group.courier?.id,
      courierSchedule: group.courierSchedule,
      managerId: group.manager?.id,
      managerSchedule: group.managerSchedule,
      label: group.label,
      description: group.description,
    };
  }
}

export default GroupService;
