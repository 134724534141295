export function getFormattedDate(date = new Date()) {
  if (!date) {
    return '';
  }
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const dayName = days[date.getDay()];
  const day = date.getDate();
  const monthName = months[date.getMonth()];

  return `${dayName} ${day} ${monthName}`;
}

export function getFormattedDateRange(startDate: Date, endDate: Date | null) {
  if (!endDate) {
    return `${getFormattedDate(startDate)} -`;
  }
  return `${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`;
}

export function getFormattedDateTime(date = new Date()) {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dayName = days[date.getDay()];
  const day = date.getDate();
  const monthName = months[date.getMonth()];
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${dayName} ${day} ${monthName} ${hours}:${minutes}`;
}

type Keys = Array<
  string | { name: string; transformer: (param: string) => never }
>;

export function getFromSearchParams(keys: Keys) {
  const searchParams = new URLSearchParams(window.location.search);
  const result: Record<string, string> = {};
  keys.forEach((key) => {
    if (typeof key === 'object') {
      const { name, transformer } = key;
      const value = searchParams.get(name);
      if (value) {
        result[name] = transformer(value);
      }
    } else {
      const value = searchParams.get(key);
      if (value) {
        result[key] = value;
      }
    }
  });
  return result;
}

export const autocompleteTransformer = (e: never, data: never) => {
  return [data];
};

export const mergeAndRemoveMatched = <T>(arr1: T[], arr2: T[]) => {
  // Find common elements between the two arrays
  const commonElements = arr1.filter((item) => arr2.includes(item));

  // Filter out the common elements from both arrays
  const filteredArr1 = arr1.filter((item) => !commonElements.includes(item));
  const filteredArr2 = arr2.filter((item) => !commonElements.includes(item));

  // Merge the two filtered arrays
  return [...filteredArr1, ...filteredArr2];
};

export { default as QueryBuilder } from './query-builder';
export { default as getDuration } from './get-duration';
export { default as removeDuplicates } from './remove-duplicates';
export { default as toggleSearchParam } from './toggle-search-params';
export * from './helpers';
