import { Box, Skeleton } from '@mui/material';
import { style } from './style';

type Props = {
  count?: number;
};

function SearchResultsSkeleton({ count = 3 }: Props) {
  return (
    <Box sx={style.skeletons}>
      {[...Array(count)].map((_, index) => (
        <Skeleton
          animation='wave'
          height={48}
          variant='rounded'
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        />
      ))}
    </Box>
  );
}

SearchResultsSkeleton.displayName = 'SearchResultsSkeleton';

export default SearchResultsSkeleton;
