import { useCallback, useState } from 'react';

import { ToggleState } from '../types';

const useToggle = (defaultValue = false): ToggleState => {
  const [open, setOpen] = useState<boolean>(defaultValue);

  const toggle = useCallback((): void => {
    setOpen((value) => !value);
  }, [setOpen]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    toggle,
    handleOpen,
    handleClose,
  };
};

export default useToggle;
