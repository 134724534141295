import { ApplicationAction, ApplicationArea } from '../../shared/types';
import { GroupMember } from '../../shared/components/GroupListView';

export interface UserFormDTO {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  roleId: string;
  name: string;
  description: string;
  area: Record<ApplicationArea, ApplicationAction>;
  access: {
    level: string;
    transportIds: number[];
    teamIds: number[];
  };
  profileImageId: null | string;
}

export interface UserFormRequestDTO {
  access: {
    level: string;
    transportIds: number[];
    teamIds: number[];
  };
  firstName: string;
  profileImageId?: string | null;
  lastName: string;
  phone: string;
  email: string;
  roleId?: number | string;
  area?: Record<ApplicationArea, ApplicationAction>;
}

export type TransportTeamDTO = {
  id: number;
  teamId: number;
  teamName: string;
  licensePlate: string;
  imageId: string | null;
  name: string;
  courier: {
    firstName: string;
    lastName: string;
  } | null;
};

export const enum SearchOption {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
}

export interface SelectedGroup {
  id: number;
  name: string;
  members: Array<GroupMember>;
}
