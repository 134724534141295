import {
  ActionCreatorWithPayload,
  AnyAction,
  configureStore,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'react';
import { monitoringSlice } from '../../monitoring/slices/monitoring-slice';
import { authSlice } from '../../auth/slices/auth-slice';
import { layoutSlice } from '../slices/layout-slice';

const store = configureStore({
  reducer: {
    monitoringSlice: monitoringSlice.reducer,
    authSlice: authSlice.reducer,
    layoutSlice: layoutSlice.reducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction> &
  Dispatch<ActionCreatorWithPayload<any>>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
