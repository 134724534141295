import {
  Autocomplete,
  AutocompleteProps,
  Box,
  TextField,
  Typography,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { style } from './style';
import { Field } from '../../../global';
import { autocompleteTransformer } from '../../utils';
import { TeamService } from '../../../services';
import { TeamLightDTO } from '../../../services/team.service';

type Props = {
  name: string;
  label?: string;
  useDefaultAutocompleteLabel?: boolean;
  multiple?: boolean;
};

function TeamsAutocomplete({
  name,
  useDefaultAutocompleteLabel,
  label,
  multiple = false,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'shared' });
  const [search, setSearch] = useState('');

  const [teams, setTeams] = useState<{
    isLoading: boolean;
    items: TeamLightDTO[];
  }>({
    isLoading: true,
    items: [],
  });

  function handleSearchChange(
    event: SyntheticEvent<Element, Event>,
    val: string,
  ) {
    setSearch(val);
  }

  async function getTeams(searchTerm: string) {
    setTeams((existingTeams) => ({ ...existingTeams, isLoading: true }));
    const { ok, data } = await TeamService.getTeams({
      search: searchTerm,
      limit: 10,
      offset: 0,
    });
    if (ok) {
      setTeams({
        isLoading: false,
        items: data.items,
      });
    }
  }

  useEffect(() => {
    getTeams(search);
  }, [search]);

  const additionalProps: Pick<
    AutocompleteProps<unknown, boolean, boolean, boolean>,
    'clearIcon'
  > = {};
  const inputProps: Record<string, string> = {};

  if (multiple) {
    additionalProps.clearIcon = null;
  }

  if (useDefaultAutocompleteLabel) {
    inputProps.label = t('team');
  }

  return (
    <Box>
      <Field
        name={name}
        label={
          useDefaultAutocompleteLabel
            ? null
            : label || t(`team${multiple ? 's' : ''}`)
        }
        attachError={false}
        transformer={autocompleteTransformer}
      >
        <Autocomplete
          onInputChange={handleSearchChange}
          options={teams.items}
          loading={teams.isLoading}
          loadingText={t('gettingTeams')}
          size='small'
          freeSolo
          limitTags={1}
          filterOptions={(x) => x}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getOptionLabel={(option: TeamDTO) => option.name || ''}
          renderOption={(props, option: TeamLightDTO) => (
            <Box component='li' sx={style.option} {...props} key={option.id}>
              <Typography variant='body1' sx={style.teamName}>
                {option.name}({option.transportsCount})
              </Typography>
            </Box>
          )}
          renderInput={(params) => {
            return <TextField {...inputProps} {...params} />;
          }}
          multiple={multiple}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Field>
    </Box>
  );
}

TeamsAutocomplete.displayName = 'TeamsAutocomplete';

export default TeamsAutocomplete;
