import { lazy, Suspense } from 'react';
import { Drawer, Theme, useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import {
  MONITORING_DRAWER_COLLAPSED_WIDTH,
  MONITORING_DRAWER_WIDTH,
} from '../../utils/constants';
import {
  NAV_DRAWER_COLLAPSED_WIDTH,
  NAV_DRAWER_WIDTH,
} from '../../../shared/utils/constants';
import { useAppSelector } from '../../../layout/store';
import { getNavDrawerCollapsedSelector } from '../../../layout/selectors/layout-selector';
import { style } from './style';
import { toggleSearchParam } from '../../../shared/utils';
import { ROUTES } from '../../../global';

type MonitoringDeviceHistoryDrawerProps = {
  hasSelectedDevice: boolean;
  sidebarHidden: boolean;
};

const MonitoringDeviceHistoryWrapper = lazy(
  () => import('../MonitoringDeviceHistoryWrapper'),
);

function MonitoringDeviceHistoryDrawer(
  props: MonitoringDeviceHistoryDrawerProps,
) {
  const navDrawerCollapsed = useAppSelector(getNavDrawerCollapsedSelector);
  const { hasSelectedDevice, sidebarHidden } = props;
  const [, setSearchParams] = useSearchParams();

  const onDrawerClose = () => {
    setSearchParams((params) => {
      params = toggleSearchParam(
        params,
        ROUTES.monitoring.searchParams.transportID,
        undefined,
      );
      params.delete(ROUTES.monitoring.searchParams.tripID);
      return params;
    });
  };

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm'),
  );

  const calcDrawerLeftOffset = () => {
    if (isMobile) {
      return '0px';
    }

    const monitoringDrawerLeft = sidebarHidden
      ? MONITORING_DRAWER_COLLAPSED_WIDTH
      : MONITORING_DRAWER_WIDTH;

    const navDrawerLeft = navDrawerCollapsed
      ? NAV_DRAWER_COLLAPSED_WIDTH
      : NAV_DRAWER_WIDTH;

    return `${monitoringDrawerLeft + navDrawerLeft}px`;
  };

  return (
    <Drawer
      variant='persistent'
      open={hasSelectedDevice}
      anchor='bottom'
      ModalProps={{ keepMounted: true, hideBackdrop: true }}
      PaperProps={{ sx: style.paper(calcDrawerLeftOffset()) }}
      onClose={onDrawerClose}
    >
      <Suspense fallback={null}>
        <MonitoringDeviceHistoryWrapper onClose={onDrawerClose} />
      </Suspense>
    </Drawer>
  );
}

export default MonitoringDeviceHistoryDrawer;
