export const style = {
  toggleButton: {
    padding: '0',
    borderRadius: '50%',
    height: '30px',
    width: '28px',
    minWidth: 0,
    minHeight: 0,
  },
  field: {
    position: 'relative',
    paddingRight: '10px',
    height: '50px',
  },
};
