import { Button, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIsInBrowser } from '../../../global';
import usePwaInstallable from '../../../global/hooks/use-pwa-installable';

function GetTheApp() {
  const isInBrowser = useIsInBrowser();
  const { promptToInstall } = usePwaInstallable();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  if (!isInBrowser || !isMobile) {
    return null;
  }

  return (
    <Button
      fullWidth
      variant='contained'
      size='large'
      onClick={promptToInstall}
    >
      {t('getTheApp')}
    </Button>
  );
}

GetTheApp.displayName = 'GetTheApp';

export default GetTheApp;
