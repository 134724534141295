import { FC } from 'react';
import { AntennaSignalIcons, SignalStrengthLabels } from './constants';

type Props = {
  gsmSignal?: number | null;
};
export default function getAntennaSignalIcon({ gsmSignal }: Props): FC {
  if (!gsmSignal) return AntennaSignalIcons[SignalStrengthLabels.NoSignal];
  switch (!!gsmSignal) {
    case gsmSignal === 1:
      return AntennaSignalIcons[SignalStrengthLabels.VeryWeak];
    case gsmSignal === 2:
      return AntennaSignalIcons[SignalStrengthLabels.Weak];
    case gsmSignal === 3:
      return AntennaSignalIcons[SignalStrengthLabels.Strong];
    default:
      return AntennaSignalIcons[SignalStrengthLabels.VeryStrong];
  }
}
