import { ListResponseDTO, MatchingMethod } from '../global';
import ApiService from '../global/services/api.service';
import {
  SearchOption,
  VehicleFormDTO,
  VehicleListFilterDTO,
} from '../vehicles/types';
import { VehicleDTO, VehicleLightDTO } from '../shared/types';
import QueryBuilder from '../shared/utils/query-builder';

class VehicleService {
  static ENDPOINT = '/transports';

  static getVehicles(filters: VehicleListFilterDTO) {
    const body = new QueryBuilder()
      .addLimit(filters.limit)
      .addOffset(filters.offset)
      .or({
        field: SearchOption.NAME,
        value: filters.search,
        matchingMethod: MatchingMethod.STARTS_WITH,
      })
      .or({
        field: SearchOption.LICENSE_PLATE,
        value: filters.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: SearchOption.COURIER_FIRST_NAME,
        value: filters.search,
        matchingMethod: MatchingMethod.STARTS_WITH,
      })
      .or({
        field: SearchOption.COURIER_LAST_NAME,
        value: filters.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .and({
        field: SearchOption.VEHICLE_TYPE,
        value: filters.vehicleType,
        matchingMethod: MatchingMethod.EXACT,
      });

    return ApiService.post<ListResponseDTO<VehicleLightDTO>>(
      `${VehicleService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static getVehicleById(id: number) {
    return ApiService.get<VehicleDTO>(`${VehicleService.ENDPOINT}/${id}`);
  }

  static createVehicle(data: VehicleFormDTO) {
    const body = VehicleService.getVehicleRequestPayload(data);

    return ApiService.post(VehicleService.ENDPOINT, body);
  }

  static updateVehicle(id: number, data: VehicleFormDTO) {
    const body = VehicleService.getVehicleRequestPayload(data);

    return ApiService.patch(`${VehicleService.ENDPOINT}/${id}`, body);
  }

  static deleteVehicle(id: number) {
    return ApiService.delete(`${VehicleService.ENDPOINT}/${id}`);
  }

  static getVehicleRequestPayload(values: VehicleFormDTO) {
    return {
      name: values.name,
      vehicleType: values.type,
      licensePlate: values.licensePlate,
      currentKilometer: +values.mileage,
      vin: values.vin ? values.vin.toUpperCase() : null,
      imageId: values.image || null,
      teamId: values.team?.id || null,
      courierId: values.courier?.id,
      fuelType: values.fuelType,
      deviceId: values.device?.id,
      avgConsumption: +values.avgConsumption,
    };
  }
}

export default VehicleService;
