import { ApplicationAction, ApplicationArea } from '../types';
// TODO move useAppSelector into shared module
// eslint-disable-next-line import/no-restricted-paths
import { useAppSelector } from '../../layout/store';
import { getUserSelector } from '../selectors';

const usePermission = (area: ApplicationArea) => {
  const user = useAppSelector(getUserSelector);
  if (!user) {
    return { permission: ApplicationAction.NONE };
  }
  const { permissions } = user.role;
  const applicationAction =
    permissions.find((permission) => permission.area === area)?.action ||
    ApplicationAction.NONE;

  return { permission: applicationAction };
};

export default usePermission;
