import { Box } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { ROUTES } from '../../../global';
import { Map } from '../../../map/components';
import Monitoring from '../../../monitoring/components/Monitoring';
import AccessGuard from '../../../shared/components/AccessGuard';
import { ApplicationArea } from '../../../shared/types';
import styles from './styles';

const PersonnelLayoutLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "personnel-layout" */ '../../../shared/components/PersonnelLayout'
    ),
);

const DriversLayoutLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "drivers-layout" */ '../../../drivers/components/DriversLayout'
    ),
);

const PlacesLayoutLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "places-layout" */ '../../../shared/components/PlacesLayout'
    ),
);

const ProtocolsLayoutLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "protocols-layout" */ '../../../shared/components/ProtocolsLayout'
    ),
);

const UserManagmentLayoutLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "user-managment-layout" */ '../../../shared/components/UserManagmentLayout'
    ),
);

const GeofenceLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "geofence" */ '../../../geofence/components/Geofence'
    ),
);

const RulesLazy = lazy(
  () =>
    import(/* webpackChunkName: "rules" */ '../../../rules/components/Rules'),
);

const LocationsLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "locations" */ '../../../locations/components/Locations'
    ),
);

const GroupsLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "groups" */ '../../../groups/components/Groups'
    ),
);

const VehiclesLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "vehicles" */ '../../../vehicles/components/Vehicles'
    ),
);

const DriversLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "drivers" */ '../../../drivers/components/Drivers'
    ),
);

const TeamsLazy = lazy(
  () =>
    import(/* webpackChunkName: "teams" */ '../../../teams/components/Teams'),
);

const DevicesLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "devices" */ '../../../devices/components/Devices'
    ),
);

const RolesLazy = lazy(
  () =>
    import(/* webpackChunkName: "roles" */ '../../../roles/components/Roles'),
);

const UsersLazy = lazy(
  () =>
    import(/* webpackChunkName: "users" */ '../../../users/components/Users'),
);

const AlertsLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "alerts" */ '../../../alerts/components/Alerts'
    ),
);

function AppRoutes() {
  return (
    <Routes>
      <Route
        path={ROUTES.geofence.fullPath}
        element={
          <AccessGuard area={ApplicationArea.GEOFENCES}>
            <Box sx={styles.wrapper}>
              <Map
                controls={{
                  MAP_CONTROL: true,
                  MAP_CONTROL_MEASURE: true,
                  MAP_CONTROL_ZOOM: true,
                }}
              >
                <Suspense>
                  <GeofenceLazy />
                </Suspense>
              </Map>
            </Box>
          </AccessGuard>
        }
      />

      <Route
        path={ROUTES.protocols.path}
        element={
          <Suspense>
            <ProtocolsLayoutLazy />
          </Suspense>
        }
      >
        <Route
          path={ROUTES.protocols.rules.fullPath}
          element={
            <AccessGuard area={ApplicationArea.PROTOCOLS}>
              <Suspense>
                <RulesLazy />
              </Suspense>
            </AccessGuard>
          }
        />
        <Route
          path={ROUTES.protocols.alerts.fullPath}
          element={
            <AccessGuard area={ApplicationArea.PROTOCOLS}>
              <Suspense>
                <AlertsLazy />
              </Suspense>
            </AccessGuard>
          }
        />
      </Route>

      <Route
        path={ROUTES.places.path}
        element={
          <Suspense>
            <PlacesLayoutLazy />
          </Suspense>
        }
      >
        <Route
          path={ROUTES.places.locations.fullPath}
          element={
            <AccessGuard area={ApplicationArea.PLACES}>
              <Suspense>
                <LocationsLazy />
              </Suspense>
            </AccessGuard>
          }
        />
        <Route
          path={ROUTES.places.groups.fullPath}
          element={
            <AccessGuard area={ApplicationArea.PLACES}>
              <Suspense>
                <GroupsLazy />
              </Suspense>
            </AccessGuard>
          }
        />
      </Route>

      <Route
        path={ROUTES.personnel.path}
        element={
          <Suspense>
            <PersonnelLayoutLazy />
          </Suspense>
        }
      >
        <Route
          path={ROUTES.personnel.vehicles.fullPath}
          element={
            <AccessGuard area={ApplicationArea.PERSONNEL}>
              <Suspense>
                <VehiclesLazy />
              </Suspense>
            </AccessGuard>
          }
        />
        <Route
          path={ROUTES.personnel.teams.fullPath}
          element={
            <AccessGuard area={ApplicationArea.PERSONNEL}>
              <Suspense>
                <TeamsLazy />
              </Suspense>
            </AccessGuard>
          }
        />
        <Route
          path={ROUTES.personnel.devices.fullPath}
          element={
            <AccessGuard area={ApplicationArea.PERSONNEL}>
              <Suspense>
                <DevicesLazy />
              </Suspense>
            </AccessGuard>
          }
        />
      </Route>

      <Route
        path={ROUTES.drivers.fullPath}
        element={
          <Suspense>
            <DriversLayoutLazy />
          </Suspense>
        }
      >
        <Route
          path={ROUTES.drivers.fullPath}
          element={
            <AccessGuard area={ApplicationArea.PERSONNEL}>
              <Suspense>
                <DriversLazy />
              </Suspense>
            </AccessGuard>
          }
        />
      </Route>

      <Route
        path={ROUTES.monitoring.fullPath}
        element={
          <AccessGuard area={ApplicationArea.MONITORING}>
            <Box sx={styles.wrapper}>
              <Map
                controls={{
                  MAP_CONTROL: true,
                  MAP_CONTROL_MEASURE: true,
                  MAP_CONTROL_ZOOM: true,
                  MAP_CONTROL_FULL_SCREEN: true,
                  MAP_CONTROL_GEOFENCE: true,
                  MAP_CONTROL_LOCATIONS: true,
                  MONITORING_HISTORY_EVENTS: true,
                }}
              >
                <Monitoring />
              </Map>
            </Box>
          </AccessGuard>
        }
      />

      <Route
        path={ROUTES.admin.path}
        element={
          <Suspense>
            <UserManagmentLayoutLazy />
          </Suspense>
        }
      >
        <Route
          path={ROUTES.admin.roles.fullPath}
          element={
            <AccessGuard area={ApplicationArea.USERS}>
              <Suspense>
                <RolesLazy />
              </Suspense>
            </AccessGuard>
          }
        />
        <Route
          path={ROUTES.admin.users.fullPath}
          element={
            <AccessGuard area={ApplicationArea.USERS}>
              <Suspense>
                <UsersLazy />
              </Suspense>
            </AccessGuard>
          }
        />
      </Route>
      <Route path='*' element={<Navigate to={ROUTES.monitoring.path} />} />
    </Routes>
  );
}

export default AppRoutes;
