import {
  MONITORING_DRAWER_COLLAPSED_WIDTH,
  MONITORING_DRAWER_WIDTH,
} from '../../utils/constants';

export const style = {
  root: (collapsed: boolean) => ({
    transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1)',
    position: 'relative',
    pl: {
      xs: 0,
      sm: `${collapsed ? MONITORING_DRAWER_COLLAPSED_WIDTH : MONITORING_DRAWER_WIDTH}px`,
    },
  }),

  teams: {
    width: 238,
    marginTop: 2,
  },
  teamsFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  enableLocation: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  all: {
    color: '#808080',
  },
};
