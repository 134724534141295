import { Box, BoxProps, SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
import StarRateIcon from '@mui/icons-material/StarRate';
import googleMapsSatellite from '../../../../assets/google-maps-satellite.png';
import googleMapsTraffic from '../../../../assets/google-maps-traffic.png';
import yandexMaps from '../../../../assets/yandex-maps.png';
import osm from '../../../../assets/osm.png';
import { LocationIconProps, IconVariant } from '../../types';
import { buildIconPalette } from '../../hooks/buildIconPalette';
import { style } from './style';

function TriangleRounded({ fill }: SvgIconProps) {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='25'
        cy='25'
        r='24.25'
        fill='#2860B4'
        fillOpacity='0.25'
        stroke='#2860B4'
        strokeWidth='0.5'
      />
      <path
        d='M24.676 31.3808L25 31.6565L25.324 31.3808C28.0267 29.081 30.0672 26.927 31.4266 24.9177C32.7851 22.9099 33.5 21.0003 33.5 19.2C33.5 16.5823 32.6525 14.4494 30.9265 12.8575C29.2292 11.2921 27.2469 10.5 25 10.5C22.7531 10.5 20.7708 11.2921 19.0735 12.8575C17.3475 14.4494 16.5 16.5823 16.5 19.2C16.5 21.0003 17.2149 22.9099 18.5734 24.9177C19.9328 26.927 21.9733 29.081 24.676 31.3808Z'
        fill={fill}
        stroke='white'
      />
      <path d='M25 14L29.3301 21.5H20.6699L25 14Z' fill='white' />
      <circle cx='25' cy='37' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function Triangle({ fill }: SvgIconProps) {
  return (
    <svg
      width='24'
      height='31'
      viewBox='0 0 24 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
        fill={fill}
        stroke='white'
      />
      <path d='M12 5L16.3301 12.5H7.66987L12 5Z' fill='white' />
      <circle cx='12' cy='28' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function Circle({ fill }: SvgIconProps) {
  return (
    <svg
      width='24'
      height='31'
      viewBox='0 0 24 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
        fill={fill}
        stroke='white'
      />
      <circle cx='12' cy='10' r='4' fill='white' />
      <circle cx='12' cy='28' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function CircleRounded({ fill }: SvgIconProps) {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='25'
        cy='25'
        r='24.25'
        fill='#2860B4'
        fillOpacity='0.25'
        stroke='#2860B4'
        strokeWidth='0.5'
      />
      <path
        d='M24.676 31.3808L25 31.6565L25.324 31.3808C28.0267 29.081 30.0672 26.927 31.4266 24.9177C32.7851 22.9099 33.5 21.0003 33.5 19.2C33.5 16.5823 32.6525 14.4494 30.9265 12.8575C29.2292 11.2921 27.2469 10.5 25 10.5C22.7531 10.5 20.7708 11.2921 19.0735 12.8575C17.3475 14.4494 16.5 16.5823 16.5 19.2C16.5 21.0003 17.2149 22.9099 18.5734 24.9177C19.9328 26.927 21.9733 29.081 24.676 31.3808Z'
        fill={fill}
        stroke='white'
      />
      <circle cx='25' cy='19' r='4' fill='white' />
      <circle cx='25' cy='37' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function StarRounded({ fill }: SvgIconProps) {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='25'
        cy='25'
        r='24.25'
        fill='#2860B4'
        fillOpacity='0.25'
        stroke='#2860B4'
        strokeWidth='0.5'
      />
      <path
        d='M24.676 31.3808L25 31.6565L25.324 31.3808C28.0267 29.081 30.0672 26.927 31.4266 24.9177C32.7851 22.9099 33.5 21.0003 33.5 19.2C33.5 16.5823 32.6525 14.4494 30.9265 12.8575C29.2292 11.2921 27.2469 10.5 25 10.5C22.7531 10.5 20.7708 11.2921 19.0735 12.8575C17.3475 14.4494 16.5 16.5823 16.5 19.2C16.5 21.0003 17.2149 22.9099 18.5734 24.9177C19.9328 26.927 21.9733 29.081 24.676 31.3808Z'
        fill={fill}
        stroke='white'
      />
      <path
        d='M25 14L26.2931 17.2202L29.7553 17.4549L27.0923 19.6798L27.9389 23.0451L25 21.2L22.0611 23.0451L22.9077 19.6798L20.2447 17.4549L23.7069 17.2202L25 14Z'
        fill='white'
      />
      <circle cx='25' cy='37' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function CrossRounded({ fill }: SvgIconProps) {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='25'
        cy='25'
        r='24.25'
        fill='#2860B4'
        fillOpacity='0.25'
        stroke='#2860B4'
        strokeWidth='0.5'
      />
      <path
        d='M24.676 31.3808L25 31.6565L25.324 31.3808C28.0267 29.081 30.0672 26.927 31.4266 24.9177C32.7851 22.9099 33.5 21.0003 33.5 19.2C33.5 16.5823 32.6525 14.4494 30.9265 12.8575C29.2292 11.2921 27.2469 10.5 25 10.5C22.7531 10.5 20.7708 11.2921 19.0735 12.8575C17.3475 14.4494 16.5 16.5823 16.5 19.2C16.5 21.0003 17.2149 22.9099 18.5734 24.9177C19.9328 26.927 21.9733 29.081 24.676 31.3808Z'
        fill={fill}
        stroke='white'
      />
      <path
        d='M25 14L26.1667 17.8333L30 19L26.1667 20.1667L25 24L23.8333 20.1667L20 19L23.8333 17.8333L25 14Z'
        fill='white'
      />
      <circle cx='25' cy='37' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function Cross({ fill }: SvgIconProps) {
  return (
    <svg
      width='24'
      height='31'
      viewBox='0 0 24 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
        fill={fill}
        stroke='white'
      />
      <path
        d='M12 5L13.1667 8.83327L17 10L13.1667 11.1667L12 15L10.8333 11.1667L7 10L10.8333 8.83327L12 5Z'
        fill='white'
      />
      <circle cx='12' cy='28' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function Star({ fill }: SvgIconProps) {
  return (
    <svg
      width='24'
      height='31'
      viewBox='0 0 24 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
        fill={fill}
        stroke='white'
      />
      <path
        d='M12 5L13.2931 8.22016L16.7553 8.45492L14.0923 10.6798L14.9389 14.0451L12 12.2L9.06107 14.0451L9.90768 10.6798L7.24472 8.45492L10.7069 8.22016L12 5Z'
        fill='white'
      />
      <circle cx='12' cy='28' r='2.5' fill={fill} stroke='white' />
    </svg>
  );
}

function Square({ fill }: SvgIconProps) {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.676 31.3808L25 31.6565L25.324 31.3808C28.0267 29.081 30.0672 26.927 31.4266 24.9177C32.7851 22.9099 33.5 21.0003 33.5 19.2C33.5 16.5823 32.6525 14.4494 30.9265 12.8575C29.2292 11.2921 27.2469 10.5 25 10.5C22.7531 10.5 20.7708 11.2921 19.0735 12.8575C17.3475 14.4494 16.5 16.5823 16.5 19.2C16.5 21.0003 17.2149 22.9099 18.5734 24.9177C19.9328 26.927 21.9733 29.081 24.676 31.3808Z'
        fill={fill}
        stroke='white'
      />
      <rect x='22' y='16' width='6' height='6' fill='white' />
      <circle cx='25' cy='37' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function SquareRounded({ fill }: SvgIconProps) {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='25'
        cy='25'
        r='24.25'
        fill='#2860B4'
        fillOpacity='0.25'
        stroke='#2860B4'
        strokeWidth='0.5'
      />
      <path
        d='M24.676 31.3808L25 31.6565L25.324 31.3808C28.0267 29.081 30.0672 26.927 31.4266 24.9177C32.7851 22.9099 33.5 21.0003 33.5 19.2C33.5 16.5823 32.6525 14.4494 30.9265 12.8575C29.2292 11.2921 27.2469 10.5 25 10.5C22.7531 10.5 20.7708 11.2921 19.0735 12.8575C17.3475 14.4494 16.5 16.5823 16.5 19.2C16.5 21.0003 17.2149 22.9099 18.5734 24.9177C19.9328 26.927 21.9733 29.081 24.676 31.3808Z'
        fill={fill}
        stroke='white'
      />
      <rect x='22' y='16' width='6' height='6' fill='white' />
      <circle cx='25' cy='37' r='2.5' fill={fill} stroke='white' />
    </svg>
  );
}

function RhombusRounded({ fill }: SvgIconProps) {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='25'
        cy='25'
        r='24.25'
        fill='#2860B4'
        fillOpacity='0.25'
        stroke='#2860B4'
        strokeWidth='0.5'
      />
      <path
        d='M24.676 31.3808L25 31.6565L25.324 31.3808C28.0267 29.081 30.0672 26.927 31.4266 24.9177C32.7851 22.9099 33.5 21.0003 33.5 19.2C33.5 16.5823 32.6525 14.4494 30.9265 12.8575C29.2292 11.2921 27.2469 10.5 25 10.5C22.7531 10.5 20.7708 11.2921 19.0735 12.8575C17.3475 14.4494 16.5 16.5823 16.5 19.2C16.5 21.0003 17.2149 22.9099 18.5734 24.9177C19.9328 26.927 21.9733 29.081 24.676 31.3808Z'
        fill={fill}
        stroke='white'
      />
      <rect
        x='25'
        y='14.7574'
        width='6'
        height='6'
        transform='rotate(45 25 14.7574)'
        fill='white'
      />
      <circle cx='25' cy='37' r='2.5' fill={fill} stroke='white' />
    </svg>
  );
}

function Rhombus({ fill }: SvgIconProps) {
  return (
    <svg
      width='24'
      height='31'
      viewBox='0 0 24 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
        fill={fill}
        stroke='white'
      />
      <rect
        x='12'
        y='5.75735'
        width='6'
        height='6'
        transform='rotate(45 12 5.75735)'
        fill='white'
      />
      <circle cx='12' cy='28' r='2.5' fill='#2860B4' stroke='white' />
    </svg>
  );
}

function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={24} viewBox='0 0 24 24' {...props}>
      <g clipPath='url(#clip0_197_4368)'>
        <path
          d='M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM21.41 6.34L17.66 2.59L15.13 5.13L18.88 8.88L21.41 6.34Z'
          fill='#888888'
        />
      </g>
      <defs>
        <clipPath id='clip0_197_4368'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

function SuperFleetIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='164'
      height='20'
      viewBox='0 0 253 32'
      fill='none'
    >
      <path
        d='M30.5724 5.28992L31.0468 4.66748V29.0192C31.0468 30.1238 30.1514 31.0192 29.0468 31.0192H13.0918L30.5724 5.28992Z'
        fill='white'
      />
      <path
        d='M31.0213 4.02417H31.0595L7.15128 31.0606H2C0.895431 31.0606 0 30.1651 0 29.0606V16.139L31.0213 4.02417Z'
        fill='white'
      />
      <path
        d='M29.0438 0C30.1484 0 31.0438 0.895431 31.0438 2V3.14333L0.0732422 11.9812V2C0.0732422 0.895428 0.968673 0 2.07324 0H29.0438Z'
        fill='white'
      />
      <path
        d='M60.1579 27.919C65.6778 27.919 69.0817 25.1437 69.0817 20.7585V20.7432C69.0817 17.2933 67.0424 15.4227 62.3659 14.4567L60.02 13.9814C57.4134 13.4447 56.34 12.5861 56.34 11.1448V11.1294C56.34 9.47348 57.858 8.43084 60.1426 8.41551C62.4732 8.41551 64.0065 9.51948 64.2672 11.0988L64.2978 11.2828H68.6524L68.6371 11.0834C68.3457 7.3882 65.3098 4.65894 60.1426 4.65894C55.2361 4.65894 51.7095 7.37287 51.7095 11.4821V11.4974C51.7095 14.8553 53.6874 17.002 58.2873 17.9373L60.6179 18.4126C63.3625 18.9799 64.4512 19.7925 64.4512 21.2492V21.2645C64.4512 22.9665 62.7492 24.1471 60.2806 24.1471C57.6893 24.1471 55.8954 23.0278 55.7267 21.3718L55.7114 21.2185H51.2495L51.2648 21.4638C51.5255 25.3891 54.8681 27.919 60.1579 27.919Z'
        fill='white'
      />
      <path
        d='M82.728 27.919C88.4319 27.919 91.9891 24.6224 91.9891 19.7159V5.22625H87.3586V19.3019C87.3586 22.1692 85.7026 23.9938 82.728 23.9938C79.7534 23.9938 78.0822 22.1692 78.0822 19.3019V5.22625H73.4516V19.7159C73.4516 24.6378 77.0548 27.919 82.728 27.919Z'
        fill='white'
      />
      <path
        d='M97.3557 27.3517H101.986V20.3752H106.479C111.14 20.3752 114.253 17.3699 114.253 12.8161V12.7854C114.253 8.23151 111.14 5.22625 106.479 5.22625H97.3557V27.3517ZM105.344 8.89083C107.997 8.89083 109.561 10.2861 109.561 12.8007V12.8314C109.561 15.346 107.997 16.7566 105.344 16.7566H101.986V8.89083H105.344Z'
        fill='white'
      />
      <path
        d='M118.515 27.3517H133.173V23.5338H123.146V17.9373H132.606V14.38H123.146V9.04416H133.173V5.22625H118.515V27.3517Z'
        fill='white'
      />
      <path
        d='M138.049 27.3517H142.68V19.2559H146.467L150.592 27.3517H155.835L151.174 18.5659C153.704 17.5693 155.176 15.1313 155.176 12.2947V12.2641C155.176 7.90952 152.294 5.22625 147.264 5.22625H138.049V27.3517ZM142.68 15.8826V8.84483H146.697C149.012 8.84483 150.438 10.2248 150.438 12.3561V12.3867C150.438 14.564 149.074 15.8826 146.758 15.8826H142.68Z'
        fill='white'
      />
      <path
        d='M159.853 27.3517H164.483V18.6426H173.484V14.978H164.483V9.04416H174.342V5.22625H159.853V27.3517Z'
        fill='white'
      />
      <path
        d='M178.62 27.3517H193.095V23.5338H183.251V5.22625H178.62V27.3517Z'
        fill='white'
      />
      <path
        d='M197.296 27.3517H211.954V23.5338H201.926V17.9373H211.387V14.38H201.926V9.04416H211.954V5.22625H197.296V27.3517Z'
        fill='white'
      />
      <path
        d='M216.83 27.3517H231.488V23.5338H221.46V17.9373H230.921V14.38H221.46V9.04416H231.488V5.22625H216.83V27.3517Z'
        fill='white'
      />
      <path
        d='M241.731 27.3517H246.361V9.04416H253V5.22625H235.091V9.04416H241.731V27.3517Z'
        fill='white'
      />
    </svg>
  );
}

function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={24} viewBox='0 0 24 24' {...props}>
      <g clipPath='url(#clip0_197_4374)'>
        <path
          d='M6 21H18V7H6V21ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z'
          fill='#888888'
        />
      </g>
      <defs>
        <clipPath id='clip0_197_4374'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

function GoogleMapsTerrainIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width='14'
        height='20'
        viewBox='0 0 14 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_23_72961)'>
          <path
            d='M4.91248 5.33683L1.62005 2.56641C1.62005 2.56641 1.619 2.56747 1.61865 2.56818L4.91073 5.33896L4.91213 5.33719L4.91248 5.33683Z'
            fill='#547DBF'
          />
          <path
            d='M8.95623 8.74059L8.95483 8.74236L12.4832 11.7119C12.4832 11.7119 12.4843 11.7105 12.4846 11.7098L8.95623 8.74023V8.74059Z'
            fill='#547DBF'
          />
          <path
            d='M4.91448 5.33955L4.91247 5.33789L4.91113 5.33955L4.91348 5.34155C4.91348 5.34155 4.91448 5.34022 4.91482 5.33989L4.91448 5.33955Z'
            fill='white'
          />
          <path
            d='M8.95361 8.74214L8.95507 8.74356L8.957 8.74118L8.95555 8.74023C8.95555 8.74023 8.9541 8.74166 8.95361 8.74261V8.74214Z'
            fill='white'
          />
          <path
            d='M0.73204 10.3566L4.91068 5.33914L1.6186 2.56836C0.550253 3.90219 0 5.5429 0 6.95044C0 8.28037 0.254851 9.34807 0.73204 10.3562V10.3566Z'
            fill='#EA4435'
          />
          <path
            d='M6.99972 9.62482C5.54822 9.62482 4.37186 8.43203 4.37186 6.96106C4.37186 6.35191 4.57357 5.7906 4.91302 5.34197L4.91057 5.33984L0.731934 10.3573C1.3168 11.593 2.23552 12.7397 3.42832 14.1696C3.60486 14.3811 3.76986 14.5856 3.92438 14.7826L8.95427 8.74316L8.95322 8.7421C8.47219 9.28393 7.77511 9.62447 6.99937 9.62447L6.99972 9.62482Z'
            fill='#F8BB16'
          />
          <path
            d='M3.9248 14.7839C5.66681 17.0065 6.06394 18.3255 6.25447 19.0211C6.46177 19.7787 6.66908 20.0012 7.00014 20.0012C7.3312 20.0012 7.53851 19.7784 7.74582 19.0211C7.95312 18.2634 8.40514 16.7677 10.5715 14.1705C11.3225 13.2701 11.9647 12.4823 12.4834 11.7137L8.95504 8.74414L3.92515 14.7836L3.9248 14.7839Z'
            fill='#32A750'
          />
          <path
            d='M13.1392 3.7191L8.95605 8.74187L12.4845 11.7115C13.4612 10.2639 13.9996 8.88468 13.9996 6.95162C13.9996 5.92822 13.7087 4.78149 13.1392 3.71875V3.7191Z'
            fill='#32A750'
          />
          <path
            d='M9.10098 0.308297C8.46053 0.110562 7.75995 0 6.99995 0C4.60596 0 2.79823 1.09676 1.62012 2.56702L4.9122 5.3378L9.10098 0.308297Z'
            fill='#3F70B7'
          />
          <path
            d='M9.10078 0.308948L4.91235 5.33809L4.91445 5.33987C5.39479 4.70591 6.15025 4.29697 6.99975 4.29697C8.45125 4.29697 9.62761 5.48976 9.62761 6.96073C9.62761 7.64501 9.37311 8.26869 8.955 8.74035L8.95605 8.74106L13.1392 3.71829C12.3359 2.21933 10.9781 0.888335 9.10043 0.308594L9.10078 0.308948Z'
            fill='#547DBF'
          />
        </g>
        <defs>
          <clipPath id='clip0_23_72961'>
            <rect width='14' height='20' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

function GoogleMapsSatelliteIcon(props: BoxProps) {
  return (
    <Box
      component='img'
      src={googleMapsSatellite}
      alt='Google Maps Satellite'
      {...props}
    />
  );
}

function GoogleMapsTrafficIcon(props: BoxProps) {
  return (
    <Box
      component='img'
      src={googleMapsTraffic}
      alt='Google Maps Traffic'
      {...props}
    />
  );
}

function YandexIcon(props: BoxProps) {
  return <Box component='img' src={yandexMaps} alt='Yandex Maps' {...props} />;
}

function OSMIcon(props: BoxProps) {
  return <Box component='img' src={osm} alt='Open Street Map' {...props} />;
}

function IdentityDocumentIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id='mask0_79_35199'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='18'
          height='18'
        >
          <rect width='18' height='18' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_79_35199)'>
          <path
            d='M10.5 9.75H14.25V8.25H10.5V9.75ZM10.5 7.5H14.25V6H10.5V7.5ZM3.75 12H9.75V11.5875C9.75 11.025 9.475 10.5781 8.925 10.2469C8.375 9.91563 7.65 9.75 6.75 9.75C5.85 9.75 5.125 9.91563 4.575 10.2469C4.025 10.5781 3.75 11.025 3.75 11.5875V12ZM6.75 9C7.1625 9 7.51563 8.85313 7.80938 8.55937C8.10313 8.26562 8.25 7.9125 8.25 7.5C8.25 7.0875 8.10313 6.73438 7.80938 6.44063C7.51563 6.14688 7.1625 6 6.75 6C6.3375 6 5.98438 6.14688 5.69063 6.44063C5.39688 6.73438 5.25 7.0875 5.25 7.5C5.25 7.9125 5.39688 8.26562 5.69063 8.55937C5.98438 8.85313 6.3375 9 6.75 9ZM3 15C2.5875 15 2.23438 14.8531 1.94063 14.5594C1.64688 14.2656 1.5 13.9125 1.5 13.5V4.5C1.5 4.0875 1.64688 3.73438 1.94063 3.44063C2.23438 3.14688 2.5875 3 3 3H15C15.4125 3 15.7656 3.14688 16.0594 3.44063C16.3531 3.73438 16.5 4.0875 16.5 4.5V13.5C16.5 13.9125 16.3531 14.2656 16.0594 14.5594C15.7656 14.8531 15.4125 15 15 15H3ZM3 13.5H15V4.5H3V13.5Z'
            fill='#808080'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

function LocationCircleIcon({ variant, round, ...props }: LocationIconProps) {
  const { fill } = props;
  const iconPalette = buildIconPalette(fill, variant);

  if (variant == IconVariant.MAP_ICON) {
    return round ? (
      <CircleRounded fill={iconPalette.markerFill} />
    ) : (
      <Circle fill={iconPalette.markerFill} />
    );
  }
  if (variant === IconVariant.NATURAL) {
    return <CircleIcon {...props} />;
  }
  const { position, backgroundColor } = iconPalette;
  return (
    <Box sx={style.locationsIcon(position, backgroundColor)}>
      <SvgIcon {...props}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
            fill={iconPalette.markerFill}
            stroke='white'
          />
          <circle cx='12' cy='10' r='4' fill={iconPalette.markerBackground} />
        </svg>
      </SvgIcon>
    </Box>
  );
}

function LocationSquareIcon({ variant, ...props }: LocationIconProps) {
  const { fill, round } = props;
  const iconPalette = buildIconPalette(fill, variant);
  if (variant == IconVariant.MAP_ICON) {
    return round ? (
      <SquareRounded fill={iconPalette.markerFill} />
    ) : (
      <Square fill={iconPalette.markerFill} />
    );
  }

  if (variant === IconVariant.NATURAL) {
    return <SquareIcon {...props} />;
  }
  const { position, backgroundColor } = iconPalette;
  return (
    <Box sx={style.locationsIcon(position, backgroundColor)}>
      <SvgIcon {...props}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='white'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
            fill={iconPalette.markerFill}
            stroke='white'
          />
          <rect
            x='9'
            y='7'
            width='6'
            height='6'
            fill={iconPalette.markerBackground}
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}

function TriangleIcon() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 10 8'
      fill='black'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5 0L9.33013 7.5H0.669873L5 0Z' height={17} fill='black' />
    </svg>
  );
}

function CrossIcon() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 10 10'
      fill='black'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 0L6.16673 3.83327L10 5L6.16673 6.16673L5 10L3.83327 6.16673L0 5L3.83327 3.83327L5 0Z'
        fill='black'
      />
    </svg>
  );
}

function RhombusIcon() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 10 10'
      fill='black'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='5'
        y='0.757324'
        width='6'
        height='6'
        transform='rotate(45 5 0.757324)'
        fill='black'
      />
    </svg>
  );
}

function LocationTriangleIcon({ variant, round, ...props }: LocationIconProps) {
  const { fill } = props;
  const iconPalette = buildIconPalette(fill, variant);

  if (variant === IconVariant.NATURAL) {
    return <TriangleIcon {...props} />;
  }
  if (variant == IconVariant.MAP_ICON) {
    return round ? (
      <TriangleRounded fill={iconPalette.markerFill} />
    ) : (
      <Triangle fill={iconPalette.markerFill} />
    );
  }
  const { position, backgroundColor } = iconPalette;
  return (
    <Box sx={style.locationsIcon(position, backgroundColor)}>
      <SvgIcon {...props}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='white'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
            fill={iconPalette.markerFill}
            stroke='white'
          />
          <path
            d='M12 5L16.3301 12.5H7.66987L12 5Z'
            fill={iconPalette.markerBackground}
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}

function LocationRhombusIcon(props: LocationIconProps) {
  const { variant, round, fill } = props;
  const iconPalette = buildIconPalette(fill, variant);

  if (variant === IconVariant.NATURAL) {
    return <RhombusIcon />;
  }
  if (variant == IconVariant.MAP_ICON) {
    return round ? (
      <RhombusRounded fill={iconPalette.markerFill} />
    ) : (
      <Rhombus fill={iconPalette.markerFill} />
    );
  }
  const { position, backgroundColor } = iconPalette;

  return (
    <Box sx={style.locationsIcon(position, backgroundColor)}>
      <SvgIcon {...props}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='white'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
            fill={iconPalette.markerFill}
            stroke='white'
          />
          <rect
            x='12'
            y='5.75735'
            width='6'
            height='6'
            transform='rotate(45 12 5.75735)'
            fill={iconPalette.markerBackground}
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}

function LocationCrossIcon(props: LocationIconProps) {
  const { variant, round, fill } = props;
  const iconPalette = buildIconPalette(fill, variant);

  if (variant === IconVariant.NATURAL) {
    return <CrossIcon />;
  }
  if (variant == IconVariant.MAP_ICON) {
    return round ? (
      <CrossRounded fill={iconPalette.markerFill} />
    ) : (
      <Cross fill={iconPalette.markerFill} />
    );
  }
  const { position, backgroundColor } = iconPalette;

  return (
    <Box sx={style.locationsIcon(position, backgroundColor)}>
      <SvgIcon {...props}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='white'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
            fill={iconPalette.markerFill}
            stroke='white'
          />
          <path
            d='M12 5L13.1667 8.83327L17 10L13.1667 11.1667L12 15L10.8333 11.1667L7 10L10.8333 8.83327L12 5Z'
            fill={iconPalette.markerBackground}
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}

function LocationStarIcon(props: LocationIconProps) {
  const { fill, round, variant } = props;
  const iconPalette = buildIconPalette(fill, variant);

  if (variant === IconVariant.NATURAL) {
    return <StarRateIcon />;
  }
  if (variant == IconVariant.MAP_ICON) {
    return round ? (
      <StarRounded fill={iconPalette.markerFill} />
    ) : (
      <Star fill={iconPalette.markerFill} />
    );
  }
  const { position, backgroundColor } = iconPalette;
  return (
    <Box sx={style.locationsIcon(position, backgroundColor)}>
      <SvgIcon {...props}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.676 22.3808L12 22.6565L12.324 22.3808C15.0267 20.081 17.0672 17.927 18.4266 15.9177C19.7851 13.9099 20.5 12.0003 20.5 10.2C20.5 7.5823 19.6525 5.44935 17.9265 3.85745C16.2292 2.29212 14.2469 1.5 12 1.5C9.75312 1.5 7.77076 2.29212 6.07352 3.85745C4.34747 5.44935 3.5 7.5823 3.5 10.2C3.5 12.0003 4.21491 13.9099 5.57338 15.9177C6.93284 17.927 8.97329 20.081 11.676 22.3808Z'
            fill={iconPalette.markerFill}
            stroke='white'
          />
          <path
            d='M12 5L13.2931 8.22016L16.7553 8.45492L14.0923 10.6798L14.9389 14.0451L12 12.2L9.06107 14.0451L9.90768 10.6798L7.24472 8.45492L10.7069 8.22016L12 5Z'
            fill={iconPalette.markerBackground}
          />
        </svg>
      </SvgIcon>
    </Box>
  );
}

function ParkingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.99992 16.6673C13.6818 16.6673 16.6666 13.6825 16.6666 10.0007C16.6666 6.31875 13.6818 3.33398 9.99992 3.33398C6.31802 3.33398 3.33325 6.31875 3.33325 10.0007C3.33325 13.6825 6.31802 16.6673 9.99992 16.6673ZM9.28703 13.334V11.2466H10.3572C11.0598 11.2466 11.6091 11.0798 12.0051 10.7461C12.4039 10.4097 12.6033 9.95128 12.6033 9.37077C12.6033 8.98828 12.5124 8.64784 12.3306 8.34945C12.1489 8.04834 11.8885 7.81641 11.5494 7.65365C11.213 7.49089 10.8224 7.4095 10.3775 7.4095H8.06633V13.334H9.28703ZM10.3775 10.2578H9.28703V8.39827H10.406C10.7071 8.4037 10.9431 8.49593 11.114 8.67497C11.2849 8.854 11.3704 9.08865 11.3704 9.37891C11.3704 9.66102 11.2849 9.87804 11.114 10.0299C10.9458 10.1819 10.7003 10.2578 10.3775 10.2578Z'
          fill='#808080'
        />
      </svg>
    </SvgIcon>
  );
}

function HarshDrivingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.95833 15.4038H17.0833C17.2917 15.4038 17.5 15.6122 17.5 15.8205V16.2372C17.5 16.4715 17.2917 16.6538 17.0833 16.6538H2.91667C2.68229 16.6538 2.5 16.4715 2.5 16.2372V15.8205C2.5 15.6122 2.68229 15.4038 2.91667 15.4038H6.64062L5.20833 13.9976C4.60938 13.3986 4.375 12.5653 4.53125 11.8101L3.48958 9.36216C3.15104 8.58091 3.33333 7.69549 3.93229 7.09654L7.08333 3.94549C7.68229 3.34654 8.56771 3.16424 9.34896 3.50279L11.7969 4.54445C12.5521 4.3882 13.3854 4.62258 13.9844 5.22154L16.4844 7.72154C16.7188 7.95591 16.7188 8.34654 16.4844 8.58091L16.1719 8.86737C15.9375 9.12779 15.5469 9.12779 15.3125 8.86737L14.8698 8.4507L8.4375 14.883L8.95833 15.4038ZM4.79167 7.98195C4.55729 8.21633 4.50521 8.55487 4.63542 8.86737L5.28646 10.4038L10.3906 5.29966L8.85417 4.64862C8.54167 4.51841 8.20312 4.57049 7.96875 4.80487L4.79167 7.98195ZM11.6276 6.83608C11.263 7.17462 11.263 8.24233 11.6016 8.58087C11.9199 8.89921 12.3176 8.48295 12.6388 8.14687C12.6855 8.09804 12.7305 8.0509 12.7734 8.00795C13.138 7.64337 13.138 7.17462 12.7734 6.83608C12.4349 6.4715 11.9661 6.4715 11.6276 6.83608ZM6.9401 11.5236C6.57552 11.8621 6.57552 12.3309 6.9401 12.6694C7.27865 13.034 7.7474 13.034 8.11198 12.6694C8.15492 12.6265 8.20206 12.5814 8.25089 12.5348C8.58698 12.2136 9.00323 11.8159 8.6849 11.4975C8.34635 11.159 7.27865 11.159 6.9401 11.5236Z'
          fill='white'
        />
      </svg>
    </SvgIcon>
  );
}

function WrongLocationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id='mask0_5657_60099'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='18'
          height='18'
        >
          <rect width='18' height='18' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_5657_60099)'>
          <path
            d='M9 16.4996C6.9875 14.7871 5.48438 13.1965 4.49063 11.7277C3.49688 10.259 3 8.89961 3 7.64961C3 5.77461 3.60312 4.28086 4.80938 3.16836C6.01563 2.05586 7.4125 1.49961 9 1.49961C9.125 1.49961 9.24688 1.50273 9.36563 1.50898C9.48438 1.51523 9.6125 1.53086 9.75 1.55586V3.07461C9.625 3.04961 9.5 3.03086 9.375 3.01836C9.25 3.00586 9.125 2.99961 9 2.99961C7.7375 2.99961 6.67188 3.43398 5.80312 4.30273C4.93438 5.17148 4.5 6.28711 4.5 7.64961C4.5 8.53711 4.86875 9.55273 5.60625 10.6965C6.34375 11.8402 7.475 13.1121 9 14.5121C10.525 13.1121 11.6562 11.8402 12.3938 10.6965C13.1313 9.55273 13.5 8.53711 13.5 7.64961C13.5 7.62461 13.4969 7.59961 13.4906 7.57461C13.4844 7.54961 13.4813 7.52461 13.4813 7.49961H14.9813C14.9813 7.52461 14.9844 7.54961 14.9906 7.57461C14.9969 7.59961 15 7.62461 15 7.64961C15 8.89961 14.5031 10.259 13.5094 11.7277C12.5156 13.1965 11.0125 14.7871 9 16.4996ZM12.6562 6.03711L14.2313 4.46211L15.8063 6.03711L16.8563 4.98711L15.2812 3.41211L16.8563 1.83711L15.8063 0.787109L14.2313 2.36211L12.6562 0.787109L11.6063 1.83711L13.1812 3.41211L11.6063 4.98711L12.6562 6.03711ZM9 8.99961C9.4125 8.99961 9.76563 8.85273 10.0594 8.55898C10.3531 8.26523 10.5 7.91211 10.5 7.49961C10.5 7.08711 10.3531 6.73398 10.0594 6.44023C9.76563 6.14648 9.4125 5.99961 9 5.99961C8.5875 5.99961 8.23438 6.14648 7.94063 6.44023C7.64687 6.73398 7.5 7.08711 7.5 7.49961C7.5 7.91211 7.64687 8.26523 7.94063 8.55898C8.23438 8.85273 8.5875 8.99961 9 8.99961Z'
            fill='#808080'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

function NoDataIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width='120'
        height='66'
        viewBox='0 0 120 66'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id='mask0_5763_66323'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='120'
          height='66'
        >
          <path d='M120 0H0V65.6857H120V0Z' fill='white' />
        </mask>
        <g mask='url(#mask0_5763_66323)'>
          <path
            d='M101.239 28.5241C100.95 27.7453 100.661 26.9523 100.175 26.2779C99.6901 25.6036 98.9855 25.0527 98.1735 24.953C97.3616 24.8533 96.461 25.2996 96.195 26.088C96.0783 24.3119 95.831 22.4883 94.9584 20.9449C94.0858 19.4016 92.4526 18.1906 90.7074 18.2998C89.0602 18.4043 87.6509 19.6485 86.867 21.1254C86.083 22.6023 85.8124 24.2976 85.5557 25.955C84.9304 25.1762 83.6145 25.4184 82.9612 26.1782C82.3079 26.9333 82.1446 27.9923 82.0046 28.9848L101.468 29.189L101.239 28.5194V28.5241Z'
            fill='#F5F5F5'
          />
          <path
            d='M56.0047 24.295C55.4289 22.8217 54.8449 21.3288 53.8757 20.0582C52.9106 18.7875 51.4953 17.7468 49.8731 17.5558C48.247 17.3648 46.4464 18.2068 45.9152 19.6957C45.68 16.3397 45.1852 12.898 43.4374 9.98642C41.6896 7.0748 38.4251 4.78293 34.9335 4.98951C31.6406 5.1844 28.8181 7.53473 27.2527 10.3255C25.6874 13.1163 25.1481 16.3163 24.633 19.4462C23.384 17.9729 20.7481 18.4367 19.4423 19.8633C18.1405 21.2899 17.808 23.2855 17.5322 25.1681L56.4589 25.5579L56.0047 24.295Z'
            fill='#F5F5F5'
          />
          <path
            d='M61.096 56.3698C60.8067 55.591 60.5174 54.798 60.0321 54.1236C59.5468 53.4493 58.8422 52.8984 58.0302 52.7987C57.2183 52.699 56.3177 53.1454 56.0517 53.9337C55.935 52.1576 55.6877 50.334 54.8151 48.7906C53.9425 47.2473 52.3093 46.0363 50.5641 46.1455C48.9169 46.25 47.5076 47.4942 46.7237 48.9711C45.9397 50.448 45.6691 52.1433 45.4124 53.8007C44.7871 53.0219 43.4712 53.2641 42.8179 54.0239C42.1646 54.779 42.0013 55.838 41.8613 56.8305L61.3247 57.0347L61.096 56.3651V56.3698Z'
            fill='#EBEBEB'
          />
          <path
            opacity='0.2'
            d='M64.1775 48.3457C54.8702 51.9223 44.3901 47.3611 40.7715 38.1552C37.1528 28.9492 41.7691 18.5855 51.0764 15.009C60.3857 11.4305 70.8658 15.9917 74.4845 25.1995C78.1031 34.4055 73.4868 44.7692 64.1775 48.3457Z'
            fill='#B0BED6'
          />
          <path
            opacity='0.1'
            d='M60.9031 14.0901L46.7198 45.9461C46.5014 45.7826 46.2849 45.6133 46.0724 45.4402C44.7206 44.3349 43.5242 43.0253 42.5325 41.5328L54.7815 14.0162C56.8418 13.6874 58.9118 13.7243 60.9031 14.0901Z'
            fill='white'
          />
          <path
            opacity='0.1'
            d='M70.9873 19.6177L57.652 49.5647C54.8579 49.5686 52.1247 48.9284 49.6631 47.7317L64.2242 15.0273C66.7803 16.0158 69.1022 17.5764 70.9873 19.6157V19.6177Z'
            fill='white'
          />
          <path
            d='M42.741 19.6314C49.4687 11.5015 61.5879 10.3048 69.809 16.9577C78.0302 23.6107 79.2403 35.5953 72.5127 43.7252C65.7831 51.857 53.6639 53.0537 45.4427 46.4008C37.2216 39.7478 36.0114 27.7632 42.741 19.6314ZM71.2966 42.7406C77.4733 35.2762 76.3635 24.2703 68.8134 18.1603C61.2632 12.0502 50.1357 13.1516 43.9571 20.616C37.7784 28.0823 38.8882 39.0882 46.4384 45.1982C53.9885 51.3083 65.116 50.2069 71.2966 42.7406Z'
            fill='#889BBA'
          />
          <path
            d='M72.9945 42.6744C74.8894 43.7932 76.6919 45.023 78.4628 46.2917C80.2338 47.5605 81.9595 48.8837 83.6596 50.238C85.3597 51.5923 87.0244 52.9895 88.632 54.4547C89.4368 55.1863 90.2259 55.9375 90.9992 56.708C91.7705 57.4805 92.5301 58.2667 93.2424 59.1092C93.6359 59.5724 93.573 60.2651 93.1047 60.6523C92.7465 60.95 92.2507 60.9831 91.863 60.7749C90.887 60.2495 89.9583 59.6696 89.0393 59.0742C88.1224 58.4768 87.2212 57.8619 86.3377 57.2256C84.5667 55.9569 82.8528 54.6201 81.1743 53.2405C79.4959 51.8608 77.843 50.4501 76.2354 48.9848C74.6277 47.5196 73.0516 46.0154 71.5679 44.4004C71.1449 43.9392 71.1783 43.227 71.6447 42.8067C72.0244 42.4642 72.5754 42.4253 72.9965 42.6724L72.9945 42.6744Z'
            fill='#889BBA'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export {
  DeleteIcon,
  GoogleMapsTerrainIcon,
  EditIcon,
  GoogleMapsSatelliteIcon,
  GoogleMapsTrafficIcon,
  YandexIcon,
  OSMIcon,
  IdentityDocumentIcon,
  LocationCircleIcon,
  LocationSquareIcon,
  LocationCrossIcon,
  LocationStarIcon,
  LocationRhombusIcon,
  LocationTriangleIcon,
  ParkingIcon,
  HarshDrivingIcon,
  WrongLocationIcon,
  SuperFleetIcon,
  NoDataIcon,
};
