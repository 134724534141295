import { Theme } from '@mui/material';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    alignItems: 'start',
  },
  perField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconField: {
    width: 21,
    height: 21,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 3.5,
    height: 3.5,
    backgroundColor: '#808080',
    borderRadius: '50%',
  },
  note: { width: 'max-content', fontSize: 13, color: '#808080' },
  successText: (theme: Theme) => ({
    color: theme.palette.success.light,
  }),
  errorText: (theme: Theme) => ({
    color: theme.palette.error.main,
  }),
  statusIconError: (theme: Theme) => ({
    width: 17,
    height: 17,
    color: theme.palette.error.main,
  }),
  statusIconSuccess: (theme: Theme) => ({
    width: 17,
    height: 17,
    color: theme.palette.success.light,
  }),
};
