import { TableCell } from '@mui/material';

type Props = {
  count: number;
};

function Cells({ count }: Props) {
  return (
    <>
      {Array.from({ length: count }, (_, i) => i).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableCell key={i} />
      ))}
    </>
  );
}

export default Cells;
