import { ReactNode } from 'react';
import { LogicalOperator, MatchingMethod } from '../../global';
import { VehicleType } from '../../vehicles/types';
import { MonitoringTripDTO } from '../services/monitoring.service';
import { TransportFuelType } from '../../shared/types';

export type MonitoringTeam = {
  id: number;
  name: string;
  transportsCount: number;
};

export type MonitoringTransportDTO = Partial<
  MonitoringDriverDTO & MonitoringVehicleDTO
>;

export type MonitoringSlice = {
  map: {
    teams: Record<string, MonitoringTeamDTO>;
    transports: Record<string, MonitoringTransportDTO>;
  };
  trips: {
    all: Array<MonitoringTripDTO>;
    overallTrip: Omit<
      MonitoringTripDTO,
      'id' | 'deviceId' | 'transportId'
    > | null;
    tripState: MonitoringTripState;
    tripProgress: number;
    previewProgress: number;
    tripDraggedProgress: number;
    tripSpeed: number;
    loading: boolean;
  };
  trackInfo: {
    allSelected: boolean;
    teamId: number | null;
    transportId: number | null;
  };
  sidebarFilters: {
    searchResults: {
      loading: boolean;
      data: MonitoringDriverDTO[];
      total: number;
      searching: boolean;
    };
    teamIds: number[];
  };
  filter: {
    startTime: string;
    endTime: string;
    excludeWeekends: boolean;
  };
  general: MonitoringGeneralInfo;
};

export const enum MonitoringSearchOption {
  LICENSE_PLATE = 'licensePlate',
  TRANSPORT_TYPE = 'vehicleType',
  DRIVER_NAME = 'name',
  TEAM_ID = 'team:id',
  TEAM_NAME = 'team:name',
}
export type MonitoringTransportSearchParams = {
  search?: string;
  team?: string[] | null;
};

export type MonitoringDriverDTO = {
  courierId: number;
  courierName: string;
  teamId: number | null;
  teamName: string;
  transportId: number;
  transportName: string | null;
  vehicleType: string | null;
  licensePlate: string | null;
  deviceId: number | null;
  vin: string | null;
  vehicle?: MonitoringVehicleDTO;
  profileImageId: string | null;
};

export type MonitoringVehicleDTO = {
  id: number;
  name: string;
  licensePlate: string | null;
  deviceId: number | null;
  teamId: number | null;
  latitude: number;
  longitude: number;
  bearing: number;
  status: MonitoringMovementStatus;
  type: VehicleType;
  satellites: number | null;
  lastData: number | null;
  gsmSignal: number | null;
  vehicleSpeed: number | null;
  movement: number | null;
  altitude: number | null;
  externalVoltage: number | null;
  lastActive: string | null;
  deviceStatus: 0 | 1;
};

export enum MonitoringMovementStatus {
  MOVEMENT = 'MOVEMENT',
  IDLING = 'IDLING',
  STOP = 'STOP',
  PARKING = 'PARKING',
  NO_DATA = 'NO_DATA',
  NA = 'NA',
}
export interface PopOverItem {
  label: string;
  value: ReactNode;
  getIsActive?: () => boolean;
}

export interface MonitoringTeamDTO {
  id: number;
  name: string;
  total: number;
  transportIds: number[];
}

export interface MonitoringVehicleDetailedDTO {
  courierId: number;
  courierName: string;
  transportName: string;
  transportId: number;
  vehicleType: VehicleType;
  licensePlate: string | null;
  currentKilometer: number;
  latitude: number;
  longitude: number;
  movement: number;
  speed: number;
  batteryVoltage: number;
  altitude: number;
  gsmSignal: number;
  gnssStatus: number;
  profileImageId: string | null;
  lastActive: string;
  transportStatus: MonitoringMovementStatus;
  deviceStatus: 0 | 1;
  externalVoltage: number;
  satellites: number;
}
export type SignalConnectionOptions = {
  signalQuality: string;
  ratio: string;
};

export interface MonitoringUpdateDTO {
  transportId: number;
  data: Array<{
    device: {
      id: number;
    };
    id: number;
    eventId: number;
    position: {
      lat: number;
      lng: number;
      alt: number;
    };
    bearing: number;
    satellites: number | null;
    speed: number | null;
    engineRpm: number | null;
    data: {
      totalOdometer: number | null;
      gsmSignal: number | null;
      temperature: number | null;
      numberOfDtc: number | null;
      engineLoad: number | null;
      coolantTemperature: number | null;
      intakeMap: number | null;
      throttlePosition: number | null;
      fuelLevel: number | null;
      distanceSinceCodesClear: number | null;
      barometricPressure: number | null;
      ambientAirTemperature: null;
      externalVoltage: number | null;
      batteryVoltage: number | null;
      batteryCurrent: number | null;
      gnssStatus: number | null;
      dataMode: number | null;
      batteryLevel: number | null;
      tripOdometer: number | null;
      ignition: number | null;
      overSpeeding: number | null;
      greenDrivingDuration: number | null;
      vin: number | null;
    };
    greenDrivingValue: number | null;
    createdAt: string;
    status: MonitoringMovementStatus;
  }>;
}

export interface MonitoringRequestFilter {
  startDate: string;
  endDate: string;
  transportId: number;
}
export { MatchingMethod, LogicalOperator };

export enum MonitoringTripState {
  PLAYING = 'PLAYING',
  PAUSED = 'PAUSED',
  INITIAL = 'INITIAL',
}

export interface MonitoringGeneralInfo {
  avgConsumption: number | null;
  avgSpeed: number | null;
  distancePassed: number | null;
  drivingTime: number | null;
  fuelConsumption: number | null;
  humidity: string | null;
  idlingTime: number | null;
  parkingTime: number | null;
  temperature: string | null;
  fuelType: TransportFuelType;
  isLoading: boolean;
}

export type MonitoringDeviceHistoryTab = 'general' | 'notifications' | 'trips';
