import { ListResponseDTO, TableFilterDTO } from '../global';
import QueryBuilder from '../shared/utils/query-builder';
import ApiService from '../global/services/api.service';
import { RuleCondition, RuleType } from '../rules/types';
import { AlertFilterDTO } from '../alerts/types';

export interface AlertLightDTO {
  id: number;
  state: 'CLOSED' | 'OPEN';
  startTime: string;
  endTime?: string;
  rule: {
    id: number | null;
    type: RuleType | null;
    conditions: RuleCondition[] | null;
  };
  transport: {
    id: number;
    name: string;
  };
  courier?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  resolveReason?: 'CONDITION' | 'MANUAL';
}

class AlertService {
  static ENDPOINT = '/alerts';

  static async getAlerts(filters: AlertFilterDTO & TableFilterDTO) {
    const body = new QueryBuilder()
      .addLimit(filters.limit)
      .addOffset(filters.offset)
      .or({
        field: 'rule:type',
        values: filters.rule,
      });

    return ApiService.post<ListResponseDTO<AlertLightDTO>>(
      `${AlertService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }
}

export default AlertService;
