export const style = {
  userGMT: {
    display: { xs: 'flex', sm: 'none', md: 'flex' },
    alignItems: { xs: 'flex-start', sm: 'center' },
    flexDirection: { xs: 'column-reverse', sm: 'row' },
    gap: 2,
  },
  userAgentInfo: {
    display: 'flex',
    gap: 4,
  },
  divider: {
    marginLeft: 2,
    marginRight: 2,
  },
};
