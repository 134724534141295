import { Box, Divider, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MyLocation } from '@mui/icons-material';
import { MouseEvent } from 'react';
import { style } from './style';
import { monitoringTrack } from '../../slices/monitoring-slice';
import { useAppDispatch } from '../../../layout/store';
import { MonitoringMovementStatus } from '../../types';
import { WrongLocationIcon } from '../../../shared/components/Icons';
import InternetAntennaIcon from '../MonitoringSidebarIcons/InternetAntennaIcon';
import LocationIcon from '../MonitoringSidebarIcons/LocationIcon';
import MovementsIcon from '../MonitoringSidebarIcons/MovementsIcon';
import GpsAntennaIcon from '../MonitoringSidebarIcons/GpsAntennaIcon';
import DriverControl from '../DriverControl';
import Location from '../../../../assets/locationIcon.svg?component';

export type TransportMetricsDisplayProps = Partial<{
  deviceId: number | null;
  transportId: number | null;
  onClose: () => void;
  vehicleSpeed: number | null;
  latitude: number;
  longitude: number;
  gsmSignal: number | null;
  status: MonitoringMovementStatus;
  satellites: number | null;
  lastData: number | null;
}> & {
  tracked: boolean;
  inMovement: boolean;
};
function TransportMetricsDisplay(props: TransportMetricsDisplayProps) {
  const {
    deviceId,
    satellites,
    vehicleSpeed,
    gsmSignal,
    status,
    inMovement,
    onClose,
    transportId,
    tracked,
    latitude,
    longitude,
    lastData,
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  if (!deviceId) {
    return (
      <Box sx={style.listItemInfo}>
        <IconButton size='small' aria-label={t('wrongLocation')}>
          <WrongLocationIcon fontSize='small' color='action' />
        </IconButton>
      </Box>
    );
  }

  const isLocationDefined = latitude && longitude;

  const trackTransport = (e: MouseEvent<HTMLButtonElement>) => {
    onClose?.();
    e.stopPropagation();
    dispatch(monitoringTrack({ transportId }));
  };

  return (
    <Box sx={style.listItemInfo} key={deviceId}>
      <MovementsIcon
        status={status}
        inMovement={inMovement}
        vehicleSpeed={vehicleSpeed}
        lastData={lastData}
      />
      <DriverControl Icon={<Location />}>
        {(open: boolean) =>
          open ? (
            <LocationIcon
              longitude={longitude}
              latitude={latitude}
              lastData={lastData}
            />
          ) : null
        }
      </DriverControl>
      <GpsAntennaIcon satellites={satellites} lastData={lastData} />
      <InternetAntennaIcon gsmSignal={gsmSignal} lastData={lastData} />
      <Divider orientation='vertical' flexItem />
      <IconButton
        size='small'
        aria-label={t('follow')}
        onClick={trackTransport}
        disabled={!isLocationDefined}
      >
        {isLocationDefined ? (
          <MyLocation fontSize='small' color={tracked ? 'primary' : 'action'} />
        ) : (
          <WrongLocationIcon fontSize='small' color='action' />
        )}
      </IconButton>
    </Box>
  );
}

TransportMetricsDisplay.displayName = 'TransportMetricsDisplay';

export default TransportMetricsDisplay;
