import CloseIcon from '@mui/icons-material/Close';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Box } from '@mui/material';
import { ResetPasswordValidationStatus } from '../../types/password';
import { styles } from '../PasswordRequirementsChecker/styles';

type Props = {
  status: ResetPasswordValidationStatus;
};

function ValidationIconMapper({ status }: Props) {
  const icons = {
    ERROR: <CloseIcon sx={styles.statusIconError} />,
    SUCCESS: <CheckOutlinedIcon sx={styles.statusIconSuccess} />,
    PENDING: <Box sx={styles.icon} />,
  };
  return icons[status];
}
export default ValidationIconMapper;
