import { Callback } from '..';

class NotificationService {
  private subscribers: Callback[] = [];

  subscribe(cb: Callback) {
    this.subscribers.push(cb);

    return () => {
      this.subscribers = this.subscribers.filter((item) => item !== cb);
    };
  }

  showSuccess(message: string, options = {}) {
    this.subscribers.forEach((cb) => {
      cb(message, {
        variant: 'success',
        ...options,
      });
    });
  }

  showError(message: string, options = {}) {
    this.subscribers.forEach((cb) => {
      cb(message, {
        variant: 'error',
        ...options,
      });
    });
  }

  showWarning(message: string, options = {}) {
    this.subscribers.forEach((cb) => {
      cb(message, {
        variant: 'warning',
        ...options,
      });
    });
  }
}

export const NotificationApi = new NotificationService();
