import { Theme } from '@mui/material';

export const style = {
  search: {
    height: 'unset',
    width: 220,
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
    justifyContent: 'space-between',
  },
  teams: (theme: Theme) => ({
    margin: theme.spacing(3, 0),
  }),
  followButton: (theme: Theme, allSelected: boolean) => ({
    fontSize: 12,
    color: allSelected ? theme.palette.primary.main : '#808080',
  }),
};
