import { Slide, SlideProps } from '@mui/material';
import React, { forwardRef } from 'react';

/**
 * @deprecated This component shouldn't be used
 */
const SFSlide = forwardRef((props: SlideProps, ref) => (
  <Slide {...props} ref={ref} direction='left' />
));

SFSlide.displayName = 'SFSlide';

export default SFSlide;
