import { MyLocationOutlined } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../layout/store';
import { getMonitoringTrackInfoSelector } from '../../selectors';
import { monitoringFollowAll } from '../../slices/monitoring-slice';
import { style } from './style';

type Props = {
  showSidebar: () => void;
  sidebarHidden: boolean;
};

function MonitoringSidebarControl({ showSidebar, sidebarHidden }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const trackInfo = useAppSelector(getMonitoringTrackInfoSelector);
  const dispatch = useAppDispatch();

  const followAll = () => {
    dispatch(monitoringFollowAll());
  };

  if (!sidebarHidden) {
    return null;
  }

  return (
    <Box sx={style.root}>
      <ButtonGroup>
        <Button
          onMouseDown={showSidebar}
          variant='text'
          sx={style.sidebarTrigger}
        >
          {t('showSidebar')}
        </Button>
        <Divider orientation='vertical' sx={style.divider} />

        <Button
          variant='text'
          endIcon={<MyLocationOutlined />}
          sx={[
            style.followAll,
            trackInfo.allSelected
              ? style.followAllActive
              : style.followAllInactive,
          ]}
          onClick={followAll}
          size='large'
        >
          {t('all')}
        </Button>
      </ButtonGroup>
    </Box>
  );
}

MonitoringSidebarControl.displayName = 'MonitoringSidebarControl';

export default MonitoringSidebarControl;
