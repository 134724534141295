import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { startOfToday } from 'date-fns';
import { ROUTES } from '../../global';

const useMonitoringDateFilter = () => {
  const [searchParams] = useSearchParams();

  const endDateInSearchParams = searchParams.get(
    ROUTES.monitoring.searchParams.endDate,
  );
  const startDateInSearchParams = +(
    searchParams.get(ROUTES.monitoring.searchParams.startDate) ||
    startOfToday().getTime()
  );

  return useMemo(() => {
    return {
      startDate: new Date(startDateInSearchParams),
      endDate: endDateInSearchParams
        ? new Date(+endDateInSearchParams)
        : new Date(),
    };
  }, [endDateInSearchParams, startDateInSearchParams]);
};

export default useMonitoringDateFilter;
