import { useTranslation } from 'react-i18next';
import { PopOverItem } from '../../types';
import SidebarContentPopover from '../SidebarContentPopover';
import DriverControl from '../DriverControl';
import {
  getInternetAntennaTitle,
  getLastDataReceivedOption,
} from '../../utils';
import getAntennaSignalIcon from '../../utils/getAntennaSignalIcon';

type Props = {
  gsmSignal?: null | number;
  lastData?: number | null;
};
export default function InternetAntennaIcon({ gsmSignal, lastData }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const internetAntennaOptions = getInternetAntennaTitle(gsmSignal);
  const popOverItem: PopOverItem[] = [
    {
      label: t('internetAntenna'),
      value: `${t(internetAntennaOptions.signalQuality)} ${internetAntennaOptions.ratio}`,
    },
    getLastDataReceivedOption(lastData),
  ];
  const AntennaIcon = getAntennaSignalIcon({ gsmSignal });

  return (
    <DriverControl Icon={<AntennaIcon />}>
      <SidebarContentPopover items={popOverItem} />
    </DriverControl>
  );
}
