import { object, string } from 'yup';
import i18n from '../../global/utils/localization';

function getForgotPasswordValidationSchema() {
  return object().shape({
    email: string()
      .email(i18n.t('validation.email'))
      .required(i18n.t('errorMessages.required')),
  });
}
function getPasswordSettingValidationScheme() {
  return object().shape({
    password: string(),
    verifyPassword: string().test(
      'passwords-match',
      i18n.t('validation.notMatch'),
      function (value) {
        return !value || value === this.parent.password;
      },
    ),
  });
}

function getLoginValidationSchema() {
  return object().shape({
    username: string()
      .required(i18n.t('errorMessages.required'))
      .email(i18n.t('validation.email')),
    password: string().required(i18n.t('errorMessages.required')),
  });
}

export {
  getForgotPasswordValidationSchema,
  getPasswordSettingValidationScheme,
  getLoginValidationSchema,
};
