import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Path, useLocation, useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Tooltip,
  useMediaQuery,
  Theme,
  Box,
  Divider,
} from '@mui/material';
import { getNavigationItems } from '../../utils';
import AccessGuard from '../../../shared/components/AccessGuard';

import styles from './styles';
import AppBarInfo from '../AppBarInfo/AppBarInfo';

type NavigationDrawerProps = {
  collapsed: boolean;
  onToggle: () => void;
};

function NavigationDrawer({ collapsed, onToggle }: NavigationDrawerProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const NAVIGATION_ITEMS = useMemo(() => getNavigationItems(), []);

  const isActive = (path: string) =>
    new RegExp(`^\\${path}\\.*`).test(pathname);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const isDrawerOpen = isMobile ? collapsed : true;
  const areStylesCollapsed = isMobile ? false : collapsed;
  const drawerVariant = isMobile ? 'temporary' : 'permanent';

  const onListItemClick = (path: Partial<Path> | string) => {
    navigate(path);

    if (isMobile) {
      onToggle();
    }
  };

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={onToggle}
      variant={drawerVariant}
      sx={styles.drawer(areStylesCollapsed)}
      ModalProps={{ keepMounted: true }}
    >
      <List sx={styles.list}>
        {NAVIGATION_ITEMS.map((item) => (
          <AccessGuard area={item.area} key={item.id}>
            <ListItem sx={styles.listItem}>
              <Tooltip
                title={collapsed && !isMobile && t(item.labelTranslationKey)}
                placement='right'
              >
                <ListItemButton
                  sx={styles.listItemButton}
                  selected={isActive(item.fullPath)}
                  onClick={() => onListItemClick(item.path)}
                >
                  <ListItemIcon sx={styles.listItemIcon}>
                    <item.Icon sx={styles.icon} />
                  </ListItemIcon>

                  <ListItemText
                    sx={styles.listItemText(areStylesCollapsed)}
                    primary={
                      <Typography variant='subtitle2'>
                        {t(item.labelTranslationKey)}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </AccessGuard>
        ))}
      </List>
      <Box sx={styles.dividerBox}>
        <Divider sx={styles.divider} />
      </Box>
      <Box sx={[styles.list, styles.appBarBox]}>
        <AppBarInfo />
      </Box>
    </Drawer>
  );
}

export default NavigationDrawer;
