import { createTheme } from '@mui/material';
import { SFSlide } from '../../../global';

type ResponsiveTypography = {
  textScaleRatio: number;
};

declare module '@mui/material/styles' {
  interface Theme {
    responsiveTypography: ResponsiveTypography;
  }

  interface ThemeOptions {
    responsiveTypography: ResponsiveTypography;
  }
}

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1135,
      lg: 1920,
      xl: 3000,
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      'sans-serif',
    ].join(','),
    allVariants: {
      fontWeight: 500,
    },
  },
  responsiveTypography: {
    textScaleRatio: 1.2,
  },
  spacing: 4,
  components: {
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 24,
          height: 24,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& > tr > th': {
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: '17px',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& > tr:hover': {
            backgroundColor: '#E7E7E7',
          },
          '& > tr > td': {
            fontSize: 14,
            fontWeight: 400,
            height: 47,
            paddingTop: 0,
            paddingBottom: 0,
            lineHeight: '17px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 350,
          },
          '& > tr > td:has(img)': {
            textOverflow: 'unset',
          },
          border: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&&': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& > .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
          '& > .MuiDialog-container > .MuiPaper-root': {
            maxHeight: 'unset',
            height: '100%',
            margin: 0,
            boxShadow: 'unset',
            borderRadius: 0,
          },
        },
      },
      defaultProps: {
        transitionDuration: 300,
        TransitionComponent: SFSlide,
      },
    },
    MuiTablePagination: {
      defaultProps: {
        rowsPerPageOptions: [10, 20, 50],
      },
    },
  },
});

theme = createTheme(theme, {
  responsiveTypography: {
    textScaleRatio: 1.2,
  },
  typography: {
    h1: {
      fontSize: '29px',
      lineHeight: '138%',
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '37px',
        lineHeight: '44px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '50px',
        lineHeight: '60px',
      },
    },
    h2: {
      fontSize: '24px',
      lineHeight: '135%',
      fontWeight: 700,
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '29px',
        lineHeight: '138%',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '37px',
        lineHeight: '44px',
      },
    },
    h3: {
      fontSize: '21px',
      lineHeight: '135%',
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '24px',
        lineHeight: '135%',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '29px',
        lineHeight: '138%',
      },
    },
    h4: {
      fontSize: '19px',
      lineHeight: '135%',
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '21px',
        lineHeight: '135%',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '24px',
        lineHeight: '135%',
      },
    },
    h5: {
      fontSize: '18px',
      lineHeight: '135%',
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '19px',
        lineHeight: '135%',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '21px',
        lineHeight: '135%',
      },
    },
    h6: {
      fontSize: '17px',
      lineHeight: '140%',
      [theme.breakpoints.between('md', 'lg')]: {
        fontSize: '18px',
        lineHeight: '135%',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '19px',
        lineHeight: '135%',
      },
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '14px',
      lineHeight: '140%',
      fontWeight: 'normal',
    },
    body1: {
      fontSize: '12px',
      lineHeight: '100%',
      fontWeight: 400,
    },
    body2: {
      fontSize: '10px',
      lineHeight: '120%',
    },
  },
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
          borderRadius: 24,
          padding: theme.spacing(1),
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& > fieldset': {
            borderColor: '#dfe7f0',
          },
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 3),
          fontSize: theme.typography.subtitle2.fontSize,
          lineHeight: theme.typography.subtitle2.lineHeight,
          border: 'none',
          color: theme.palette.common.black,
          textTransform: 'capitalize',
          fontWeight: 600,
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '100px !important',
          },
          '&.Mui-selected:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
          },
          '&:hover': {
            borderRadius: '100px !important',
          },
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },

    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },

    MuiTabs: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            borderRadius: 5,
            background: 'rgba(0, 0, 0, 0.05)',
            boxShadow:
              '0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset',
            minHeight: 0,
            padding: 1,
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            '& .MuiTab-root': {
              borderRadius: 5,
              color: 'black',
              ...theme.typography.subtitle2,
              padding: 0,
              minHeight: 0,
              textTransform: 'none',
              '&.Mui-selected': {
                backgroundColor: '#fff',
                boxShadow:
                  '0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.15), 0px 1px 0.75px 0px rgba(0, 0, 0, 0.05)',
                color: 'black',
              },
            },
          },
        },
      ],
    },
  },
});

export default theme;
