export enum AsyncStatus {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ResponseStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export const enum MatchingMethod {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  STARTS_WITH = 'STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN = 'GREATER_THAN',
  NULL = 'NULL',
}
export const enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}
