import { Coordinate } from 'ol/coordinate';
import React, { useMemo } from 'react';
import { getVehicleIcon } from '../../../map/utils';
import { VehicleType } from '../../../shared/types';
import {
  MonitoringDriverDTO,
  MonitoringMovementStatus,
  MonitoringVehicleDTO,
} from '../../types';
import Marker from '../../../map/components/Marker';
import MonitoringVehicleRealTimeInfo from './MonitoringVehicleRealTimeInfo';

interface MonitoringVehicleMarkerProps {
  vehicle: Partial<MonitoringDriverDTO & MonitoringVehicleDTO>;
}

function MonitoringVehicleMarker({ vehicle }: MonitoringVehicleMarkerProps) {
  const position = useMemo(
    () =>
      [vehicle.latitude as number, vehicle.longitude as number] as Coordinate,
    [vehicle.latitude, vehicle.longitude],
  );

  return (
    <Marker
      position={position}
      src={(isOverlayOpen) => {
        return getVehicleIcon(
          vehicle.type as VehicleType,
          vehicle.status as MonitoringMovementStatus,
        );
      }}
      key={vehicle.id || vehicle.transportId}
      rotation={vehicle.bearing}
      label={vehicle.name}
      renderOverlay={() => (
        <MonitoringVehicleRealTimeInfo id={vehicle.id as number} />
      )}
    />
  );
}

MonitoringVehicleMarker.displayName = 'MonitoringVehicleMarker';

export default MonitoringVehicleMarker;
