import {
  ExpandMore,
  KeyboardArrowRight,
  MyLocation,
} from '@mui/icons-material';
import { Box, IconButton, List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { style } from './style';
import { useAppDispatch, useAppSelector } from '../../../layout/store';
import {
  getMonitoringDriversByIDsSelector,
  getMonitoringTeamSelector,
  getMonitoringTrackInfoSelector,
} from '../../selectors';
import { MonitoringTeamDTO } from '../../types';
import { getMonitoringDrivers } from '../../services/monitoring.service';
import MonitoringSingleTransport from '../MonitoringSingleTransport';
import SearchResultsSkeleton from '../SearchResultsSkeleton';
import { monitoringTrack } from '../../slices/monitoring-slice';
import { ROUTES } from '../../../global';

type Props = {
  teamName: string;
  id: number;
  total: number;
  onClose?: () => void;
};

function MonitoringSingleTeam({ teamName, id, total, onClose }: Props) {
  const [expanded, setExpanded] = useState(total > 1);

  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });

  const team: MonitoringTeamDTO = useAppSelector(getMonitoringTeamSelector(id));
  const transports = useAppSelector(
    getMonitoringDriversByIDsSelector(team.transportIds),
  );
  const trackInfo = useAppSelector(getMonitoringTrackInfoSelector);
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const selectedTransportID = searchParams.get(
    ROUTES.monitoring.searchParams.transportID,
  );

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (expanded && team.transportIds.length === 0) {
      dispatch(getMonitoringDrivers(id));
    }
  }, [dispatch, expanded, id, team.transportIds.length]);

  const isActive = trackInfo.teamId === id;

  const trackTeam = () => {
    dispatch(monitoringTrack({ teamId: id }));
    onClose?.();
  };

  const noDeviceAttachedToTeam = transports.every(
    (transport) => !transport.deviceId,
  );
  return (
    <Box>
      <Box sx={style.team}>
        <IconButton
          size='small'
          aria-label={t('expandMoreLess')}
          disabled={total === 0}
          onClick={toggleExpand}
        >
          {expanded ? (
            <ExpandMore sx={style.expandIcon} />
          ) : (
            <KeyboardArrowRight sx={style.expandIcon} />
          )}
        </IconButton>
        <Typography fontWeight={600} variant='body1' noWrap>
          {teamName} ({total})
        </Typography>
        <IconButton
          sx={style.locationIcon}
          size='small'
          disabled={noDeviceAttachedToTeam || total === 0}
          aria-label={t('follow')}
          onClick={trackTeam}
        >
          <MyLocation
            fontSize='small'
            color={isActive && !noDeviceAttachedToTeam ? 'primary' : 'inherit'}
          />
        </IconButton>
      </Box>
      {expanded && (
        <List sx={style.driverList}>
          {transports.length === 0 && (
            <SearchResultsSkeleton count={team.total} />
          )}
          {transports.map((transport) => (
            <MonitoringSingleTransport
              selectedTransportID={selectedTransportID}
              {...transport}
              tracked={trackInfo.transportId === transport.transportId}
              key={transport.transportId}
              onClose={onClose}
            />
          ))}
        </List>
      )}
    </Box>
  );
}

MonitoringSingleTeam.displayName = 'MonitoringSingleTeam';

export default MonitoringSingleTeam;
