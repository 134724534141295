import { AuthService } from '../../services';
import {
  IMiddleware,
  MiddlewareContext,
  NextFunction,
} from '../../global/services/middleware-manager';

export class ReauthenticateMiddleware implements IMiddleware {
  private static isRefreshing = false;

  private static maxRetries = 1;

  private readonly callbackOnFail: () => void;

  constructor(callbackOnFail: () => void) {
    this.callbackOnFail = callbackOnFail;
  }

  async onResponse(
    context: MiddlewareContext,
    next: NextFunction,
  ): Promise<void> {
    const currentRetry = context.request.options?.retrying || 0;

    try {
      await next();
      if (
        context.response?.status === 401 &&
        currentRetry < ReauthenticateMiddleware.maxRetries &&
        !ReauthenticateMiddleware.isRefreshing
      ) {
        try {
          ReauthenticateMiddleware.isRefreshing = true;
          const response = await AuthService.refreshToken();
          if (response?.ok) {
            context.request.options = {
              ...context.request.options,
              retrying: currentRetry + 1,
            };
            context.request.retrying = true;

            context.response = undefined;
          } else {
            this.callbackOnFail();
          }
        } finally {
          ReauthenticateMiddleware.isRefreshing = false;
        }
      }
    } catch (error) {
      console.error('Error in reauthenticate middleware:', error);
    }
  }
}
