import { Button, InputAdornment, TextField } from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useState } from 'react';
import { style } from './style';

export function PasswordField(props: any) {
  const [visible, setVisible] = useState<boolean>(false);
  function togglePasswordVisibility() {
    setVisible(!visible);
  }
  return (
    <TextField
      size='medium'
      sx={style.field}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Button
              sx={style.toggleButton}
              onClick={togglePasswordVisibility}
              color='inherit'
            >
              {visible ? (
                <VisibilityOffOutlinedIcon />
              ) : (
                <RemoveRedEyeOutlinedIcon />
              )}
            </Button>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
