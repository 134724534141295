import MovementsActive from '../../../assets/movements-status-icons/MovementsActive.svg?component';
import MovementsIdling from '../../../assets/movements-status-icons/MovementsIdling.svg?component';
import MovementsParking from '../../../assets/movements-status-icons/MovementsParking.svg?component';
import MovementsStop from '../../../assets/movements-status-icons/MovementsStop.svg?component';
import GSMBadSignal from '../../../assets/gsm-signal-icons/GSMBadSignal.svg?component';
import GSMNotSignal from '../../../assets/gsm-signal-icons/GSMNotSignal.svg?component';
import GSMLowSignal from '../../../assets/gsm-signal-icons/GSMLowSignal.svg?component';
import GSMNormalSignal from '../../../assets/gsm-signal-icons/GSMNormalSignal.svg?component';
import GSMGoodSignal from '../../../assets/gsm-signal-icons/GSMGoodSignal.svg?component';
import NotSignalAntenna from '../../../assets/internet-antenna-status/NotSignalAntenna.svg?component';
import BadSignalAntenna from '../../../assets/internet-antenna-status/BadSignalAntenna.svg?component';
import LowSignalAntenna from '../../../assets/internet-antenna-status/LowSignalAntenna.svg?component';
import NormalSignalAntenna from '../../../assets/internet-antenna-status/NormalSignalAntenna.svg?component';
import GoodSignalAntenna from '../../../assets/internet-antenna-status/GoodSignalAntenna.svg?component';

import { MonitoringMovementStatus } from '../types';

export const MONITORING_SIDEBAR_COLLAPSE_STATE =
  'MONITORING_SIDEBAR_COLLAPSE_STATE';

export const MONITORING_SPEED_LIST = [1, 2, 4, 8, 16, 32, 64];

export const MONITORING_DRAWER_COLLAPSED_WIDTH = 0;
export const MONITORING_DRAWER_WIDTH = 392;

export const GEOFENCE_DRAWER_COLLAPSED_WIDTH = 0;
export const GEOFENCE_DRAWER_WIDTH = 392;

export const MONITORING_GENERAL_SECTION_INITIAL_HEIGHT = 266;
export const MONITORING_TRIPS_SECTION_INITIAL_HEIGHT = 315;
export const MONITORING_NOTIFICATIONS_SECTION_INITIAL_HEIGHT = 266;

export const MONITORING_TRIPS_SECTION_PLAYER_ENABLED_HEIGHT = 186;

export const MONITORING_WRAPPER_MAX_WIDTH = 904;
export const MONITORING_RESIZABLE_MIN_HEIGHT = 110;
export const MONITORING_RESIZABLE_MAX_HEIGHT = '85vh';
export const MovementsIcons: {
  state: MonitoringMovementStatus;
  icon: any;
}[] = [
  { state: MonitoringMovementStatus.PARKING, icon: MovementsParking },
  { state: MonitoringMovementStatus.STOP, icon: MovementsStop },
  { state: MonitoringMovementStatus.IDLING, icon: MovementsIdling },
  { state: MonitoringMovementStatus.MOVEMENT, icon: MovementsActive },
];
export enum SignalStrengthLabels {
  NoSignal = 'NoSignal',
  VeryWeak = 'VeryWeak',
  Weak = 'Weak',
  Strong = 'Strong',
  VeryStrong = 'VeryStrong',
}
export const GSMSignalIcons = {
  [SignalStrengthLabels.NoSignal]: GSMNotSignal,
  [SignalStrengthLabels.VeryWeak]: GSMBadSignal,
  [SignalStrengthLabels.Weak]: GSMLowSignal,
  [SignalStrengthLabels.Strong]: GSMNormalSignal,
  [SignalStrengthLabels.VeryStrong]: GSMGoodSignal,
};

export const AntennaSignalIcons = {
  [SignalStrengthLabels.NoSignal]: NotSignalAntenna,
  [SignalStrengthLabels.VeryWeak]: BadSignalAntenna,
  [SignalStrengthLabels.Weak]: LowSignalAntenna,
  [SignalStrengthLabels.Strong]: NormalSignalAntenna,
  [SignalStrengthLabels.VeryStrong]: GoodSignalAntenna,
};
