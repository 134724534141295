import ApiService from '../global/services/api.service';
import {
  GeofenceCircle,
  GeofenceListResponseDTO,
  GeofenceRequestDTO,
} from '../geofence/types';
import { TableFilterDTO } from '../global';

export interface GeofenceLightDTO {
  id: number;
  name: string;
  description: string;
  polygon?: Array<[Array<number>]>;
  creatorId: number;
  label: string;
  circle: GeofenceCircle | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
}

export type GeofenceDTO = GeofenceLightDTO;

class GeofenceService {
  static ENDPOINT = '/geofences';

  static createGeofence(data: GeofenceRequestDTO) {
    return ApiService.post(`${GeofenceService.ENDPOINT}`, data);
  }

  static getGeofences(filter: Partial<TableFilterDTO> = {}) {
    const filters = {
      limit: filter.limit ? String(filter.limit) : '10',
      offset: filter.offset ? String(filter.offset) : '0',
    };
    return ApiService.get<GeofenceListResponseDTO>(
      `${GeofenceService.ENDPOINT}`,
      filters,
    );
  }

  static deleteGeofence(id: string) {
    return ApiService.delete(`${GeofenceService.ENDPOINT}/${id}`);
  }

  static getGeofenceById(id: string) {
    return ApiService.get<GeofenceDTO>(`${GeofenceService.ENDPOINT}/${id}`);
  }

  static updateGeofence(id: string, data: GeofenceRequestDTO) {
    return ApiService.patch(`${GeofenceService.ENDPOINT}/${id}`, data);
  }
}

export default GeofenceService;
