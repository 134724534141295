import { ListDTO } from '../../global';
import { VehicleDTO, VehicleLightDTO } from '../../shared/types';

export type TeamDTO = {
  description: string;
  transportsCount: number;
  id: number;
  name: string;
  transports: Array<VehicleDTO>;
};

export type CourierDTO = {
  courierId: number;
  courierName: string;
  licensePlate: string;
  teamId: number;
  teamName: string;
  transportId: number;
  transportName: string;
  vehicleType: string;
};

export type TeamList = ListDTO<TeamDTO>;

export type TeamFormDTO = {
  transports: Partial<VehicleLightDTO>[];
  name: string;
  description: string;
};

export type TeamFormModal = {
  open: boolean;
  id: number | null;
};

export const enum SearchOption {
  NAME = 'name',
  DESCRIPTION = 'description',
}
