import { VehicleDTO } from '../../shared/types';

export const enum DeviceSearchOption {
  NAME = 'name',
  IMEI = 'imei',
  MODEL = 'model',
  PHONE = 'phone',
}

export interface DeviceFormDTO {
  name: string;
  imei: string;
  model: DeviceModel;
  transports: null | VehicleDTO;
  phone: string;
}

export enum DeviceModel {
  FMB002 = 'FMB002',
  FMB003 = 'FMB003',
  FMB910 = 'FMB910',
  FMB920 = 'FMB920',
  FMP100 = 'FMP100',
}

export const enum SearchOption {
  NAME = 'name',
  PHONE = 'phone',
}
