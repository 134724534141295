import { VehicleLightDTO, VehicleType } from '../../shared/types';
import { DriverDTO } from '../../services/driver.service';
import { UserLightDTO } from '../../services/user.service';

export enum RuleType {
  SPEED_LIMIT = 'SPEED_LIMIT',
  STOP_TIME = 'STOP_TIME',
  RPM = 'RPM',
  TEMPERATURE = 'TEMPERATURE',
  GEOFENCE = 'GEOFENCE',
}

export enum RuleConditionOperator {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  EQUAL = 'EQUAL',
  ENTERING = 'ENTERING',
  LEAVING = 'LEAVING',
}
export enum RuleSearchOption {
  TYPE = 'type',
  ENABLED = 'enabled',
  COURIER = 'courier:id',
  VEHICLE = 'transport:id',
}

export type RuleFilterDTO = {
  rule: string[];
  status: string | null;
  driverID: number | null;
  vehicleID: number | null;
  limit?: number;
  offset?: number;
};
export type RuleListFormDTO = {
  rule: string[];
  driver: DriverDTO | null;
  transport: VehicleLightDTO | null;
  status: boolean;
};
export enum StatusMode {
  ALL = 'all',
  ENABLED = 'true',
  DISABLED = 'false',
}
export enum RuleNotificationTechnicalType {
  IN_APP = 'IN_APP',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export interface RuleNotificationType {
  notificationType: RuleNotificationTechnicalType;
  sendImmediately: boolean;
}

export interface RuleCondition {
  operator: RuleConditionOperator;
  value: number;
  id: string;
}

export interface RuleDTO {
  id: number;
  type: string;
  enabled: boolean;
  firingDuration: number;
  firingUnit: string;
  resolvingDuration: number;
  resolvingUnit: string;
  conditions?: Array<{
    value: number;
    operator: RuleConditionOperator;
    id: string;
  }>;
  recipients: Array<{
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  }>;
  transports?: RuleTransportDTO[];
  teams?: RuleTeamDTO[];
  geofences: Array<RuleGeofenceDTO> | null;
  notificationSettings: Array<RuleNotificationType>;
}

export interface RuleFormDTO {
  id?: number;
  enabled?: boolean;
  type: string;
  firingDuration: string;
  firingUnit: string;
  resolvingDuration: string;
  resolvingUnit: string;
  recipients: Array<UserLightDTO>;
  geofence: RuleGeofenceDTO | null;
  notifications: string[];
  notificationTypes: {
    [key: string]: { sendImmediately: boolean | undefined } | undefined;
  };
  conditions?: Array<{
    value?: number;
    operator?: RuleConditionOperator;
    id: string;
  }>;
  access: {
    teamIds: Array<number>;
    transportIds: Array<number>;
  };
}

export type RuleEditModal = {
  open: boolean;
  id: number;
};
export interface RuleRequestDTO {
  type: string;
  firingDuration: number;
  firingUnit: string;
  resolvingDuration: number;
  resolvingUnit: string;
  conditions:
    | Array<{
        value: number;
        operator: RuleConditionOperator;
      }>
    | null
    | undefined;
  transports: number[];
  teams?: number[];
  geofence: RuleGeofenceDTO | null;
  notificationSettings: Array<RuleNotificationType>;
  recipients: number[];
  enabled?: boolean;
}

export interface RuleTransportDTO {
  vehicleType: VehicleType;
  id: number;
  name: string;
  licensePlate: string;
}

export interface RuleTeamDTO {
  id: number;
  name: string;
  type: string;
}

export interface RuleUserDTO {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface RuleGeofenceDTO {
  id: number;
  name: string;
  label: string;
  exclude?: boolean;
}

export enum DurationUnit {
  SECOND = 's',
  MINUTE = 'm',
}
