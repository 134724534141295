import AuthLayout from '../AuthLayout';
import ForgotPasswordForm from '../ForgotPasswordForm';

function ForgotPassword() {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
}

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;
