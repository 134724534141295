export const style = {
  mapWrapper: {
    height: '100%',
    width: 400,
    flex: 1,
    position: 'relative',
  },
  map: {
    height: '100%',
    width: '100%',
  },
  yandexMap: {
    width: 400,
    flex: 1,
  },
};
