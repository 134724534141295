import { Theme } from '@mui/material';

const style = {
  root: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      height: '100%',
      paddingBottom: 0.5,
      flexDirection: 'column',
    },
    display: 'flex',
    height: '100%',
  }),
  contentBox: (theme: Theme) => ({
    flex: 1,
    padding: 5,
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      pt: 12,
    },
  }),

  content: (theme: Theme) => ({
    margin: 'auto',
    padding: theme.spacing(12, 14),
    maxWidth: '100%',
    width: {
      xs: '100%',
      sm: 462,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    },
  }),

  imageBoxDesktop: {
    minWidth: 720,
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderTopLeftRadius: '4vw',
    borderBottomLeftRadius: '4vw',
    display: { xs: 'none', md: 'block' },
  },

  logoBox: {
    position: 'absolute',
    top: 0,
    width: '100%',
    mt: 18,
    display: 'flex',
    justifyContent: 'center',
  },
  illustration: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'bottom center',
  },

  imageBoxMobile: {
    position: 'relative',
    display: { xs: 'flex', sm: 'none' },
    height: '230px',
    borderBottomRightRadius: '10vw',
    borderBottomLeftRadius: '10vw',
    overflow: 'hidden',
    flexShrink: 0,
  },
  logoBoxMobile: { mt: 0, inset: 0, overflow: 'hidden' },
  illustrationMobile: {
    width: 250,
    px: 4,
    maxWidth: '100%',
  },
};

export default style;
