import { ReactNode } from 'react';
import { Box } from '@mui/material';
import superFleet from '../../../../assets/superfleetWhite.svg';
import authDesktopBg from '../../../../assets/auth-desktop-bg.png';
import authMobileBg from '../../../../assets/auth-mobile-bg.png';
import style from './style';

type AuthLayoutProps = {
  children: ReactNode;
};

function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <Box sx={style.root}>
      <Box sx={style.imageBoxMobile}>
        <Box sx={[style.logoBox, style.logoBoxMobile]}>
          <Box
            component='img'
            src={superFleet}
            sx={style.illustrationMobile}
            alt='Superfleet icon'
          />
        </Box>
        <Box
          component='img'
          src={authMobileBg}
          sx={style.illustration}
          alt='Superfleet illustration'
        />
      </Box>

      <Box sx={style.contentBox}>
        <Box sx={style.content}>{children}</Box>
      </Box>

      <Box sx={style.imageBoxDesktop}>
        <Box sx={style.logoBox}>
          <Box
            component='img'
            src={superFleet}
            width={310}
            alt='Superfleet icon'
          />
        </Box>
        <Box
          component='img'
          src={authDesktopBg}
          sx={style.illustration}
          alt='Superfleet illustration'
        />
      </Box>
    </Box>
  );
}

export default AuthLayout;
