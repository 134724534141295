export const style = {
  schedule: {
    display: 'flex',
    gap: 1,
  },
  day: (size: 'S' | 'L') =>
    size === 'L'
      ? {
          width: 44,
          height: 40,
          border: '2px solid rgba(204, 204, 204, 1)',
          background: '#fff',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          '&:hover': {
            background: 'rgba(39, 110, 241, 0.1)',
          },
        }
      : {
          background: '#F5F5F5',
          borderRadius: '50%',
          width: 32,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          '&:hover': {
            background: 'rgba(39, 110, 241, 0.1)',
          },
        },
  dayLabel: {
    fontWeight: 'bold',
  },
  selected: {
    background: 'rgba(39, 110, 241, 0.1)',
    border: '2px solid rgb(39, 110, 241)',
  },
};
