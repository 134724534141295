import { Geometry } from 'ol/geom';
import { getLength } from 'ol/sphere';
import VectorSource from 'ol/source/Vector';
import { Draw } from 'ol/interaction';
import { Overlay, MapEvent } from 'ol';
import { unByKey } from 'ol/Observable';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { EventsKey } from 'ol/events';
import { SFMapType } from './map.service';
import './measure.css';

class SFMeasure {
  instance: SFMapType;

  measureTooltipElement: null | HTMLElement;

  measureTooltip: null | Overlay;

  measureTooltipElements: HTMLElement[] = [];

  constructor(instance: SFMapType) {
    this.instance = instance;
    this.measureTooltipElement = null;
    this.measureTooltip = null;
  }

  static formatLength(line: Geometry) {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = `${Math.round((length / 1000) * 100) / 100} km`;
    } else {
      output = `${Math.round(length * 100) / 100} m`;
    }
    return output;
  }

  enableMeasureTool() {
    const source = new VectorSource();
    const draw = new Draw({
      source,
      type: 'LineString',
      style: new Style({
        stroke: new Stroke({
          color: '#FF7009',
          width: 2,
          lineDash: [10, 10],
        }),
      }),
    });
    const vector = new VectorLayer({
      source,
      style: new Style({
        fill: new Fill({
          color: '#0F6EA4',
        }),
        stroke: new Stroke({
          color: '#FF7009',
          width: 5,
        }),
      }),
    });
    this.instance.map.addInteraction(draw);
    this.instance.map.addLayer(vector);
    let measureTooltipCleanup = () => {};
    measureTooltipCleanup = this.createMeasureTooltip().cleanup;
    let listener: null | EventsKey = null;
    let sketch = null;

    //  eslint-disable-next-line @typescript-eslint/no-explicit-any
    const showLength = (event: any) => {
      sketch = event.feature;
      let tooltipCoordinates = event.coordinate;

      listener = sketch.getGeometry().on('change', (evt: MapEvent) => {
        const geom = evt.target;
        const output = SFMeasure.formatLength(geom);
        tooltipCoordinates = geom.getLastCoordinate();
        if (this.measureTooltipElement) {
          this.measureTooltipElement.innerText = output;
        }
        if (this.measureTooltip) {
          this.measureTooltip.setPosition(tooltipCoordinates);
        }
      });
    };

    const drawEnd = () => {
      this.measureTooltipElement!.className = 'ol-tooltip ol-tooltip-static';
      this.measureTooltip!.setOffset([0, -7]);
      sketch = null;
      this.measureTooltipElement = null;
      measureTooltipCleanup = this.createMeasureTooltip().cleanup;
      unByKey(listener as EventsKey);
    };

    draw.on('drawstart', showLength);

    draw.on('drawend', drawEnd);

    const cleanup = () => {
      measureTooltipCleanup();
      draw.removeEventListener('drawstart', showLength);
      draw.removeEventListener('drawend', drawEnd);
      this.instance.map.removeInteraction(draw);
      this.instance.map.removeLayer(vector);
    };

    return { cleanup };
  }

  createMeasureTooltip() {
    if (this.measureTooltipElement) {
      const parent = this.measureTooltipElement.parentNode;
      if (parent) {
        parent.removeChild(this.measureTooltipElement);
      }
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
    });
    this.instance.map.addOverlay(this.measureTooltip);
    this.measureTooltipElements.push(this.measureTooltipElement);

    const cleanup = () => {
      if (this.measureTooltip) {
        this.instance.map.removeOverlay(this.measureTooltip);
      }
      this.measureTooltipElements.forEach((element) => {
        const parent = element.parentNode;
        if (parent) {
          parent.removeChild(element);
        }
      });
      this.measureTooltipElement = null;
      this.measureTooltip = null;
      this.measureTooltipElements = [];
    };
    return { cleanup };
  }
}

export default SFMeasure;
