import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  endOfToday,
  startOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  subWeeks,
} from 'date-fns';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ValidationError } from 'yup';
import { useSearchParams } from 'react-router-dom';
import { getFormattedDate } from '../../../shared/utils';
import { dateRangeDescription } from '../../utils';
import { style } from './style';
import { useAppSelector } from '../../../layout/store';
import { getMonitoringFilterSelector } from '../../selectors';
import { getMonitoringTimePickerValidationSchema } from '../../utils/validation';
import { NotificationApi, ROUTES } from '../../../global';
import useMonitoringDateFilter from '../../hooks/useMonitoringDateFilter';

const DatePickerLazy = lazy(async () => {
  await import('react-datepicker/dist/react-datepicker.css');
  return import(/* webpackChunkName: "date-picker" */ 'react-datepicker');
});

type Props = {
  onClose?: () => void;
};
function MonitoringDateRangePicker({ onClose }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const filter = useAppSelector(getMonitoringFilterSelector);
  const [, setSearchParams] = useSearchParams();

  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    useMonitoringDateFilter();

  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);
  const [advanced, setAdvanced] = useState(false);
  const [startTime, setStartTime] = useState(filter.startTime);
  const [endTime, setEndTime] = useState(filter.endTime);
  const [errors, setErrors] = useState({
    startTime: '',
    endTime: '',
  });

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    if (start) {
      setStartDate(start as Date);
    }
    setEndDate(end as Date);
  };

  const onApply = async () => {
    setErrors({ startTime: '', endTime: '' });
    if (advanced) {
      try {
        await getMonitoringTimePickerValidationSchema().validate({
          startTime,
          endTime,
        });
      } catch (e) {
        if (e instanceof ValidationError && e.path) {
          setErrors({ ...errors, [e.path]: e.message });
        }
        return;
      }
    }
    if (!endDate || !startDate) {
      NotificationApi.showWarning(t('selectDateRange'));
      return;
    }
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const finalStartDate = new Date(startDate);
    finalStartDate.setHours(startHours, startMinutes, 0, 0);

    const finalEndDate = new Date(endDate);
    finalEndDate.setHours(endHours, endMinutes, 0, 0);

    setSearchParams((params) => {
      const sp = new URLSearchParams(params);
      sp.set(
        ROUTES.monitoring.searchParams.startDate,
        finalStartDate.getTime().toString(),
      );
      sp.set(
        ROUTES.monitoring.searchParams.endDate,
        finalEndDate.getTime().toString(),
      );
      return sp;
    });
    onClose?.();
  };

  const handleTabChange = (value: string) => {
    const today = new Date();

    if (value === 'custom') {
      return;
    }

    if (value === 'today') {
      setStartDate(startOfToday());
      setEndDate(endOfToday());
    }
    if (value === 'yesterday') {
      setStartDate(startOfYesterday());
      setEndDate(endOfYesterday());
    }
    if (value === 'lastWeek') {
      setStartDate(startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }));
      setEndDate(endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }));
    }
    if (value === 'lastMonth') {
      const lastMonthDate = subMonths(today, 1);
      setStartDate(startOfMonth(lastMonthDate));
      setEndDate(endOfMonth(lastMonthDate));
    }
  };

  const toggleAdvanced = () => setAdvanced(!advanced);

  const currentTab = dateRangeDescription(startDate, endDate);

  return (
    <Box>
      <Box sx={style.content}>
        <ButtonGroup orientation='vertical' size='large'>
          <Button
            sx={style.tab(currentTab === 'custom')}
            onClick={() => handleTabChange('custom')}
          >
            {t('dateRangePickerOptions.custom')}
          </Button>
          <Button
            sx={style.tab(currentTab === 'today')}
            onClick={() => handleTabChange('today')}
          >
            {t('dateRangePickerOptions.today')}
          </Button>
          <Button
            sx={style.tab(currentTab === 'yesterday')}
            onClick={() => handleTabChange('yesterday')}
          >
            {t('dateRangePickerOptions.yesterday')}
          </Button>
          <Button
            sx={style.tab(currentTab === 'lastWeek')}
            onClick={() => handleTabChange('lastWeek')}
          >
            {t('dateRangePickerOptions.lastWeek')}
          </Button>
          <Button
            sx={style.tab(currentTab === 'lastMonth')}
            onClick={() => handleTabChange('lastMonth')}
          >
            {t('dateRangePickerOptions.lastMonth')}
          </Button>
        </ButtonGroup>
        <Box sx={style.dateRangePicker}>
          <Box sx={style.header}>
            <Box sx={style.dates}>
              <TextField
                label={t('startDate')}
                value={getFormattedDate(startDate as Date)}
                name='startDate'
                size='small'
              />
              <TextField
                label={t('endDate')}
                value={getFormattedDate(endDate as Date)}
                name='endDate'
                size='small'
              />
            </Box>
            {advanced && (
              <Box sx={{ marginTop: 4 }}>
                <Box sx={style.dates}>
                  <TextField
                    label={t('startTime')}
                    name='startDateTime'
                    size='small'
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    helperText={errors.startTime}
                    error={!!errors.startTime}
                  />
                  <TextField
                    label={t('endTime')}
                    name='endDateTime'
                    size='small'
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    helperText={errors.endTime}
                    error={!!errors.endTime}
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t('excludeWeekends')}
                  />
                </Box>
              </Box>
            )}
            <Box>
              <Button
                endIcon={advanced ? <ExpandLess /> : <ExpandMore />}
                onClick={toggleAdvanced}
                sx={{ fontWeight: 400 }}
              >
                {t('advanced')}
              </Button>
            </Box>
          </Box>
          <Suspense fallback={null}>
            <DatePickerLazy
              selected={startDate}
              onChange={onChange}
              startDate={startDate as Date}
              endDate={endDate as Date}
              selectsRange
              inline
            />
          </Suspense>
        </Box>
      </Box>
      <Box sx={style.footer}>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button onClick={onApply}>{t('apply')}</Button>
      </Box>
    </Box>
  );
}

MonitoringDateRangePicker.displayName = 'MonitoringDateRangePicker';

export default MonitoringDateRangePicker;
