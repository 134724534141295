import { useEffect } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { FilterList, MyLocationOutlined, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { Field, useToggle } from '../../../global';
import { getMonitoringTransportsByTerm } from '../../services/monitoring.service';
import { style } from './style';
import {
  monitoringFilterByTeams,
  monitoringFollowAll,
  monitoringResetSearch,
  monitoringSearchTransport,
} from '../../slices/monitoring-slice';
import { useAppDispatch, useAppSelector } from '../../../layout/store';
import TeamsAutocomplete from '../../../shared/components/TeamsAutocomplete';
import { TeamDTO } from '../../../teams/types';
import { MonitoringTransportSearchParams } from '../../types';
import { getMonitoringTrackInfoSelector } from '../../selectors';

type MonitoringFilterProps = {
  onClose?: () => void;
};

function MonitoringFilter(props: MonitoringFilterProps) {
  const { onClose } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const dispatch = useAppDispatch();

  const trackInfo = useAppSelector(getMonitoringTrackInfoSelector);

  const methods = useForm<{ term: string; teams: TeamDTO[] }>({
    defaultValues: { term: '', teams: [] },
  });

  const [search, teams] = useWatch({
    control: methods.control,
    name: ['term', 'teams'],
  });

  const { toggle, open } = useToggle();
  const debouncedEffect = useDebouncedCallback(() => {
    if (search || teams.length) {
      const data: MonitoringTransportSearchParams = {};
      if (teams.length) {
        data.team =
          teams.length > 1
            ? teams.map((team) => {
                return String(team.id);
              })
            : [String(teams[0].id)];
      }
      if (search) {
        data.search = search;
      }
      dispatch(getMonitoringTransportsByTerm(data));
    }
  }, 300);

  const followAll = () => {
    dispatch(monitoringFollowAll());
    onClose?.();
  };

  useEffect(() => {
    debouncedEffect();
  }, [debouncedEffect, search]);

  useEffect(() => {
    if (search) {
      dispatch(monitoringSearchTransport());
    } else {
      dispatch(monitoringResetSearch());
    }
  }, [dispatch, search]);

  useEffect(() => {
    dispatch(monitoringFilterByTeams(teams.map((team) => team.id)));
  }, [dispatch, teams]);

  return (
    <FormProvider {...methods}>
      <Box>
        <Box sx={style.filter}>
          <Field name='term'>
            <TextField
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder={t('searchDrivers')}
              sx={style.search}
            />
          </Field>
          <IconButton onClick={toggle}>
            <FilterList />
          </IconButton>
          <Button
            variant='text'
            endIcon={<MyLocationOutlined />}
            sx={(theme) => style.followButton(theme, trackInfo.allSelected)}
            onClick={followAll}
            size='large'
          >
            {t('all')}
          </Button>
        </Box>
        <Collapse in={open}>
          <Box sx={style.teams}>
            <TeamsAutocomplete name='teams' multiple />
          </Box>
        </Collapse>
      </Box>
    </FormProvider>
  );
}

MonitoringFilter.displayName = 'MonitoringFilter';

export default MonitoringFilter;
