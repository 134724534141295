import {
  NAV_DRAWER_COLLAPSED_WIDTH,
  NAV_DRAWER_WIDTH,
} from '../../../shared/utils/constants';

const styles = {
  drawer: (collapsed: boolean) => ({
    width: collapsed ? NAV_DRAWER_COLLAPSED_WIDTH : NAV_DRAWER_WIDTH,
    transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1)',
    flexShrink: 0,
    zIndex: 9,
    '& .MuiDrawer-paper': {
      height: 'calc(100vh - 60px)',
      mt: { sm: '62px', xs: '61px' },
      width: collapsed ? NAV_DRAWER_COLLAPSED_WIDTH : NAV_DRAWER_WIDTH,
      transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1)',
      boxSizing: 'border-box',
      overflowX: 'hidden',
    },
  }),
  list: { py: 4, px: 2, gap: 2 },
  listItem: { p: 0, my: 1 },
  listItemButton: {
    py: 1.5,
    px: 2,
    borderRadius: 2,
    color: 'black',
    flexShrink: 0,
    '&.Mui-selected': {
      backgroundColor: '#E7E7E7',
    },
  },
  listItemIcon: { minWidth: 'auto', color: '#323232' },
  icon: {
    width: 20,
    height: 20,
    minWidth: 'auto',
    padding: 0,
    margin: 0,
  },
  listItemText: (collapsed: boolean) => ({
    display: collapsed ? 'none' : 'initial',
    p: 0,
    m: 0,
    ml: 3,
    whiteSpace: 'nowrap',
  }),
  dividerBox: { display: { xs: 'block', sm: 'none' }, px: 4 },
  divider: { p: 0 },
  appBarBox: {
    display: { xs: 'flex', sm: 'none' },
    px: 4,
    flexDirection: 'column',
  },
};

export default styles;
