export const style = {
  locationsIcon: (position: string, backgroundColor: string) => {
    return {
      position,
      backgroundColor,
      width: 21,
      height: 21,
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px white solid',
      left: 26,
      bottom: 9,
    };
  },
};
