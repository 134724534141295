import {
  IMiddleware,
  MiddlewareContext,
  NextFunction,
} from '../middleware-manager';

export class CacheMiddleware implements IMiddleware {
  private cache = new Map<string, { data: any; timestamp: number }>();

  constructor(private readonly ttl: number) {
    this.ttl = ttl;
  }

  shouldNotCache(context: MiddlewareContext) {
    return !context.request.options?.cache || context.request.retrying;
  }

  async onRequest(
    context: MiddlewareContext,
    next: NextFunction,
  ): Promise<void> {
    if (this.shouldNotCache(context)) {
      await next();
      return Promise.resolve();
    }

    const cacheKey = context.request.url;
    const cached = this.cache.get(cacheKey);

    if (cached && Date.now() - cached.timestamp < this.ttl) {
      context.response = cached.data;
      return Promise.resolve();
    }

    await next();

    return Promise.resolve();
  }

  async onResponse(
    context: MiddlewareContext,
    next: NextFunction,
  ): Promise<void> {
    if (this.shouldNotCache(context)) {
      await next();
      return Promise.resolve();
    }

    const cacheKey = context.request.url;

    if (context.response?.ok) {
      this.cache.set(cacheKey, {
        data: context.response,
        timestamp: Date.now(),
      });
    }

    return Promise.resolve();
  }
}
