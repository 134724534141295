/**
 * Superfleet.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/23/2023
 * All Rights Reserved.
 */

import {
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { Vector } from 'ol/layer';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import BaseEvent from 'ol/events/Event';
import { Coordinate } from 'ol/coordinate';
import { SFMap } from '../../services';

type Props = {
  fillColor: string;
  strokeColor: string;
  onShapeChange: Dispatch<SetStateAction<Coordinate[][] | null>>;
  paths?: Coordinate[][];
};

function EditableGeofence({
  fillColor,
  strokeColor,
  onShapeChange,
  paths,
}: Props) {
  const modifyRef = useRef<((style: Style) => void) | null>(null);
  const vectorRef = useRef<Vector<VectorSource<Feature<Polygon>>> | null>(null);

  const shapeChange = useCallback(
    (e: BaseEvent | Event) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const modifiedFeatures = e.features.getArray();
      if (modifiedFeatures.length > 0) {
        const modifiedFeature = modifiedFeatures[0];
        const polygonGeometry = modifiedFeature.getGeometry();
        if (polygonGeometry instanceof Polygon) {
          const coordinates = polygonGeometry.getCoordinates();
          onShapeChange(coordinates);
        }
      }
    },
    [onShapeChange],
  );

  useEffect(() => {
    const { cleanup, changeModifyStyle, vectorLayer } =
      SFMap.drawEditablePolygon(shapeChange, paths);
    modifyRef.current = changeModifyStyle;
    vectorRef.current = vectorLayer;
    return cleanup;
  }, [paths, shapeChange]);

  useEffect(() => {
    if (modifyRef.current && vectorRef.current) {
      modifyRef.current(
        new Style({
          stroke: new Stroke({
            color: strokeColor,
          }),
          fill: new Fill({
            color: fillColor,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: strokeColor,
            }),
          }),
        }),
      );
      vectorRef.current.setStyle(
        new Style({
          stroke: new Stroke({
            color: strokeColor,
          }),
          fill: new Fill({
            color: fillColor,
          }),
        }),
      );
    }
  }, [fillColor, strokeColor]);

  return null;
}

EditableGeofence.displayName = 'EditableGeofence';

export default EditableGeofence;
