import { ApiRequest, ApiResponse } from './api.service';

export interface IHttpClient {
  request<T>(config: ApiRequest): Promise<ApiResponse<T>>;
}

export class FetchHttpClient implements IHttpClient {
  constructor(private readonly baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async request<T>(config: ApiRequest): Promise<ApiResponse<T>> {
    const { url, ...init } = config;
    const response = await fetch(`${this.baseUrl}${url}`, init);
    return this.parseResponse<T>(response);
  }

  parseData<T>(response: Response, data: any): ApiResponse<T> {
    if (Array.isArray(data?.data)) {
      return { data: data.data, ok: response.ok };
    }

    return {
      data: data?.data || {},
      error: data?.error,
      ok: response.ok,
      status: response.status,
    };
  }

  private async parseResponse<T>(response: Response): Promise<ApiResponse<T>> {
    try {
      const data = await response.json();
      return this.parseData(response, data);
    } catch {
      return this.parseData(response, undefined);
    }
  }
}
