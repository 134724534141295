import landingPage from '../../../../assets/landingPageBackground.png';

export const styles = {
  typography: {
    fontSize: 16,
    fontWeight: 400,
    color: '#2D3748',
  },
  typographyPasswordTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: '#4A5568',
  },
  backNote: {
    fontSize: 10,
    fontWeight: 300,
    color: '#FFF',
  },
  screen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '911px',
    height: '527px',
    boxShadow: '0 0 10px -4px',
    borderRadius: 4,
    padding: 2,
    alignItems: 'center',
  },
  main: {
    width: '100%',
    height: 416,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-Start',
    padding: 14,
    paddingTop: 0,
    paddingBottom: 0,
    gap: 5,
  },
  note: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'spaceBetween',
    paddingBottom: 2,
    gap: '1px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'spaceBetween',
    gap: 4,
  },
  fieldSx: {
    height: 50,
  },
  perField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2.7,
  },
  rightBackground: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${landingPage})`,
    backgroundSize: 'cover',
    position: 'relative',
    borderRadius: '0px 12px 12px 0px',
  },
  position: { position: 'absolute', left: '7%', top: '20%' },
};
