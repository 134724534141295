import { Box, IconButton, List, Typography } from '@mui/material';
import { useMemo } from 'react';
import { ExpandLess } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../layout/store';
import {
  getMonitoringFilteredTeamsSelector,
  getMonitoringIsSearchingSelector,
  getMonitoringSearchResultsSelector,
  getMonitoringTeamsSelector,
  getMonitoringTrackInfoSelector,
} from '../../selectors';
import { style } from './style';
import MonitoringSingleTeam from '../MonitoringSingleTeam';
import MonitoringSingleTransport from '../MonitoringSingleTransport';
import SearchResultsSkeleton from '../SearchResultsSkeleton';

type MonitoringTeamsProps = {
  onClose?: () => void;
};

function MonitoringTeams(props: MonitoringTeamsProps) {
  const { onClose } = props;
  const teams = useAppSelector(getMonitoringTeamsSelector);

  const selectedTeamIDs = useAppSelector(getMonitoringFilteredTeamsSelector);

  const searchResults = useAppSelector(getMonitoringSearchResultsSelector);

  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });

  const teamsToDraw = useMemo(() => {
    if (selectedTeamIDs.length) {
      return teams
        .filter(({ id }) => selectedTeamIDs.includes(id))
        .sort((a, b) => b.total - a.total);
    }
    return teams.sort((a, b) => b.total - a.total);
  }, [selectedTeamIDs, teams]);

  const trackInfo = useAppSelector(getMonitoringTrackInfoSelector);

  const showSearchResults = useAppSelector(getMonitoringIsSearchingSelector);

  return (
    <Box sx={style.teams}>
      {showSearchResults && (
        <Box>
          <Box sx={style.team}>
            <IconButton size='small' aria-label={t('expandMoreLess')} disabled>
              <ExpandLess sx={style.expandIcon} />
            </IconButton>
            <Typography fontWeight={600} variant='body1'>
              {t('results')}
              {searchResults.total ? `(${searchResults.total})` : null}
            </Typography>
          </Box>
          <List sx={style.driverList}>
            {searchResults.loading && <SearchResultsSkeleton />}
            {searchResults.data.map((driver) => (
              <MonitoringSingleTransport
                tracked={trackInfo.transportId === driver.transportId}
                {...driver}
                key={driver.courierId}
                onClose={onClose}
              />
            ))}
          </List>
        </Box>
      )}
      {showSearchResults
        ? null
        : teamsToDraw.map(({ name, id, total }) => (
            <MonitoringSingleTeam
              teamName={name}
              id={id}
              total={total}
              key={id}
              onClose={onClose}
            />
          ))}
    </Box>
  );
}

MonitoringTeams.displayName = 'MonitoringTeams';

export default MonitoringTeams;
