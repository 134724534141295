type ParamValue = string | number | undefined;

const toggleSearchParam = (
  params: URLSearchParams,
  key: string,
  value: ParamValue,
): URLSearchParams => {
  const sp = new URLSearchParams(params);
  const stringValue = String(value);

  if (sp.get(key) === stringValue || value === undefined) {
    sp.delete(key);
  } else {
    sp.set(key, stringValue);
  }

  return sp;
};

export default toggleSearchParam;
