import { useEffect, useState } from 'react';

interface BeforeInstallPromptEvent extends Event {
  prompt(): Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

const usePWAInstallable = () => {
  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener(
      'beforeinstallprompt',
      handleBeforeInstallPrompt as any,
    );

    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstallable(false);
    }

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt as any,
      );
    };
  }, []);

  const promptToInstall = async () => {
    if (!deferredPrompt) {
      return { outcome: 'unavailable' as const };
    }

    deferredPrompt.prompt();

    const choiceResult = await deferredPrompt.userChoice;

    setDeferredPrompt(null);
    setIsInstallable(false);

    return { outcome: choiceResult.outcome };
  };

  return {
    isInstallable,
    promptToInstall,
  };
};

export default usePWAInstallable;
