import { ReactElement } from 'react';
import { ApplicationAction, ApplicationArea } from '../../types';
import usePermission from '../../hooks/usePermission';

type Props = {
  area: ApplicationArea;
  children: ReactElement;
  permissions?: ApplicationAction[];
};

function AccessGuard({
  area,
  children,
  permissions = [ApplicationAction.EDIT, ApplicationAction.VIEW],
}: Props): ReactElement | null {
  const { permission } = usePermission(area);

  if (permissions.includes(permission)) {
    return children;
  }

  return null;
}

AccessGuard.displayName = 'AccessGuard';

export default AccessGuard;
