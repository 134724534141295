export const style = {
  button: {
    background: 'white',
    borderRadius: '8px',
    '&:hover': { background: 'white' },
    height: '40px',
    minWidth: '40px',
    padding: 0,
    '&&': {
      boxShadow: '0px 2px 8px 0px #38435840',
    },
  },
};
