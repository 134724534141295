import { Typography, Box } from '@mui/material';
import { style } from './style';
import { PopOverItem } from '../../types';

type Props = {
  items: PopOverItem[];
};
function SidebarContentPopover({ items }: Props) {
  return (
    <Box sx={style.container}>
      {items.map((item) => {
        return (
          <Box key={item.label} sx={style.popoverContent}>
            <Typography sx={style.title}>{item.label}</Typography>
            {item.getIsActive ? (
              <Box sx={style.movementStatus}>
                <Box sx={style.circle(item.getIsActive())} />
                <Typography sx={style.titleValue}>{item.value}</Typography>
              </Box>
            ) : (
              <Typography sx={style.titleValue}>{item.value}</Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
export default SidebarContentPopover;
