import { Theme } from '@mui/material';

export const style = {
  root: {
    background: 'white',
    display: 'inline-flex',
    borderRadius: '8px',
    boxShadow: '0px 2px 8px 0px #38435840',
    padding: 0,
  },
  sidebarTrigger: {
    whiteSpace: 'nowrap',
    fontSize: 12,
    px: 4,
    display: { xs: 'inline-flex', sm: 'flex' },
    position: { xs: 'fixed', sm: 'static' },
    bottom: '8px',
    left: '8px',
    zIndex: 1,
    background: 'white',
    '&:hover': {
      background: 'white',
    },
    borderRadius: { xs: '8px !important', sm: 0 },
    boxShadow: {
      xs: '0px 2px 8px 0px #38435840 !important',
      sm: 'none !important',
    },
    height: { xs: '38px', sm: 'auto' },
  },
  followAll: {
    fontSize: 12,
    px: 4,
    height: '40px',
  },
  followAllActive: (theme: Theme) => ({ color: theme.palette.primary.main }),
  followAllInactive: { color: '#808080' },
  divider: { display: { xs: 'none', sm: 'flex' } },
};
