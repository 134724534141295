import { Button, ButtonProps } from '@mui/material';
import { style } from './style';
import { mergeSX } from '../../utils';

export type SFControlButtonProps = ButtonProps;

function SFControlButton(props: SFControlButtonProps) {
  const { children, sx, ...buttonProps } = props;

  return (
    <Button sx={mergeSX(style.button, sx)} {...buttonProps}>
      {children}
    </Button>
  );
}

export default SFControlButton;
