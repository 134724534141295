import { Box, Button, Typography } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  ResetPasswordForm,
  PASSWORD_DEFAULT_VALUES,
} from '../../types/password';
import Field from '../../../global/components/Field';
import { PasswordField } from '../../../shared/components/PasswordField';
import { styles } from './styles';
import { NotificationApi, ROUTES } from '../../../global';
import { getPasswordSettingValidationScheme } from '../../utils/validation';
import { AuthService } from '../../../services';
import PasswordRequirementsChecker from '../PasswordRequirementsChecker';
import { SuperFleetIcon } from '../../../shared/components/Icons';

function ResetPassword() {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const methods = useForm<ResetPasswordForm>({
    defaultValues: PASSWORD_DEFAULT_VALUES,
    resolver: yupResolver(getPasswordSettingValidationScheme()),
  });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const navigate = useNavigate();
  const [password] = useWatch({
    name: ['password'],
    control,
  });

  const onSubmit = async () => {
    const searchParam = new URLSearchParams(window.location.search);
    const code: number = (searchParam && Number(searchParam.get('code'))) || 0;

    const { ok } = await AuthService.setPassword({
      password,
      code,
    });
    if (ok) {
      NotificationApi.showSuccess(t('passwordCreated'));
      navigate(ROUTES.auth.login.path);
    }
  };

  return (
    <Box sx={styles.screen}>
      <FormProvider {...methods}>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={styles.container}
        >
          <Box sx={styles.main}>
            <Box sx={styles.note}>
              <Typography sx={styles.typography}>
                {t('welcomeSetPassword')}
              </Typography>
              <Typography fontSize={30} fontWeight={700}>
                {t('setPassword')}
              </Typography>
            </Box>
            <Box sx={styles.form}>
              <Box sx={styles.perField}>
                <Typography sx={styles.typographyPasswordTitle}>
                  {t('newPassword')}
                </Typography>
                <Field fieldSX={styles.fieldSx} name='password'>
                  <PasswordField />
                </Field>
              </Box>
              <Box sx={styles.perField}>
                <Typography sx={styles.typographyPasswordTitle}>
                  {t('confirmPassword')}
                </Typography>
                <Field fieldSX={styles.fieldSx} name='verifyPassword'>
                  <PasswordField />
                </Field>
              </Box>
            </Box>
            <PasswordRequirementsChecker password={password} />
            <Button
              disabled={isSubmitting}
              type='submit'
              variant='contained'
              size='large'
            >
              {t('submit')}
            </Button>
          </Box>
          <Box sx={styles.rightBackground}>
            <Box sx={styles.position}>
              <SuperFleetIcon />
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
}
export default ResetPassword;
