import { Theme } from '@mui/material';

export const style = {
  listItem: {
    gap: 4,
    padding: 2,
    height: 48,
    cursor: 'pointer',
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.50)',
  },
  listItemSecondaryText: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  listItemSelected: {
    backgroundColor: '#E6F0FC',
  },
  disabled: {
    pointerEvents: 'none',
  },
  listItemInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  popoverContent: {
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  popoverTitle: {
    color: 'text.secondary',
  },

  circle: (inMovement: boolean) => (theme: Theme) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: inMovement
      ? theme.palette.success.main
      : theme.palette.action.disabled,
  }),
};
