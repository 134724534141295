import {
  DirectionsCarOutlined,
  MapOutlined,
  MyLocation,
  PersonOutlined,
  PinDropOutlined,
  WarningAmber,
} from '@mui/icons-material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { ROUTES } from '../../global';
import { ApplicationArea } from '../../shared/types';
import { NAVIGATION_COLLAPSE_STATE } from './constants';
import { NavigationItem } from '../types';

export const getNavigationItems = (): NavigationItem[] => [
  {
    labelTranslationKey: 'monitoring.title',
    Icon: MyLocation,
    path: ROUTES.monitoring.path,
    fullPath: ROUTES.monitoring.fullPath,
    id: 0,
    area: ApplicationArea.MONITORING,
  },
  {
    labelTranslationKey: 'personnel.title',
    Icon: DirectionsCarOutlined,
    path: {
      pathname: ROUTES.personnel.vehicles.fullPath,
      search: 'page=1&rowsPerPage=10',
    },
    fullPath: ROUTES.personnel.fullPath,
    id: 1,
    area: ApplicationArea.PERSONNEL,
  },
  {
    labelTranslationKey: 'drivers.title',
    Icon: PersonOutlined,
    path: { pathname: ROUTES.drivers.path, search: 'page=1&rowsPerPage=10' },
    fullPath: ROUTES.drivers.fullPath,
    id: 2,
    area: ApplicationArea.PERSONNEL,
  },
  {
    labelTranslationKey: 'places.title',
    Icon: MapOutlined,
    path: {
      pathname: ROUTES.places.locations.fullPath,
      search: 'page=1&rowsPerPage=10',
    },
    fullPath: ROUTES.places.fullPath,
    id: 3,
    area: ApplicationArea.PLACES,
  },
  {
    labelTranslationKey: 'geofence.title',
    Icon: PinDropOutlined,
    path: ROUTES.geofence.path,
    fullPath: ROUTES.geofence.fullPath,
    id: 4,
    area: ApplicationArea.GEOFENCES,
  },
  {
    labelTranslationKey: 'protocols.title',
    Icon: WarningAmber,
    path: {
      pathname: ROUTES.protocols.rules.fullPath,
      search: 'page=1&rowsPerPage=10',
    },
    fullPath: ROUTES.protocols.fullPath,
    id: 5,
    area: ApplicationArea.PROTOCOLS,
  },
  {
    labelTranslationKey: 'admin.title',
    Icon: ManageAccountsOutlinedIcon,
    path: ROUTES.admin.users.fullPath,
    fullPath: ROUTES.admin.fullPath,
    id: 6,
    area: ApplicationArea.USERS,
  },
];

export function getUserGMTOffset() {
  const date = new Date();
  const offsetInMinutes = date.getTimezoneOffset();
  const result = -offsetInMinutes / 60;
  return `GMT${result >= 0 ? '+' : ''}${result}`;
}

export function getNavDrawerCollapsedState() {
  const stateFromStorage = localStorage.getItem(NAVIGATION_COLLAPSE_STATE);
  if (stateFromStorage) {
    return Boolean(JSON.parse(stateFromStorage));
  }
  return true;
}
