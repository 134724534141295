import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ResetPasswordValidationStatus } from '../../types/password';
import { styles } from './styles';
import PasswordValidateStatus from '../PasswordValidateStatus';
import {
  HAS_NUMBER_REGEX,
  HAS_LOWER_UPPER_CASE_REGEX,
  HAS_SPECIAL_CHARACTER_REGEX,
} from '../../utils';

type Props = {
  password: string;
};

function PasswordRequirementsChecker({ password }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const validationStatus = useMemo(() => {
    const validation = {
      passwordLengthStatus: ResetPasswordValidationStatus.PENDING,
      upperLowerCaseValidationStatus: ResetPasswordValidationStatus.PENDING,
      numberInsidePasswordStatus: ResetPasswordValidationStatus.PENDING,
      specialCharacterStatus: ResetPasswordValidationStatus.PENDING,
    };

    if (password) {
      validation.numberInsidePasswordStatus = HAS_NUMBER_REGEX.test(password)
        ? ResetPasswordValidationStatus.SUCCESS
        : ResetPasswordValidationStatus.ERROR;

      validation.upperLowerCaseValidationStatus =
        HAS_LOWER_UPPER_CASE_REGEX.test(password)
          ? ResetPasswordValidationStatus.SUCCESS
          : ResetPasswordValidationStatus.ERROR;
      validation.passwordLengthStatus =
        password.length >= 8
          ? ResetPasswordValidationStatus.SUCCESS
          : ResetPasswordValidationStatus.ERROR;
      validation.specialCharacterStatus = HAS_SPECIAL_CHARACTER_REGEX.test(
        password,
      )
        ? ResetPasswordValidationStatus.SUCCESS
        : ResetPasswordValidationStatus.ERROR;
    }
    return validation;
  }, [password]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.perField}>
        <Box sx={styles.iconField}>
          <PasswordValidateStatus
            status={validationStatus.passwordLengthStatus}
          />
        </Box>
        <Typography
          sx={[
            styles.note,
            validationStatus.passwordLengthStatus ===
              ResetPasswordValidationStatus.SUCCESS && styles.successText,
            validationStatus.passwordLengthStatus ===
              ResetPasswordValidationStatus.ERROR && styles.errorText,
          ]}
        >
          {t('moreCharacters')}
        </Typography>
      </Box>
      <Box sx={styles.perField}>
        <Box sx={styles.iconField}>
          <PasswordValidateStatus
            status={validationStatus.upperLowerCaseValidationStatus}
          />
        </Box>

        <Typography
          sx={[
            styles.note,
            validationStatus.upperLowerCaseValidationStatus ===
              ResetPasswordValidationStatus.SUCCESS && styles.successText,
            validationStatus.upperLowerCaseValidationStatus ===
              ResetPasswordValidationStatus.ERROR && styles.errorText,
          ]}
        >
          {t('contain')}
        </Typography>
      </Box>
      <Box sx={styles.perField}>
        <Box sx={styles.iconField}>
          <PasswordValidateStatus
            status={validationStatus.numberInsidePasswordStatus}
          />
        </Box>

        <Typography
          sx={[
            styles.note,
            validationStatus.numberInsidePasswordStatus ===
              ResetPasswordValidationStatus.SUCCESS && styles.successText,
            validationStatus.numberInsidePasswordStatus ===
              ResetPasswordValidationStatus.ERROR && styles.errorText,
          ]}
        >
          {t('number')}
        </Typography>
      </Box>
      <Box sx={styles.perField}>
        <Box sx={styles.iconField}>
          <PasswordValidateStatus
            status={validationStatus.specialCharacterStatus}
          />
        </Box>

        <Typography
          sx={[
            styles.note,
            validationStatus.specialCharacterStatus ===
              ResetPasswordValidationStatus.SUCCESS && styles.successText,
            validationStatus.specialCharacterStatus ===
              ResetPasswordValidationStatus.ERROR && styles.errorText,
          ]}
        >
          {t('specialCharacter')}
        </Typography>
      </Box>
    </Box>
  );
}

PasswordRequirementsChecker.displayName = 'Password Requirements Checker';
export default PasswordRequirementsChecker;
