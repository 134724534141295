import { Theme } from '@mui/material';

export const style = {
  content: {
    display: 'flex',
  },
  header: {
    padding: 4,
    height: 192,
    display: 'flex',
    flexDirection: 'column',
  },
  dates: {
    gap: 2,
    display: 'flex',
  },
  dateRangePicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tabs: {
    borderRight: '1px solid #ccc',
  },
  tab: (active: boolean) => (theme: Theme) => ({
    paddingLeft: theme.spacing(3, 4),
    alignItems: 'flex-start',
    color: active ? theme.palette.primary.main : '#1D1B20',
    backgroundColor: active ? '#E6F0FC' : 'transparent',
    width: 208,
    textTransform: 'none',
    fontWeight: 400,
    border: '1px solid transparent',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  }),
  footer: {
    padding: 3,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
    borderTop: '1px solid #ccc',
  },
};
