export const style = {
  team: {
    height: 32,
    width: '100%',
    backgroundColor: '#E7E7E7',
    borderBottom: '1px solid #CCC',
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  expandIcon: {
    cursor: 'pointer',
  },
  locationIcon: {
    marginLeft: 'auto',
  },
  driverList: {
    padding: 0,
  },
};
