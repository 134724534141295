import { useEffect, useMemo } from 'react';
import socket from '../utils/socket';
import { MonitoringTransportDTO, MonitoringUpdateDTO } from '../types';
import { monitoringUpdateVehicle } from '../slices/monitoring-slice';
import { useAppDispatch } from '../../layout/store';

const useRealTimeVehicleMonitoring = (vehicles: MonitoringTransportDTO[]) => {
  const vehicleIDs = useMemo(() => {
    const filteredByDeviceID = vehicles.filter((vehicle) => vehicle.deviceId);
    return filteredByDeviceID.map((vehicle) => vehicle.id).filter(Boolean);
  }, [vehicles]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleSocketConnect = () => {
      if (vehicleIDs.length) {
        socket.emit('joinRoom', { transports: vehicleIDs });
      }
    };

    socket.on('connect', handleSocketConnect);
    return () => {
      socket.off('connect', handleSocketConnect);
    };
  }, [vehicleIDs]);

  useEffect(() => {
    const handleSocketError = (event: unknown) => {};

    socket.on('error', handleSocketError);
    return () => {
      socket.off('error', handleSocketError);
    };
  }, []);

  useEffect(() => {
    const handleUpdate = (event: MonitoringUpdateDTO) => {
      dispatch(monitoringUpdateVehicle(event));
    };

    socket.on('update', handleUpdate);

    return () => {
      socket.off('update', handleUpdate);
    };
  }, [dispatch]);

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);
};

export default useRealTimeVehicleMonitoring;
