import { Box, Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { style } from './style';
import { Field, NotificationApi, ROUTES } from '../../../global';
import { getForgotPasswordValidationSchema } from '../../utils/validation';
import { AuthService } from '../../../services';

function ForgotPasswordForm() {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const formMethods = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(getForgotPasswordValidationSchema()),
  });

  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const onSubmit = async (data: { email: string }) => {
    try {
      const { ok } = await AuthService.forgotPassword(data.email);
      if (ok) {
        NotificationApi.showSuccess(t('resetInstructionsSent'));
        navigate(ROUTES.auth.login.path);
      }
    } catch {}
  };

  return (
    <>
      <Typography variant='h1'>{t('forgotPassword')}</Typography>
      <Typography variant='subtitle2' sx={style.instructions}>
        {t('forgotPasswordInstructions')}
      </Typography>
      <Box component='form' sx={style.form} onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <Box sx={style.fieldItem}>
            <Typography color='#4A5568' variant='subtitle1' fontWeight={400}>
              {t('email')}
            </Typography>
            <Field name='email'>
              <TextField
                size='medium'
                fullWidth
                inputProps={{ 'aria-label': 'Email' }}
              />
            </Field>
          </Box>
          <Button
            variant='contained'
            disabled={isSubmitting}
            sx={style.submitButton}
            type='submit'
          >
            {t('sendResetInstructions')}
          </Button>
        </FormProvider>
      </Box>
    </>
  );
}

export default ForgotPasswordForm;
