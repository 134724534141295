import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore, History } from '@mui/icons-material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DeviceStatusCar from '../../../../assets/monitoring-device-details-icons/monitoring-device-status-by-car.svg?component';
import { style } from './style';
import { MonitoringMovementStatus, MonitoringTransportDTO } from '../../types';
import { formatLatLng, NotificationApi, ROUTES } from '../../../global';
import AvatarImage from '../../../shared/components/AvatarImage';
import { toggleSearchParam } from '../../../shared/utils';
import { InfoGenerator } from '../../utils';
import DeviceSingleDetailMarker, {
  DeviceDetailType,
} from '../DeviceSingleDetailMarker/DeviceDetail';

type Props = {
  vehicleInfo: MonitoringTransportDTO;
};

function MonitoringDeviceDetails({ vehicleInfo }: Props) {
  const [showAll, setShowAll] = useState(false);
  const showMoreRef = useRef<HTMLButtonElement | null>(null);
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });

  const isActive = vehicleInfo.status === MonitoringMovementStatus.MOVEMENT;

  const isDeviceActive = vehicleInfo.deviceStatus === 1;

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(
      formatLatLng(
        vehicleInfo.latitude as number,
        vehicleInfo.longitude as number,
      ),
    );
    NotificationApi.showSuccess(t('copiedToClipboard'));
  }, [vehicleInfo, t]);
  const vehicleShowingData: DeviceDetailType[] = InfoGenerator({
    ...vehicleInfo,
    showAll,
    isDeviceActive,
    isActive,
    handleCopyClick,
  });

  // const address = useRef('Not Available');
  // const getCurrentLocation = async (): Promise<string | null> => {
  //   if (!vehicleInfo.latitude || !vehicleInfo.longitude) {
  //     return null;
  //   }
  //   try {
  //     const { data, ok } = await GeoService.reverseSearch([
  //       vehicleInfo.longitude,
  //       vehicleInfo.latitude,
  //     ]);
  //     if (ok && data) {
  //       address.current = data.address;
  //       return data.address;
  //     }
  //     return null;
  //   } catch (error) {
  //     return t('N/A');
  //   }
  // };
  // useEffect(() => {
  //   getCurrentLocation();
  // }, []);

  const toggleShowAll = useCallback(() => {
    setShowAll(!showAll);
  }, [showAll]);

  useEffect(() => {
    // We are attaching click event to copyRef because
    // currently we cannot attach it using onClick
    // this is a content rendered in a portal
    // and copy icon is rendered in a overlay

    if (showMoreRef.current) {
      showMoreRef.current.addEventListener('click', toggleShowAll);
    }
  }, [toggleShowAll]);

  const showHistory = () => {
    setSearchParams((params) =>
      toggleSearchParam(
        params,
        ROUTES.monitoring.searchParams.transportID,
        vehicleInfo.transportId,
      ),
    );
  };

  return (
    <>
      <Box sx={style.cardHeader}>
        <>
          <AvatarImage
            size='L'
            altName='Image'
            imageId={vehicleInfo.profileImageId}
            avatarSX={{ position: 'relative' }}
          />
          <Box sx={style.deviceStatus(vehicleInfo.deviceStatus)}>
            <DeviceStatusCar />
          </Box>
        </>

        <Box sx={style.title}>
          <Box sx={style.header}>
            <Typography sx={{ fontWeight: 500 }} variant='subtitle1'>
              {vehicleInfo.licensePlate || t('N/A')}
            </Typography>
            <Button
              startIcon={<History />}
              sx={{ paddingBottom: 2 }}
              onClick={showHistory}
              size='small'
            >
              {t('showHistory')}
            </Button>
          </Box>
          <Box sx={style.transportName}>
            <Typography sx={{ color: '#000000' }} variant='body1'>
              {vehicleInfo.transportName || t('N/A')}
            </Typography>
            <Box sx={style.dot} component='span' />
            <Typography sx={style.listItemPrimaryText} variant='body1'>
              {vehicleInfo.courierName || t('N/A')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <DeviceSingleDetailMarker data={vehicleShowingData} />
      </Box>

      <Button
        endIcon={showAll ? <ExpandLess /> : <ExpandMore />}
        sx={style.showMore}
        ref={showMoreRef}
      >
        {t(`show${showAll ? 'Less' : 'More'}`)}
      </Button>
    </>
  );
}

export default MonitoringDeviceDetails;
