import {
  LocationFilterDTO,
  LocationFormDTO,
  LocationsBulkDTO,
  LocationSearchOption,
} from '../locations/types';
import ApiService from '../global/services/api.service';
import QueryBuilder from '../shared/utils/query-builder';
import { ListDTO, MatchingMethod } from '../global';
import { GroupDTO } from './group.service';
import { LocationIconType } from '../shared/types';

export interface LocationLightDTO {
  id: number;
  name: string;
  address: string;
  code: string;
  group: GroupDTO;
  status: {
    id: number;
    status: string;
  };
  latitude: number;
  longitude: number;
  icon: LocationIconType;
  notes: string;
  contact: string;
  imageId: string;
}

export type LocationDTO = {
  id: number;
  name: string;
  address: string;
  status: string;
  statusId: number;
  group: {
    id: number;
    label: string;
    name: string;
  };
  latitude: number;
  longitude: number;
  icon: LocationIconType;
  notes: string;
  contact: string;
  imageId: string;
  code: string;
};

export interface LocationStatusDTO {
  id: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}
class LocationService {
  static ENDPOINT = '/locations';

  static getLocations({
    search,
    limit,
    offset,
    statusIds,
    group,
  }: LocationFilterDTO = {}) {
    const body = new QueryBuilder()
      .addLimit(limit || 20)
      .addOffset(offset || 0)
      .or({
        field: LocationSearchOption.NAME,
        value: search,
        matchingMethod: MatchingMethod.STARTS_WITH,
      })
      .or({
        field: LocationSearchOption.ADDRESS,
        value: search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: LocationSearchOption.CODE,
        value: search,
        matchingMethod: MatchingMethod.STARTS_WITH,
      })
      .and({
        value: statusIds?.length === 1 ? statusIds[0] : null,
        values: statusIds && statusIds.length > 1 ? statusIds : null,
        field: LocationSearchOption.STATUS,
        matchingMethod:
          statusIds?.length === 1
            ? MatchingMethod.EXACT
            : MatchingMethod.CONTAINS,
      })
      .and({
        field: LocationSearchOption.GROUP,
        value: group ? String(group) : null,
        matchingMethod: MatchingMethod.EXACT,
      });

    return ApiService.post<ListDTO<LocationLightDTO>>(
      `${LocationService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static addLocation(body: LocationFormDTO) {
    const data = LocationService.getLocationRequestPayload(body);

    return ApiService.post(LocationService.ENDPOINT, data);
  }

  static updateLocation(id: number, body: LocationFormDTO) {
    const data = LocationService.getLocationRequestPayload(body);

    return ApiService.patch(`${LocationService.ENDPOINT}/${id}`, data);
  }

  static getLocationById(id: string | number) {
    return ApiService.get<LocationDTO>(`${LocationService.ENDPOINT}/${id}`);
  }

  static getStatuses() {
    return ApiService.get<Array<LocationStatusDTO>>(
      `${LocationService.ENDPOINT}/status/all`,
      {},
      { cache: true },
    );
  }

  static deleteLocation(id: number) {
    return ApiService.delete(`${LocationService.ENDPOINT}/${id}`);
  }

  static updateManyLocations(body: LocationsBulkDTO) {
    return ApiService.put(`${LocationService.ENDPOINT}`, body);
  }

  static getLocationRequestPayload(formValues: LocationFormDTO) {
    const coordinates = formValues.coordinates
      .split(',')
      .map((coord) => parseFloat(coord));
    return {
      name: formValues.name,
      address: formValues.address.label,
      groupId: formValues.group?.id,
      code: formValues.code,
      latitude: coordinates[0],
      longitude: coordinates[1],
      notes: formValues.notes,
      icon: formValues.icon,
      status: Number(formValues.status),
      contact: formValues.contact,
      imageId: formValues.imageId || null,
    };
  }
}

export default LocationService;
