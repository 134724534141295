import { createSlice } from '@reduxjs/toolkit';
import {
  getMonitoringDrivers,
  getMonitoringGeneralInfo,
  getMonitoringTeams,
  getMonitoringTransportById,
  getMonitoringTransportsByTerm,
  getMonitoringTrips,
  getMonitoringVehicles,
} from '../services/monitoring.service';
import {
  MonitoringMovementStatus,
  MonitoringSlice,
  MonitoringTripState,
  MonitoringUpdateDTO,
} from '../types';
import { TransportFuelType } from '../../shared/types';

const TRIPS_INITIAL_STATE = {
  all: [],
  overallTrip: null,
  tripState: MonitoringTripState.INITIAL,
  tripProgress: 0,
  tripDraggedProgress: 0,
  previewProgress: 0,
  tripSpeed: 16,
  loading: true,
};

const GENERAL_INITIAL_STATE = {
  avgConsumption: null,
  avgSpeed: null,
  distancePassed: null,
  drivingTime: null,
  fuelConsumption: null,
  humidity: null,
  idlingTime: null,
  parkingTime: null,
  temperature: null,
  fuelType: TransportFuelType.GASOLINE,
  isLoading: true,
};

const initialState: MonitoringSlice = {
  map: {
    teams: {},
    transports: {},
  },
  trips: TRIPS_INITIAL_STATE,
  trackInfo: {
    allSelected: true,
    teamId: null,
    transportId: null,
  },
  sidebarFilters: {
    searchResults: {
      loading: false,
      data: [],
      total: 0,
      searching: false,
    },
    teamIds: [],
  },
  general: GENERAL_INITIAL_STATE,
  filter: {
    startTime: '00:00',
    endTime: '23:59',
    excludeWeekends: false,
  },
};

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    monitoringUpdateVehicle: (
      state,
      action: { payload: MonitoringUpdateDTO },
    ) => {
      const vehicle = state.map.transports[action.payload.transportId];
      if (vehicle && action.payload.data?.length) {
        const last = action.payload.data[action.payload.data.length - 1];
        vehicle.latitude = last.position.lat;
        vehicle.longitude = last.position.lng;
        vehicle.bearing = last.bearing;
        vehicle.vehicleSpeed = last.speed;
        vehicle.lastData = new Date(last.createdAt).getTime() || null;
        vehicle.gsmSignal = last.data.gsmSignal;
        vehicle.satellites = last.satellites;
        if (last.speed && last.speed > 0) {
          vehicle.status = MonitoringMovementStatus.MOVEMENT;
        } else if (last.speed == null) {
          vehicle.status = MonitoringMovementStatus.NO_DATA;
        }
      }
    },
    monitoringTrack: (
      state,
      action: { payload: Partial<MonitoringSlice['trackInfo']> },
    ) => {
      state.trackInfo.allSelected = action.payload.allSelected || false;
      state.trackInfo.teamId = action.payload.teamId || null;
      state.trackInfo.transportId = action.payload.transportId || null;
    },
    monitoringFollowAll: (state) => {
      state.trackInfo.allSelected = true;
      state.trackInfo.teamId = null;
      state.trackInfo.transportId = null;
    },
    monitoringSearchTransport: (state) => {
      state.sidebarFilters.searchResults.searching = true;
    },
    monitoringResetSearch: (state) => {
      state.sidebarFilters.searchResults.searching = false;
    },
    monitoringFilterByTeams: (state, action) => {
      state.sidebarFilters.teamIds = action.payload;
    },
    monitoringSelectTrip: (state) => {
      state.trips.tripState = MonitoringTripState.INITIAL;
      state.trips.tripProgress = 0;
      state.trips.tripDraggedProgress = 0;
      state.trips.previewProgress = 0;
    },
    monitoringStartTrip: (state) => {
      state.trips.tripState = MonitoringTripState.PLAYING;
    },
    monitoringPauseTrip: (state) => {
      state.trips.tripState = MonitoringTripState.PAUSED;
    },
    monitoringUpdateTripProgress: (state, action) => {
      state.trips.tripProgress = Math.floor(action.payload);
    },
    monitoringUpdateTripSpeed: (state, action) => {
      state.trips.tripSpeed = action.payload;
    },
    monitoringSeekToProgress(state, action) {
      state.trips.tripDraggedProgress = action.payload;
    },
    monitoringUpdateTripPreviewProgress(state, action) {
      state.trips.previewProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMonitoringTeams.fulfilled, (state, action) => {
      action.payload.forEach(({ id, total, name }) => {
        state.map.teams[id] = {
          id,
          total,
          name,
          transportIds: [],
        };
      });
    });

    builder.addCase(getMonitoringTransportById.fulfilled, (state, action) => {
      const { id, ...transport } = action.payload;

      state.map.transports[id] = {
        ...(state.map.transports[id] || {}),
        ...transport,
      };
    });

    builder.addCase(getMonitoringDrivers.fulfilled, (state, action) => {
      const { teamId, transports } = action.payload;

      state.map.teams[teamId].transportIds = transports.map(
        (transport) => transport.transportId,
      );

      transports.forEach((transport) => {
        if (state.map.transports[transport.transportId]) {
          state.map.transports[transport.transportId] = {
            ...transport,
            ...state.map.transports[transport.transportId],
          };
        } else {
          state.map.transports[transport.transportId] = transport;
        }
      });
    });

    builder.addCase(
      getMonitoringTransportsByTerm.fulfilled,
      (state, action) => {
        const { drivers, total } = action.payload;
        state.sidebarFilters.searchResults = {
          loading: false,
          data: drivers,
          total,
          searching: true,
        };
      },
    );

    builder.addCase(getMonitoringTransportsByTerm.pending, (state) => {
      state.sidebarFilters = {
        ...state.sidebarFilters,
        searchResults: {
          ...initialState.sidebarFilters.searchResults,
          loading: true,
          searching: true,
        },
      };
    });

    builder.addCase(getMonitoringVehicles.fulfilled, (state, action) => {
      const { transports } = action.payload;
      transports.forEach((transport) => {
        if (state.map.transports[transport.id]) {
          state.map.transports[transport.id] = {
            ...state.map.transports[transport.id],
            ...transport,
          };
        } else {
          state.map.transports[transport.id] = transport;
        }
      });
    });

    builder.addCase(getMonitoringTrips.pending, (state) => {
      state.trips = {
        all: [],
        overallTrip: null,
        tripState: MonitoringTripState.INITIAL,
        // This is real trip progress
        tripProgress: 0,
        tripSpeed: 16,
        // This is used for dragend event
        tripDraggedProgress: 0,
        // This is used for moving the marker when dragging
        previewProgress: 0,
        loading: true,
      };
    });

    builder.addCase(getMonitoringTrips.fulfilled, (state, action) => {
      const hasItems = action.payload.length > 0;
      state.trips.overallTrip = hasItems
        ? {
            startTime: action.payload[0]?.startTime,
            endTime: action.payload[action.payload.length - 1]?.endTime,
            distance: action.payload.reduce(
              (acc, trip) => acc + (trip.distance || 0),
              0,
            ),
            messages: action.payload.flatMap((trip) => {
              return trip.messages;
            }),
            startLatitude: action.payload[0].startLatitude,
            startLongitude: action.payload[0].startLongitude,
            endLatitude: action.payload[action.payload.length - 1].endLongitude,
            endLongitude:
              action.payload[action.payload.length - 1].startLongitude,
            startAddress: action.payload[0].startAddress,
            endAddress: action.payload[action.payload.length - 1].endAddress,
          }
        : null;
      state.trips.all = [
        ...action.payload.sort((a, b) => b.timestamp - a.timestamp),
      ];
      state.trips.tripState = MonitoringTripState.INITIAL;
      state.trips.tripProgress = 0;
      state.trips.loading = false;
    });

    builder.addCase(getMonitoringGeneralInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.general = action.payload;
      }
    });
  },
});

export const {
  monitoringUpdateVehicle,
  monitoringTrack,
  monitoringFollowAll,
  monitoringResetSearch,
  monitoringSearchTransport,
  monitoringFilterByTeams,
  monitoringSelectTrip,
  monitoringStartTrip,
  monitoringPauseTrip,
  monitoringUpdateTripProgress,
  monitoringUpdateTripSpeed,
  monitoringSeekToProgress,
  monitoringUpdateTripPreviewProgress,
} = monitoringSlice.actions;
