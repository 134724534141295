import { object, string } from 'yup';
import { t } from 'i18next';

const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;

export const getMonitoringTimePickerValidationSchema = () =>
  object().shape({
    startTime: string().matches(timeFormat, t('monitoring.invalidTimeFormat')),
    endTime: string()
      .matches(timeFormat, t('monitoring.invalidTimeFormat'))
      .test(
        'is-greater',
        t('monitoring.invalidEndDate'),
        function validateDatePicker(value) {
          const { startTime } = this.parent;
          return startTime && value && startTime < value;
        },
      ),
  });
