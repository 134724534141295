import { Theme } from '@mui/material';

const styles = {
  loginTo: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      marginBottom: theme.spacing(8),
    },
  }),
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
  },
  header: {
    marginTop: 36,
  },
  checkbox: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      paddingRight: '3px',
      padding: 0,
    },
  }),

  icon: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
    },
    fontSize: 'medium',
  }),
  welcomeBack: (theme: Theme) => ({
    color: '#2D3748',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      fontWeight: '400',
    },
  }),
  form: {
    flexDirection: 'column' as const,
    pt: 4,
    pb: { sm: 0, xs: 8 },
  },
  forgot: (theme: Theme) => ({
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      fontSize: 12,
      marginTop: 0,
    },
  }),

  rememberMe: (theme: Theme) => ({
    marginLeft: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 400,
      color: '#2D3748',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiFormControlLabel-label': {
        fontSize: 12,
        fontWeight: 400,
        color: '#2D3748',
        marginTop: 0,
        padding: 0,
        paddingLeft: '6px',
      },
    },
  }),
  loginButton: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      height: 50,
    },
  }),

  footer: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0.5,
    fontSize: 16,
    fontWeight: 400,
    paddingLeft: '15px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 0,
      paddingTop: 1,
    },
  }),
};

export default styles;
