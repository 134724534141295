import { Theme } from '@mui/material';

export const style = {
  teams: {
    marginTop: 4,
    overflowY: 'auto',
  },
  team: (theme: Theme) => ({
    height: 32,
    width: '100%',
    backgroundColor: '#E7E7E7',
    borderBottom: '1px solid #CCC',
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  }),
  driverList: {
    padding: 0,
  },
  expandIcon: {
    cursor: 'pointer',
  },
};
