import { FC } from 'react';
import { GSMSignalIcons, SignalStrengthLabels } from './constants';

type Props = {
  satellites?: number | null;
};
export default function getGsmSignalIcon({ satellites }: Props): FC {
  if (!satellites) {
    return GSMSignalIcons[SignalStrengthLabels.NoSignal];
  }
  switch (!!satellites) {
    case satellites > 1 && satellites < 6:
      return GSMSignalIcons[SignalStrengthLabels.VeryWeak];
    case satellites < 11:
      return GSMSignalIcons[SignalStrengthLabels.Weak];
    case satellites < 16:
      return GSMSignalIcons[SignalStrengthLabels.Strong];
    default:
      return GSMSignalIcons[SignalStrengthLabels.VeryStrong];
  }
}
