import ApiService from '../global/services/api.service';
import { ListDTO, MatchingMethod, TableFilterDTO } from '../global';
import { RoleFormValues, SearchOption, Permission } from '../roles/types';
import QueryBuilder from '../shared/utils/query-builder';
import { ApplicationAction, ApplicationArea } from '../shared/types';
import { ROLE_DEFAULT_AREAS } from '../shared/utils/constants';

export interface RoleLightDTO {
  createdAt: string;
  updatedAt: string;
  description: string;
  id: number;
  name: string;
  permissions: Array<Permission>;
  type: 'USER' | 'SYSTEM';
}

export type RoleDTO = RoleLightDTO;

class RoleService {
  static ENDPOINT = '/roles';

  static getRoles(filter: TableFilterDTO) {
    const body = new QueryBuilder()
      .addOffset(filter.offset)
      .addLimit(filter.limit)
      .or({
        field: SearchOption.NAME,
        value: filter.search,
        matchingMethod: MatchingMethod.CONTAINS,
      });
    return ApiService.post<ListDTO<RoleLightDTO>>(
      `${RoleService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static createRole(role: RoleFormValues) {
    const body = RoleService.getRoleRequestPayload(role);
    return ApiService.post<RoleDTO>('/roles', body);
  }

  static getRoleById(id: number) {
    return ApiService.get<RoleDTO>(`/roles/${id}`);
  }

  static updateRole(role: RoleFormValues, id: number) {
    const body = RoleService.getRoleRequestPayload(role);
    return ApiService.patch(`/roles/${id}`, body);
  }

  static deleteRole(id: number) {
    return ApiService.delete(`/roles/${id}`);
  }

  static getRoleRequestPayload(role: RoleFormValues) {
    return {
      name: role.name,
      description: role.description,
      permissions: RoleService.mapPermissions(role.area),
    };
  }

  static mapPermissionsToFormValues(permissions: Permission[]) {
    return permissions.reduce<
      Partial<Record<ApplicationArea, ApplicationAction>>
    >((acc, { area, action }) => {
      acc[area] = action;
      return acc;
    }, ROLE_DEFAULT_AREAS);
  }

  static mapPermissions(area: Record<ApplicationArea, ApplicationAction>) {
    return Object.entries(area)
      .map(([applicationArea, action]) => ({
        area: applicationArea,
        action,
      }))
      .filter((permission) => permission.action);
  }
}

export default RoleService;
