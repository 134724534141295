import { Box } from '@mui/material';
import { NavigationDrawer } from '../index';
import Header from '../Header';
import AppRoutes from '../AppRoutes';
import styles from './styles';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleNavDrawer } from '../../slices/layout-slice';
import { getNavDrawerCollapsedSelector } from '../../selectors/layout-selector';

function Content() {
  const dispatch = useAppDispatch();
  const navDrawerCollapsed = useAppSelector(getNavDrawerCollapsedSelector);
  const toggle = () => dispatch(toggleNavDrawer());

  return (
    <Box sx={styles.root}>
      <Header onMenuClick={toggle} />
      <Box sx={styles.body}>
        <NavigationDrawer collapsed={navDrawerCollapsed} onToggle={toggle} />
        <Box sx={styles.routesWrapper} id='appContent'>
          <AppRoutes />
        </Box>
      </Box>
    </Box>
  );
}

export default Content;
