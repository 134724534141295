import { useCallback, useEffect } from 'react';
import { useAppDispatch } from '../../store';
import { AuthService } from '../../../services';
import { setUser, setUserLoadingState } from '../../../auth/slices/auth-slice';

function IdentifyUser() {
  const dispatch = useAppDispatch();

  const getMe = useCallback(async () => {
    dispatch(setUserLoadingState(true));
    const { data, ok } = await AuthService.getUser();

    if (ok) {
      dispatch(setUser(data));
    }
    dispatch(setUserLoadingState(false));
  }, [dispatch]);

  useEffect(() => {
    getMe();
  }, [getMe]);

  return null;
}

IdentifyUser.displayName = 'IdentifyUser';

export default IdentifyUser;
