import { Theme } from '@mui/material';

const styles = {
  appBarRight: {
    display: 'flex',
    alignItems: { xs: 'flex-start', sm: 'center' },
    flexDirection: { xs: 'column', sm: 'row' },
    gap: 4,
  },
  selectMenu: {
    padding: 0,
  },
  menuItem: (theme: Theme) => ({
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '140%',
    padding: 2,
    gap: 2,
  }),
  languageSelect: {
    border: 'none !important',
    outline: 'none !important',
    '& .MuiSelect-select': {
      padding: '0 !important',
      height: '18px !important',
      borderRadius: '50% !important',
    },
    '&:after': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
  },
  divider: {
    marginLeft: 2,
    marginRight: 2,
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: { xs: 'row-reverse', sm: 'row' },
    gap: 2,
  },
  logout: {
    width: '100%',
  },
};

export default styles;
