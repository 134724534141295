import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  SwipeableDrawer,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect } from 'react';
import MonitoringFilter from '../MonitoringFilter';
import MonitoringTeams from '../MonitoringTeams';
import style from './style';
import Puller from '../../../shared/components/Puller';
import MonitoringVehicles from '../MonitoringVehicles';
import { getMonitoringVehicles } from '../../services/monitoring.service';
import { useAppDispatch, useAppSelector } from '../../../layout/store';
import { getMonitoringModeSelector } from '../../selectors';
import { MonitoringMode } from '../../../shared/types';

type MonitoringTeamsDrawerProps = {
  open: boolean;
  hideSidebar: () => void;
  showSidebar: () => void;
};

function MonitoringTeamsDrawer(props: MonitoringTeamsDrawerProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const { open, hideSidebar, showSidebar } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const mode = useAppSelector(getMonitoringModeSelector);

  const inHistoryMode = mode === MonitoringMode.HISTORY;

  const dispatch = useAppDispatch();

  const onMobileDrawerClose = () => {
    if (isMobile) {
      hideSidebar();
    }
  };

  useEffect(() => {
    dispatch(getMonitoringVehicles());
  }, [dispatch]);

  return (
    <SwipeableDrawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor={isMobile ? 'bottom' : 'left'}
      ModalProps={{ keepMounted: true }}
      PaperProps={{ sx: style.paper }}
      open={open}
      onOpen={showSidebar}
      onClose={hideSidebar}
    >
      <Puller />
      <Box sx={style.header}>
        <Typography variant='h2'>{t('title')}</Typography>
        <Button onMouseDown={hideSidebar} sx={style.hideButton}>
          {t('hideSidebar')}
        </Button>
      </Box>
      <MonitoringFilter onClose={onMobileDrawerClose} />
      <MonitoringTeams onClose={onMobileDrawerClose} />

      {!inHistoryMode && <MonitoringVehicles />}
    </SwipeableDrawer>
  );
}

export default MonitoringTeamsDrawer;
