import { ListResponseDTO, MatchingMethod, TableFilterDTO } from '../global';
import {
  DriverFormDTO,
  DriverTeamChangeDTO,
  SearchOption,
} from '../drivers/types';
import ApiService from '../global/services/api.service';
import QueryBuilder from '../shared/utils/query-builder';

export enum DriverRole {
  SUPPLIER = 'SUPPLIER',
  MANAGER = 'MANAGER',
  PRESELLER = 'PRESELLER',
}

export interface DriverLightDTO {
  id: number;
  firstName: string;
  lastName: string;
  notes: string;
  phone: string;
  email: string;
  roles: DriverRole;
  transport?: {
    id: number;
    name: string;
    licensePlate: string;
    team: {
      id: number;
      name: string;
    };
  };
  profileImageId: string | null;
}

export type DriverDTO = DriverLightDTO;

class DriverService {
  static ENDPOINT = '/couriers';

  static getDrivers(filters: TableFilterDTO) {
    const body = new QueryBuilder()
      .addLimit(filters.limit)
      .addOffset(filters.offset)
      .or({
        field: SearchOption.FIRST_NAME,
        value: filters.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: SearchOption.LAST_NAME,
        value: filters.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: SearchOption.EMAIL,
        value: filters.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: SearchOption.PHONE,
        value: filters.search,
        matchingMethod: MatchingMethod.CONTAINS,
      });

    return ApiService.post<ListResponseDTO<DriverLightDTO>>(
      `${DriverService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static createDriverRequestPayload(driver: DriverFormDTO) {
    const body: any = {
      firstName: driver.firstName,
      lastName: driver.lastName,
      notes: driver.notes,
      phone: driver.contactNumber,
      email: driver.email,
      roles: driver.role,
      transportId: driver.transport?.id || null,
    };

    if (driver.profileImageId) {
      body.profileImageId = driver.profileImageId;
    }

    return body;
  }

  static createDriver(driver: DriverFormDTO) {
    return ApiService.post(
      DriverService.ENDPOINT,
      DriverService.createDriverRequestPayload(driver),
    );
  }

  static getDriverById(id: number) {
    return ApiService.get<DriverDTO>(`${DriverService.ENDPOINT}/${id}`);
  }

  static editDriver(id: number, driver: DriverFormDTO) {
    return ApiService.patch(
      `${DriverService.ENDPOINT}/${id}`,
      DriverService.createDriverRequestPayload(driver),
    );
  }

  static deleteDriver(id: number) {
    return ApiService.delete(`${DriverService.ENDPOINT}/${id}`);
  }

  static editDrivers(driver: DriverTeamChangeDTO) {
    return ApiService.put(`${DriverService.ENDPOINT}`, driver);
  }
}

export default DriverService;
