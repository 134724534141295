import { Box, SxProps, Theme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { style } from './style';
import { mergeSX } from '../../../global';

export type ControlsWrapperProps = PropsWithChildren<{
  sx?: SxProps<Theme>;
}>;

function ControlsWrapper(props: ControlsWrapperProps) {
  const { sx = [], children } = props;

  return <Box sx={mergeSX(style.wrapper, sx)}>{children}</Box>;
}

export default ControlsWrapper;
