import { Theme } from '@mui/material';

export const style = {
  instructions: {
    marginTop: 3,
    color: '#808080',
  },
  form: {
    marginTop: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  fieldItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  submitButton: (theme: Theme) => ({
    height: 50,
    ...theme.typography.subtitle1,
  }),
};
