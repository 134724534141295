import { Theme } from '@mui/material';

export const style = {
  field: {
    display: 'flex',
    gap: 2,
    flexDirection: 'column',
  },
  label: (theme: Theme) => ({
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize as number,
    lineHeight: theme.typography.body1.lineHeight as number,
    letterSpacing: theme.typography.body1.letterSpacing as number,
  }),
};
