import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../global/services/api.service';
import { ReauthenticateMiddleware } from '../../middlewares/reauthenticate-middleware';
import { ROUTES } from '../../../global';
import { useAppDispatch } from '../../../layout/store';
import { setUser } from '../../slices/auth-slice';

function Reauthenticate() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const callbackOnFail = () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      dispatch(setUser(null));
      navigate(ROUTES.auth.login.path);
    };

    ApiService.use(new ReauthenticateMiddleware(callbackOnFail), 'response');
  }, [dispatch, navigate]);

  return null;
}

export default Reauthenticate;
