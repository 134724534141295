import { Component, PropsWithChildren, ReactNode } from 'react';

type IProps = PropsWithChildren<{ fallback: ReactNode }>;

class ErrorBoundary extends Component<IProps, { hasError: boolean }> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    const { state, props } = this;

    if (state.hasError) {
      return props.fallback;
    }

    return props.children;
  }
}

export default ErrorBoundary;
