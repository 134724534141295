export type ResetPasswordForm = {
  password: string;
  verifyPassword: string;
};
export const PASSWORD_DEFAULT_VALUES = {
  password: '',
  verifyPassword: '',
};
export enum ResetPasswordValidationStatus {
  'PENDING' = 'PENDING',
  'SUCCESS' = 'SUCCESS',
  'ERROR' = 'ERROR',
}

export type PasswordDTO = {
  password: string;
  code: number;
};
