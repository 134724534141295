import armenianFlag from '../../../assets/flags/armenia.svg';
import spanishFlag from '../../../assets/flags/spain.svg';
import frenchFlag from '../../../assets/flags/french.svg';
import russianFlag from '../../../assets/flags/russia.svg';
import englishFlag from '../../../assets/flags/united-states.svg';

export const LANGUAGES = [
  { label: 'English', value: 'en', src: englishFlag },
  { label: 'Հայերեն', value: 'hy', src: armenianFlag },
  { label: 'Русский', value: 'ru', src: russianFlag },
  { label: 'Español', value: 'es', src: spanishFlag },
  { label: 'Français', value: 'fr', src: frenchFlag },
];

export const LANGUAGE_MAPPER = {
  'en-US': 'en',
  en: 'en',
  hy: 'hy',
  ru: 'ru',
  es: 'es',
  fr: 'fr',
};

export const NAVIGATION_COLLAPSE_STATE = 'GLOBAL_NAVIGATION_COLLAPSE_STATE';
