import { createSlice } from '@reduxjs/toolkit';
import { User } from '../types';

const initialState: { user: User | null; isLoading: boolean } = {
  user: null,
  isLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    resetUser: (state) => {
      state.user = null;
    },
    setUserLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setUser, resetUser, setUserLoadingState } = authSlice.actions;
