import { useEffect, useRef } from 'react';
import { noop } from '../utils';
import { Callback } from '../types';

export function useInterval(callback: Callback, delay: number | null) {
  const savedCallback = useRef(noop);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      const intervalId = setInterval(tick, delay);
      return () => clearInterval(intervalId);
    }
    return () => {};
  }, [delay]);
}

export default useInterval;
