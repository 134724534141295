import VectorSource from 'ol/source/Vector';
import { Vector } from 'ol/layer';
import { Feature, MapEvent } from 'ol';
import { Geometry, Point } from 'ol/geom';
import { Modify } from 'ol/interaction';
import { Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import BaseEvent from 'ol/events/Event';
import { SFMapType } from './map.service';
import { MarkerConfig } from '../types';

class SFMarker {
  instance: SFMapType;

  constructor(instance: SFMapType) {
    this.instance = instance;
  }

  createMarker({
    position,
    id,
    draggable,
    zIndex = 0,
    onCoordinateChange,
  }: MarkerConfig) {
    const markerGeometry = new Point(position);
    const markerFeature = new Feature({
      geometry: markerGeometry,
      isMarker: true,
      id,
    });

    // Create separate sources for marker and label
    const markerSource = new VectorSource({
      features: [markerFeature],
    });

    // Create separate layers for marker and label
    const markerLayer = new Vector({
      source: markerSource,
      zIndex,
    });

    const labelLayer = new Vector({
      source: markerSource,
      zIndex: zIndex + 1,
    });

    let dragCleanup = () => {};

    if (draggable) {
      dragCleanup = this.makeMarkerDraggable({
        vectorLayer: markerLayer,
        vectorSource: markerSource,
        onCoordinateChange: onCoordinateChange as (
          coordinate: Coordinate,
        ) => void,
      });
    }

    this.instance.addOverlayLayer(markerLayer);
    this.instance.addOverlayLayer(labelLayer);

    const cleanup = () => {
      this.instance.removeOverlayLayer(markerLayer);
      this.instance.removeOverlayLayer(labelLayer);
      dragCleanup();
    };

    return {
      cleanup,
      markerLayer,
      labelLayer,
      setPosition: (coord: Coordinate) => {
        markerGeometry.setCoordinates(coord);
      },
    };
  }

  makeMarkerDraggable({
    vectorLayer,
    vectorSource,
    onCoordinateChange,
  }: {
    vectorLayer: Vector<VectorSource<Feature<Point>>>;
    vectorSource: VectorSource<Feature<Point>>;
    onCoordinateChange: (coordinate: Coordinate) => void;
  }) {
    const modify = new Modify({
      hitDetection: vectorLayer,
      source: vectorSource as unknown as VectorSource<Feature<Geometry>>,
      style: new Style(),
    });
    modify.on(['modifystart', 'modifyend'], (evt) => {
      const target = this.instance.map.getTargetElement();
      if (target) {
        target.style.cursor =
          evt.type === 'modifystart' ? 'grabbing' : 'pointer';
      }
    });

    modify.on('modifyend', (evt) => {
      evt.features.forEach((feature) => {
        const geometry = feature.getGeometry() as Point;
        if (geometry) {
          const coordinates = geometry.getCoordinates();
          onCoordinateChange(coordinates);
        }
      });
    });
    const overlaySource = modify.getOverlay().getSource();
    overlaySource?.on(
      ['addfeature', 'removefeature'],
      (event: BaseEvent | Event) => {
        if (event instanceof MapEvent) {
          const target = this.instance.map.getTargetElement();
          if (target) {
            target.style.cursor = event.type === 'addfeature' ? 'pointer' : '';
          }
        }
      },
    );

    this.instance.map.addInteraction(modify);

    return () => {
      this.instance.map.removeInteraction(modify);
    };
  }
}

export default SFMarker;
