import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getMonitoringTrips } from '../services/monitoring.service';
import useMonitoringDateFilter from './useMonitoringDateFilter';
import { useAppDispatch } from '../../layout/store';

const useMonitoringTripsPreload = () => {
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const transportID = searchParams.get('transportID');

  const { startDate, endDate } = useMonitoringDateFilter();

  useEffect(() => {
    if (transportID) {
      dispatch(
        getMonitoringTrips({
          startDate: startDate.toUTCString(),
          endDate: endDate.toUTCString(),
          transportId: +transportID,
        }),
      );
    }
  }, [transportID, startDate, endDate, dispatch]);
};

export default useMonitoringTripsPreload;
