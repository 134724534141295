const styles = {
  root: { display: 'flex', flexDirection: 'column' },
  body: {
    display: 'flex',
    height: ['calc(100dvh - 60px)', 'calc(100vh - 60px)'],
  },
  routesWrapper: {
    display: 'flex',
    flexGrow: 1,
    overflowX: 'hidden',
  },
};

export default styles;
