import { Geometry, Polygon } from 'ol/geom';
import { Collection, Feature } from 'ol';
import { Style } from 'ol/style';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Modify, Translate } from 'ol/interaction';
import BaseEvent from 'ol/events/Event';
import { Coordinate } from 'ol/coordinate';
import { fromExtent } from 'ol/geom/Polygon';
import SFCircle from './circle.service';
import { SFMapType } from './map.service';

class SFGeofence {
  circle: SFCircle | null;

  instance: SFMapType;

  constructor(instance: SFMapType) {
    this.circle = null;
    this.instance = instance;
  }

  createCircle(
    center: number[],
    radius: number,
    onCenterChanged: (center: number[]) => void,
  ) {
    this.circle = new SFCircle(this.instance);
    const { cleanup, ...rest } = this.circle.createCircle(
      center,
      radius,
      onCenterChanged,
    );

    return {
      ...rest,
      cleanup: () => {
        cleanup();
        this.circle = null;
      },
    };
  }

  private createSquare() {
    const center = this.instance.getCenter();
    const sizeInMeters = 5000;
    const sizeInMapUnits =
      sizeInMeters /
      (this.instance.map.getView().getProjection().getMetersPerUnit() || 0);
    const halfSize = sizeInMapUnits / 2;

    const squareExtent = [
      center[0] - halfSize,
      center[1] - halfSize,
      center[0] + halfSize,
      center[1] + halfSize,
    ];

    return fromExtent(squareExtent);
  }

  createEditablePolygon(
    onShapeChange: (event: BaseEvent | Event) => unknown,
    paths?: Coordinate[][],
  ) {
    let feature;
    if (paths) {
      const polygon = new Polygon(paths);
      feature = new Feature(polygon);
    } else {
      feature = new Feature(this.createSquare());
    }

    const vectorSource = new VectorSource({
      features: [feature],
    });

    const vectorLayer = new Vector({
      source: vectorSource,
    });

    this.instance.addOverlayLayer(vectorLayer);

    let modify = new Modify({
      source: vectorSource as unknown as VectorSource<Feature<Geometry>>,
    });

    this.instance.map.addInteraction(modify);

    const translate = new Translate({
      features: new Collection([feature]),
      condition: (e) => e.originalEvent.ctrlKey,
    });

    this.instance.map.addInteraction(translate);

    modify.on(['modifyend'], onShapeChange);

    const cleanup = () => {
      this.instance.map.removeInteraction(modify);
      this.instance.map.removeInteraction(translate);
      this.instance.removeOverlayLayer(vectorLayer);
      modify.un(['modifyend'], onShapeChange);
    };

    const changeModifyStyle = (style: Style) => {
      this.instance.map.removeInteraction(modify);
      modify.un(['modifyend'], onShapeChange);
      modify = new Modify({
        source: vectorSource as unknown as VectorSource<Feature<Geometry>>,
        style,
      });
      this.instance.map.addInteraction(modify);
      modify.on(['modifyend'], onShapeChange);
    };

    return { cleanup, vectorLayer, changeModifyStyle };
  }
}

export default SFGeofence;
