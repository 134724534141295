import { useState, MouseEvent } from 'react';

const useAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (
    event: MouseEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return {
    open,
    anchorEl,
    handleClick,
    handleClose,
  };
};

export default useAnchor;
