import { debounce } from '@mui/material';
import {
  IMiddleware,
  MiddlewareContext,
  NextFunction,
} from '../middleware-manager';
import { getErrorMessageFromException } from '../../utils';
import { NotificationApi } from '../notification.service';

// TODO understand the root cause of
// incorrect 2 toasts appearing
// and remove this function
// to reproduce you need to just
// uncomment debounce and login with wrong
// credentials, any help will be appreciated!
const showError = debounce(
  (message: string) => NotificationApi.showError(message),
  200,
);

export class ErrorHandlingMiddleware implements IMiddleware {
  async onResponse(
    context: MiddlewareContext,
    next: NextFunction,
  ): Promise<void> {
    await next();
    if (
      context.request.method !== 'GET' &&
      context.response &&
      !context.response.ok &&
      !Array.isArray(context.response)
    ) {
      const message = getErrorMessageFromException(context.response.error);
      if (context.request.options?.notifyErrors !== false) {
        showError(message);
      }
    }
    return undefined;
  }
}
