import { useTranslation } from 'react-i18next';
import DriverControl from '../DriverControl';
import SidebarContentPopover from '../SidebarContentPopover';
import { PopOverItem } from '../../types';
import { getGsmAntennaTitle, getLastDataReceivedOption } from '../../utils';
import getGsmSignalIcon from '../../utils/getGsmSignalIcon';

type Props = {
  satellites?: number | null;
  lastData?: null | number;
};
export default function GpsAntennaIcon({ satellites, lastData }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  const gpsAntennaOptions = getGsmAntennaTitle(satellites);
  const popOverItem: PopOverItem[] = [
    {
      label: t('gpsSignal'),
      value: `${t(gpsAntennaOptions.signalQuality)} ${gpsAntennaOptions.ratio}`,
    },
    getLastDataReceivedOption(lastData),
  ];
  const GpsAntenna = getGsmSignalIcon({ satellites });
  return (
    <DriverControl Icon={<GpsAntenna />}>
      <SidebarContentPopover items={popOverItem} />
    </DriverControl>
  );
}
