import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { fromLonLat } from 'ol/proj';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Feature, MapBrowserEvent } from 'ol';
import { Point } from 'ol/geom';
import { Icon, Style, Text, Fill, Stroke } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import Overlay from 'ol/Overlay';

import { createPortal } from 'react-dom';
import { Popover } from '@mui/material';
import { SFMap } from '../../services';
import { noop } from '../../../global';

type Props = {
  src?: string | ((isOverlayOpen: boolean) => string);
  position: Coordinate;
  rotation?: number;
  draggable?: boolean;
  label?: string;
  zIndex?: number;
  anchor?: [number, number];
  onChange?: (coordinate: Coordinate) => void;
  /**
   * Either a render function or a JSX node that will be rendered when the marker is clicked.
   */
  renderOverlay?: (coordinate: Coordinate) => React.ReactNode;
};

function Marker({
  src,
  position,
  rotation,
  draggable = false,
  label,
  zIndex = 0,
  anchor = [0.5, 0.5],
  renderOverlay,
  onChange,
}: Props) {
  const vectorRef = useRef<Vector<VectorSource<Feature<Point>>> | null>(null);
  const overlayRef = useRef<Overlay | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [overlayCoordinate, setOverlayCoordinate] = useState<Coordinate | null>(
    null,
  );

  const [lat, lng] = position;
  const uniqueID = useMemo(() => crypto.randomUUID(), []);

  // Create the marker itself
  useEffect(() => {
    if (!src || (typeof src === 'function' && !src(isOverlayOpen))) return noop;

    const { cleanup, markerLayer, labelLayer } = SFMap.createMarker({
      position: fromLonLat([lng, lat]),
      id: uniqueID,
      draggable,
      zIndex,
      onCoordinateChange: onChange,
    });

    vectorRef.current = markerLayer;

    // Style for marker layer
    markerLayer.setStyle((feature, resolution) => {
      const style = new Style({
        image: new Icon({
          src: typeof src === 'string' ? src : src(isOverlayOpen),
          anchor,
          rotation,
        }),
      });
      style.getImage()!.setScale(1 / resolution ** (1 / 6));
      return style;
    });

    // Style for label layer
    if (label) {
      labelLayer.setStyle(() => {
        labelLayer.setDeclutter(true);
        return new Style({
          text: new Text({
            text: label,
            offsetY: 20,
            fill: new Fill({ color: '#fff' }),
            stroke: new Stroke({ color: '#000', width: 3 }),
            overflow: true,
          }),
        });
      });
    }

    return cleanup;
  }, [
    lat,
    lng,
    src,
    zIndex,
    anchor,
    rotation,
    uniqueID,
    draggable,
    label,
    isOverlayOpen,
  ]);

  // Create or update the overlay
  useEffect(() => {
    // 1. Create the DOM node for the overlay
    containerRef.current = document.createElement('div');

    // 2. Create and add an Overlay to the map
    overlayRef.current = new Overlay({
      element: containerRef.current,
      positioning: 'bottom-center',
      offset: [0, 20],
      autoPan: true,
    });
    SFMap.getMap().addOverlay(overlayRef.current);

    return () => {
      if (overlayRef.current) {
        SFMap.getMap().removeOverlay(overlayRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // If overlay is open, set its position, else hide
    if (isOverlayOpen && overlayCoordinate && overlayRef.current) {
      overlayRef.current.setPosition(overlayCoordinate);
    } else {
      overlayRef.current?.setPosition(undefined);
    }
  }, [isOverlayOpen, overlayCoordinate]);

  const handleMapClick = useCallback(
    (event: MapBrowserEvent<UIEvent>) => {
      const map = SFMap.getMap();
      const feature = map.forEachFeatureAtPixel(event.pixel, (feat) => feat);
      if (!feature) {
        setIsOverlayOpen(false);
        return;
      }

      const featureID = feature.get('id');
      if (featureID === uniqueID) {
        // Toggle the overlay
        setIsOverlayOpen((prev) => !prev);
        setOverlayCoordinate(event.coordinate);
      }
    },
    [uniqueID],
  );

  useEffect(() => {
    const map = SFMap.getMap();
    map.on('click', handleMapClick);
    return () => {
      map.un('click', handleMapClick);
    };
  }, [handleMapClick]);

  if (!containerRef.current || !isOverlayOpen) {
    return null;
  }

  return createPortal(
    isOverlayOpen && renderOverlay ? (
      <Popover
        open
        anchorEl={containerRef.current}
        onClose={() => setIsOverlayOpen(false)}
      >
        {renderOverlay(overlayCoordinate as Coordinate)}
      </Popover>
    ) : null,
    containerRef.current,
  );
}

Marker.displayName = 'Marker';

export default memo(Marker);
