import { Theme } from '@mui/material';

export const style = {
  dateRangeControl: {
    gap: 2.5,
    display: 'flex',
    alignItems: 'center',
  },
  control: {
    boxShadow: '0px 2px 8px 0px rgba(56, 67, 88, 0.25)',
    backgroundColor: 'white',
    borderRadius: 2,
    padding: 1,
    display: 'inline-flex',
  },
  dateRangeTag: (theme: Theme) => ({
    padding: 2,
    backgroundColor: theme.palette.grey.A200,
    fontWeight: 500,
    borderRadius: 2,
    color: '#323232',
  }),
  dateTime: {
    color: '#323232',
  },
  trigger: { padding: '0px 8px' },
};
