export const style = {
  colors: {
    display: 'flex',
    gap: 2,
    padding: 1,
  },
  coloredCircle: (fillColor: string) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: fillColor,
    cursor: 'pointer',
  }),
  selectedCircle: {
    border: '3px solid #fff',
    outline: '2px solid #276EF1',
  },
  centerText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
