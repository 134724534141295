import { useTranslation } from 'react-i18next';
import getMovementsIcon from '../../utils/getMovementsIcon';
import SidebarContentPopover from '../SidebarContentPopover';
import DriverControl from '../DriverControl';
import { MonitoringMovementStatus, PopOverItem } from '../../types';
import { getLastDataReceivedOption } from '../../utils';

type Props = {
  status?: MonitoringMovementStatus;
  inMovement: boolean;
  vehicleSpeed?: number | null;
  lastData?: number | null;
};
export default function MovementsIcon({
  status,
  inMovement,
  vehicleSpeed,
  lastData,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'monitoring' });
  if (status == undefined) {
    status = MonitoringMovementStatus.NO_DATA;
  }
  const movementsIconPopOverItems: PopOverItem[] = [
    {
      label: t('movementStatus'),
      value: t(`movementStatusType.${status}`),
      getIsActive: () => inMovement,
    },
    { label: t('currentSpeed'), value: vehicleSpeed || t('N/A') },
    getLastDataReceivedOption(lastData),
  ];
  const MovementsStatusIcon = getMovementsIcon({ status });
  return (
    <DriverControl Icon={<MovementsStatusIcon />}>
      <SidebarContentPopover items={movementsIconPopOverItems} />
    </DriverControl>
  );
}
