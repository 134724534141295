import { useEffect, useMemo, useRef } from 'react';
import { Overlay } from 'ol';
import { useAppSelector } from '../../../layout/store';
import { SFMap } from '../../../map/services';
import {
  getMonitoringTrackInfoSelector,
  getMonitoringVehiclesSelector,
} from '../../selectors';
import { MonitoringTransportDTO } from '../../types';
import MonitoringVehicleMarker from '../MonitoringVehicleMarker';
import useFollowTransports from '../../hooks/useFollowTransports';
import useRealTimeVehicleMonitoring from '../../hooks/useRealTimeVehicleMonitoring';

function MonitoringVehicles() {
  const overlayRef = useRef<Overlay | null>(null);
  const overlayElementRef = useRef<HTMLDivElement | null>(null);
  const vehicles: MonitoringTransportDTO[] = useAppSelector(
    getMonitoringVehiclesSelector,
  );

  const trackInfo = useAppSelector(getMonitoringTrackInfoSelector);

  useFollowTransports();
  useRealTimeVehicleMonitoring(vehicles);

  useEffect(() => {
    const { cleanup } = SFMap.makeCursorPointerOnMove();
    return cleanup;
  }, []);

  useEffect(() => {
    if (overlayElementRef.current) {
      const { cleanup, overlay } = SFMap.createOverlay(
        overlayElementRef.current,
      );
      overlayRef.current = overlay;
      return cleanup;
    }
    return () => {};
  }, []);

  const filteredVehicles = useMemo(() => {
    const vehiclesWithCoordinates = vehicles.filter(
      (vehicle) => vehicle.latitude && vehicle.longitude,
    );
    if (trackInfo.allSelected) {
      return vehiclesWithCoordinates;
    }
    if (trackInfo.transportId) {
      return vehiclesWithCoordinates.filter(
        (vehicle) => vehicle.id === trackInfo.transportId,
      );
    }
    if (trackInfo.teamId) {
      return vehiclesWithCoordinates.filter(
        (vehicle) => vehicle.teamId === trackInfo.teamId,
      );
    }
    return vehiclesWithCoordinates;
  }, [
    vehicles,
    trackInfo.transportId,
    trackInfo.teamId,
    trackInfo.allSelected,
  ]);

  return (
    <div>
      {filteredVehicles.map((vehicle) => (
        <MonitoringVehicleMarker
          vehicle={vehicle}
          key={vehicle.id || vehicle.transportId}
        />
      ))}
    </div>
  );
}

export default MonitoringVehicles;
