import { TableFilterDTO } from '../../global';
import { DriverDTO } from '../../services/driver.service';
import { DeviceDTO } from '../../services/device.service';
import { TeamLightDTO } from '../../services/team.service';

export enum VehicleType {
  CAR = 'CAR',
  MOTO = 'MOTO',
  BIKE = 'BIKE',
  WALK = 'WALK',
  TRUCK = 'TRUCK',
}
export enum EngineType {
  DIESEL = 'DIESEL',
  GASOLINE = 'GASOLINE',
  HYBRID = 'HYBRID',
  ELECTRICAL = 'ELECTRICAL',
}
export enum UnitOfMeasure {
  LITRE = 'LITRE',
  KILOWATT = 'KILOWATT',
  CUBICMETRE = 'CUBIC METRE',
}

export type VehicleFormDTO = {
  image: string;
  name: string;
  type: VehicleType;
  licensePlate: string;
  mileage: string;
  fuelType: EngineType | string;
  measure: UnitOfMeasure;
  team: TeamLightDTO | null;
  vin: string;
  courier: DriverDTO | null;
  device: DeviceDTO | null;
  avgConsumption: number;
};

export type VehicleListFilterDTO = TableFilterDTO & {
  vehicleType?: string;
};

export enum MovementStatus {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum DeviceStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum DeviceMovementStatus {
  MOVING = 1,
  STOPPED = 0,
}

export interface MonitoringTripListDTO {
  id: number;
  transportId: number;
  startTime: string;
  endTime: string;
  startLocation: number;
  endLocation: number;
  startAddress: string;
  endAddress: string;
  distance: number;
}

export const enum SearchOption {
  NAME = 'name',
  LICENSE_PLATE = 'licensePlate',
  VEHICLE_TYPE = 'vehicleType',
  COURIER_FIRST_NAME = 'courier:firstName',
  COURIER_LAST_NAME = 'courier:lastName',
}
