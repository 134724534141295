import { renderToStaticMarkup } from 'react-dom/server';

function findAddedObject(
  originalArray: Array<object>,
  newArray: Array<object>,
) {
  const stringifiedOriginal = originalArray.map((obj) => JSON.stringify(obj));
  const stringifiedNew = newArray.map((obj) => JSON.stringify(obj));

  const addedStringified = stringifiedNew.find(
    (obj) => !stringifiedOriginal.includes(obj),
  );

  return addedStringified ? JSON.parse(addedStringified) : null;
}
function generateSvgDataUrl(svg: JSX.Element) {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(renderToStaticMarkup(svg))}`;
}
export { findAddedObject, generateSvgDataUrl };
