import { FC } from 'react';
import { MovementsIcons } from './constants';
import { MonitoringMovementStatus } from '../types';

type Props = {
  status?: MonitoringMovementStatus;
};
function getMovementsIcon({ status }: Props): FC {
  const matchedIcon = MovementsIcons.find((el) => {
    return el.state == status;
  });
  return matchedIcon ? matchedIcon.icon : MovementsIcons[0].icon;
}
export default getMovementsIcon;
