import { ListDTO, MatchingMethod, TableFilterDTO } from '../global';
import ApiService from '../global/services/api.service';
import { SearchOption, TeamFormDTO } from '../teams/types';
import QueryBuilder from '../shared/utils/query-builder';
import { VehicleLightDTO } from '../shared/types';

export interface TeamLightDTO {
  description: string;
  transportsCount: number;
  id: number;
  name: string;
  transports: Array<VehicleLightDTO>;
}

export type TeamDTO = TeamLightDTO;

class TeamService {
  static ENDPOINT = '/teams';

  static getTeams(filters: TableFilterDTO) {
    const body = new QueryBuilder()
      .addOffset(filters.offset)
      .addLimit(filters.limit || 20)
      .or({
        field: SearchOption.NAME,
        value: filters.search,
        matchingMethod: MatchingMethod.CONTAINS,
      })
      .or({
        field: SearchOption.DESCRIPTION,
        value: filters.search,
        matchingMethod: MatchingMethod.CONTAINS,
      });

    return ApiService.post<ListDTO<TeamLightDTO>>(
      `${TeamService.ENDPOINT}/search`,
      body,
      {
        notifyErrors: false,
      },
    );
  }

  static getTeamById(id: number) {
    return ApiService.get<TeamDTO>(`${TeamService.ENDPOINT}/${id}`);
  }

  static createTeam(team: TeamFormDTO) {
    const body = TeamService.getTeamRequestPayload(team);
    return ApiService.post(`${TeamService.ENDPOINT}`, body);
  }

  static updateTeam(id: number, team: TeamFormDTO) {
    const body = TeamService.getTeamRequestPayload(team);
    return ApiService.patch(`${TeamService.ENDPOINT}/${id}`, body);
  }

  static deleteTeam(id: number) {
    return ApiService.delete(`${TeamService.ENDPOINT}/${id}`);
  }

  static getTeamRequestPayload(team: TeamFormDTO) {
    return {
      transportIds: team.transports.map((transport) => transport.id),
      name: team.name,
      description: team.description,
    };
  }
}

export default TeamService;
