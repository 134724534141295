import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { FmdGoodOutlined } from '@mui/icons-material';
import { getUserGMTOffset } from '../../utils';
import { style } from './style';
import { useInterval } from '../../../global';
import { getFormattedDateTime } from '../../../shared/utils';

function UserAgentInfo() {
  const [userTime, setUserTime] = useState(getFormattedDateTime());

  useInterval(() => {
    setUserTime(getFormattedDateTime());
  }, 15000);

  return (
    <Box sx={style.userAgentInfo}>
      <Box sx={style.userGMT}>
        <FmdGoodOutlined
          color='action'
          sx={{ display: { xs: 'none', sm: 'inline-block' } }}
        />
        <Typography color='gray'>Yerevan, {getUserGMTOffset()}</Typography>
        <Typography
          color='gray'
          display={{ xs: 'none', sm: 'block' }}
          sx={style.divider}
        >
          |
        </Typography>
        <Typography color='gray'>{userTime}</Typography>
      </Box>
    </Box>
  );
}

UserAgentInfo.displayName = 'UserAgentInfo';

export default UserAgentInfo;
