import ApiService, { ApiResponse } from '../global/services/api.service';
import { User } from '../auth/types';
import { PasswordDTO } from '../auth/types/password';
import {
  LOCALSTORAGE_ACCESS_TOKEN,
  LOCALSTORAGE_REFRESH_TOKEN,
} from '../auth/utils';

class AuthService {
  static ENDPOINT = '/auth';

  static getUser() {
    return ApiService.get<User>(`${AuthService.ENDPOINT}/me`);
  }

  static async refreshToken(): Promise<null | ApiResponse<{
    accessToken: string;
    refreshToken: string;
  }>> {
    const refreshToken = localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN);

    if (!refreshToken) {
      return null;
    }
    const response = await ApiService.post<{
      refreshToken: string;
      accessToken: string;
    }>(
      `${AuthService.ENDPOINT}/refresh-token`,
      {
        refreshToken,
      },
      {
        notifyErrors: false,
      },
    );
    if (!response.ok) {
      return null;
    }
    const { data } = response;
    localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, data.refreshToken);
    localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN, data.accessToken);
    return response;
  }

  static setPassword(body: PasswordDTO) {
    return ApiService.post<{ ok: boolean }>(
      '/account/reset-password/complete',
      body,
    );
  }

  static forgotPassword(email: string) {
    return ApiService.post<{ ok: boolean }>('/account/reset-password/request', {
      email,
    });
  }
}

export default AuthService;
