import { io } from 'socket.io-client';
import ApiService from '../../global/services/api.service';

const socket = io(ApiService.BASE_URL, {
  extraHeaders: {
    Authorization: ApiService.getToken(),
  },
  autoConnect: false,
});

export default socket;
