import {
  IMiddleware,
  MiddlewareContext,
  NextFunction,
} from '../middleware-manager';
import ApiService from '../api.service';

export class AuthMiddleware implements IMiddleware {
  async onRequest(
    context: MiddlewareContext,
    next: NextFunction,
  ): Promise<void> {
    context.request.headers.set('Authorization', ApiService.getToken());
    return next();
  }
}
