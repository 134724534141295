import bicycleGreen from '../../../assets/vehicle/green/bicycle-green.svg';
import bicycleRed from '../../../assets/vehicle/red/bicycle-red.svg';
import bicycleYellow from '../../../assets/vehicle/yellow/bicycle-yellow.svg';
import bicycleGray from '../../../assets/vehicle/gray/bicycle-gray.svg';
import motoGreen from '../../../assets/vehicle/green/moto-green.svg';
import motoRed from '../../../assets/vehicle/red/moto-red.svg';
import motoYellow from '../../../assets/vehicle/yellow/moto-yellow.svg';
import motoGray from '../../../assets/vehicle/gray/moto-gray.svg';
import sedanGreen from '../../../assets/vehicle/green/sedan-green.svg';
import sedanRed from '../../../assets/vehicle/red/sedan-red.svg';
import sedanYellow from '../../../assets/vehicle/yellow/sedan-yellow.svg';
import sedanGray from '../../../assets/vehicle/gray/sedan-gray.svg';
import truckGreen from '../../../assets/vehicle/green/truck-green.svg';
import truckRed from '../../../assets/vehicle/red/truck-red.svg';
import truckYellow from '../../../assets/vehicle/yellow/truck-yellow.svg';
import truckGray from '../../../assets/vehicle/gray/truck-gray.svg';
import walkGreen from '../../../assets/vehicle/green/walk-green.svg';
import walkRed from '../../../assets/vehicle/red/walk-red.svg';
import walkYellow from '../../../assets/vehicle/yellow/walk-yellow.svg';
import walkGray from '../../../assets/vehicle/gray/walk-gray.svg';
import { VehicleType } from '../../vehicles/types';
import { MonitoringMovementStatus } from '../../monitoring/types';

function getVehicleIcon(
  vehicleType: VehicleType,
  status: MonitoringMovementStatus,
) {
  if (status === MonitoringMovementStatus.STOP) {
    switch (vehicleType) {
      case VehicleType.BIKE:
        return bicycleRed;
      case VehicleType.MOTO:
        return motoRed;
      case VehicleType.CAR:
        return sedanRed;
      case VehicleType.TRUCK:
        return truckRed;
      case VehicleType.WALK:
        return walkRed;
      default:
        return sedanRed;
    }
  }
  if (status === MonitoringMovementStatus.MOVEMENT) {
    switch (vehicleType) {
      case VehicleType.BIKE:
        return bicycleGreen;
      case VehicleType.MOTO:
        return motoGreen;
      case VehicleType.CAR:
        return sedanGreen;
      case VehicleType.TRUCK:
        return truckGreen;
      case VehicleType.WALK:
        return walkGreen;
      default:
        return sedanGreen;
    }
  }
  if (status === MonitoringMovementStatus.IDLING) {
    switch (vehicleType) {
      case VehicleType.BIKE:
        return bicycleYellow;
      case VehicleType.MOTO:
        return motoYellow;
      case VehicleType.CAR:
        return sedanYellow;
      case VehicleType.TRUCK:
        return truckYellow;
      case VehicleType.WALK:
        return walkYellow;
      default:
        return sedanYellow;
    }
  }
  if (
    [
      MonitoringMovementStatus.PARKING,
      MonitoringMovementStatus.NO_DATA,
      MonitoringMovementStatus.NA,
    ].includes(status)
  ) {
    switch (vehicleType) {
      case VehicleType.BIKE:
        return bicycleGray;
      case VehicleType.MOTO:
        return motoGray;
      case VehicleType.CAR:
        return sedanGray;
      case VehicleType.TRUCK:
        return truckGray;
      case VehicleType.WALK:
        return walkGray;
      default:
        return sedanGray;
    }
  }
  switch (vehicleType) {
    case VehicleType.BIKE:
      return bicycleRed;
    case VehicleType.MOTO:
      return motoRed;
    case VehicleType.CAR:
      return sedanRed;
    case VehicleType.TRUCK:
      return truckRed;
    case VehicleType.WALK:
      return walkRed;
    default:
      return undefined;
  }
}

export { getVehicleIcon };
