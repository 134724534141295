import { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { NotificationApi } from '../../services';

function NotificationTracker() {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsubscribe = NotificationApi.subscribe(
      (message: string, options) => {
        enqueueSnackbar(message, options);
      },
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default NotificationTracker;
