import { lazy, PropsWithChildren, Suspense, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { SFMap } from '../../services';
import { style } from './style';
import 'ol/ol.css';
import { MapControlType } from '../../types';

const MapControls = lazy(() => import('../MapControls'));

export type MapProps = {
  position?: 'left' | 'right';
  controls?: Partial<Record<MapControlType, boolean>>;
};

function Map(props: PropsWithChildren<MapProps>) {
  const { children, controls, position = 'right' } = props;
  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current) {
      return SFMap.initMap(mapRef.current);
    }
    return () => {};
  }, []);

  const handleTabFocus = () => {
    const map = SFMap.getMap();
    if (map) {
      map.render();
    }
  };

  useEffect(() => {
    // When switching between tabs
    // the map is not rendered correctly
    window.addEventListener('focus', handleTabFocus);
    return () => {
      window.removeEventListener('focus', handleTabFocus);
    };
  }, []);

  const mapControls = (
    <Suspense>
      <MapControls controls={controls} />
    </Suspense>
  );

  const map = <Box ref={mapRef} sx={style.map} id='map-sf' />;

  return (
    <>
      {position === 'left' && (
        <Box sx={style.mapWrapper}>
          {map}
          {mapControls}
        </Box>
      )}
      {children}

      {position === 'right' && (
        <Box sx={style.mapWrapper}>
          {map}
          {mapControls}
        </Box>
      )}
    </>
  );
}

Map.displayName = 'Map';

export default Map;
