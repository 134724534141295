/**
 * Superfleet.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 2/8/2024
 * All Rights Reserved.
 */

import { styled, Switch, SwitchProps } from '@mui/material';
import { ChangeEvent } from 'react';
import { Callback } from '../../types';
import { SFControlledInputProps } from '../SFTextField';

type Props = SFControlledInputProps &
  SwitchProps & {
    value?: boolean;
    onChange?: Callback;
  };

const SFIosSwitch = styled((props: Props) => {
  const { onChange, value, errorMessage, ...rest } = props;

  const handleChange = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange?.(checked);
  };
  return (
    <Switch
      focusVisibleClassName='.Mui-focusVisible'
      disableRipple
      {...rest}
      checked={value}
      onChange={handleChange}
    />
  );
})(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default SFIosSwitch;
